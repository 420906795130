import React from 'react';
import { motion } from 'framer-motion';
import {  MoreVertical, Bot, Menu, Camera } from 'lucide-react';
import { getCountryFlag } from '../../utils/functions/functions';
const MobileHeaderLiveDash = ({currentData,setShowLeftSidebar,toggleAIMode,isAIMode,setShowRightSidebar}) => (
    <div className="flex items-center justify-between w-full px-4 py-3 bg-[#001835] border-b border-gray-700/50">
      <div className="flex items-center space-x-3">
        <button
          onClick={() => setShowLeftSidebar(true)}
          className="p-2 hover:bg-gray-700/50 rounded-lg"
        >
          <Menu size={20} />
        </button>
        <div className="flex items-center space-x-2">
          <span className="font-medium">{currentData?.name || "#"+ currentData?.visitorId}</span>
          <img src={getCountryFlag(currentData?.country || "IN")} alt="India" className="w-6 h-4" />
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={toggleAIMode}
          className={`p-2 rounded-lg flex items-center ${
            isAIMode ? 'bg-purple-500 text-white' : 'bg-gray-700/50'
          }`}
        >
          <Bot size={18} />
        </motion.button>
        <button className="p-2 hover:bg-gray-700/50 rounded-lg">
          <Camera size={20} />
        </button>
        <button 
          onClick={() => setShowRightSidebar(true)}
          className="p-2 hover:bg-gray-700/50 rounded-lg"
        >
          <MoreVertical size={20} />
        </button>
      </div>
    </div>
  );
  export default MobileHeaderLiveDash;