import React from "react";
import Icons from "../utils/Icons";
import ErrorBoundary from "./ErrorBoundary ";

const ClickToCall = ({ phoneNumber }) => {
 

  const loadCallHippoDialer = () => {
    try {
      if (window.chCall) {
        console.log("Attempting to make a call to:", phoneNumber);
        window.chCall(phoneNumber);
      } else {
        console.error("window.chCall is not defined.");
      }
    } catch (error) {
      console.error("Error in loadCallHippoDialer:", error);
    }
  };

  return (
    <ErrorBoundary>
     
      <button
        onClick={loadCallHippoDialer}
        className="bg-[#00234a] text-white text-sm py-1 px-4 ml-3 rounded-md hover:bg-opacity-90 transition duration-300"
      >
        Call Now <Icons title={"call"} size={8} />
      </button>
    </ErrorBoundary>
  );
};

export default ClickToCall;
