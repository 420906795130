import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  ChevronDown,
  Copy,
  Edit2,
  Activity,
  Send,
  Smile,
} from "lucide-react";
import RightSidebarOverlay from "./../layouts/RightSidebarOverlay";
import MobileHeaderLiveDash from "./../layouts/MobileHeader";
import DesktopHeaderLiveDash from "./../layouts/DesktopHeaderLiveDash";
import { getCountryFlag, truncateString } from "../../utils/functions/functions";
import MessageBubble from "./../components/MessageBubble";
import MobileViewsLeftSidebar from "./../views/MobileViewsLeftSidebar";
import DeskotopViewRightSidebar from "./../views/DeskotopViewRightSidebar";
import DesktopLeftSidebar from "./../views/DesktopLeftSidebar";
import { Toaster } from "../../Toaster";
import UploadFile from "./../components/UploadFile";
// import CountryWiseReport from "../country-wise-report/CountryWiseReport";
// import CountryWiseReport from "../country-wise-report/CountryWiseReport";
// Main component
export default function OpenChatBoxInternal({getUpdateUserLive,showRightSidebar,setShowRightSidebar,setNewMessage,newMessage,handleSendMessage,user,getAllLiveUser,setPageData,setFilter,filter,allLiveUser,loadMoreChats,startChat,currentUserList,chatStats,messages,setActiveFilter,activeFilter,showLeftSidebar,setopenLiveChat, setShowLeftSidebar}) {
  

  const [openDetailReport,setDetailReport]=useState(false)
  return (
      <div className="flex flex-col md:flex-row h-screen bg-[#00234a] text-gray-100">
      {/* Mobile Header */}
      {/* {openDetailReport && <CountryWiseReport isOpen={openDetailReport} setIsOpen={()=>setDetailReport(false)}/>} */}
      <div className="md:hidden">
        <MobileHeaderLiveDash 
        showLeftSidebar={showLeftSidebar}
        setShowLeftSidebar={setShowLeftSidebar}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        currentData={messages?.data}
        setShowRightSidebar={setShowRightSidebar}
        />
      </div>

      {/* Left Sidebar - Mobile */}
      <MobileViewsLeftSidebar
      setDetailReport={setDetailReport}
      setShowRightSidebar={setShowRightSidebar}
         chatStats={chatStats}
         currentData={currentUserList?.data}
         chatList={currentUserList?.data}
         onClickRow={(row) => {
          setShowLeftSidebar(false)
          startChat(row)}}
         loadMoreChats={loadMoreChats}
         paginations={allLiveUser?.paginations}
         hasMoreData={allLiveUser?.paginations?.totalFilterCount >= allLiveUser?.data?.length}
         filter={filter}
         setFilter={setFilter}
         setPageData={setPageData}
         getAllLiveUser={getAllLiveUser}
         showLeftSidebar={showLeftSidebar}
         setShowLeftSidebar={setShowLeftSidebar}
      />
      <DesktopLeftSidebar
      setDetailReport={setDetailReport}
      setShowRightSidebar={setShowRightSidebar}
        chatStats={chatStats}
        currentData={currentUserList?.data}
        chatList={currentUserList?.data}
        onClickRow={(row) => {
          startChat(row)
        }}
        loadMoreChats={loadMoreChats}
        paginations={allLiveUser?.paginations}
        hasMoreData={allLiveUser?.paginations?.totalFilterCount > allLiveUser?.data?.length}
        filter={filter}
        setFilter={setFilter}
        setPageData={setPageData}
        getAllLiveUser={getAllLiveUser}
        setShowLeftSidebar={setShowLeftSidebar}
      />

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col h-[calc(100vh-64px)] md:h-screen">
        {/* Desktop Header */}
        <div className="hidden md:block">
          <DesktopHeaderLiveDash setShowRightSidebar={setShowRightSidebar} key={"jkkdfnidf9"} currentData={messages?.data} />
        </div>

        {/* Chat Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {messages?.chatList?.map((message) => (
            <MessageBubble
              key={message?.data?._id}
              message={message}
              currentData={messages?.data}
              user={user}
            />
          ))}
        </div>

        {/* Message Input */}
        <div className="p-4 border-t border-gray-700/50">
          <div className="flex items-center space-x-2 mb-2">
           <UploadFile onSubmit={handleSendMessage}/>
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder={`Type your message...`}
              className="flex-1 bg-gray-700/50 rounded-xl py-2.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 text-base"
            />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg"
              onClick={()=>Toaster.info("This feature is coming soon!")}
            >
              <Smile size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSendMessage}
              className={`${"bg-blue-500 hover:bg-blue-600"
              } rounded-lg p-2 md:px-4 md:py-2 flex items-center space-x-2`}
            >
              <Send size={16} className="md:hidden" />
              <span className="hidden md:inline">Send</span>
            </motion.button>
          </div>
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="bg-green-500/20 text-green-300 text-center py-2 rounded-lg"
          >
            CHAT CONNECTED
          </motion.div>
          <div className="text-sm text-gray-400 mt-2 text-center">
            {"by "+user?.name}
          </div>
        </div>
      </div>
      <RightSidebarOverlay
        show={showRightSidebar}
        onClose={() => setShowRightSidebar(false)}
      >
        <div className="p-4 border-b border-gray-700/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="w-12 h-12 bg-gray-700/50 rounded-full flex items-center justify-center">
                <span className="text-xl font-semibold">T</span>
              </div>
              <div>
                <div className="font-medium text-lg flex items-center space-x-2">
                  <span>{messages?.name || "NA"}</span>
                  <img
                    src={getCountryFlag(messages?.country || "IN")}
                    alt="India"
                    className="w-6 h-4"
                  />
                  <Edit2 size={14} className="text-gray-400" />
                </div>
                <div className="text-sm text-gray-400">
                  {messages?.countryName || "NA"}
                </div>
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg"
            >
              <Copy size={16} />
            </motion.button>
          </div>
        </div>

        <div className="p-4 space-y-4 overflow-y-auto flex-1">
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-400">Email</span>
              <span>{messages?.data?.email || "NA"}</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-400">Phone</span>
              <span>{messages?.data?.number || "NA"}</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-400">Type</span>
              <span>{messages?.data?.exists || "NA"}</span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-400">IP Address</span>
              <span>{truncateString(messages?.data?.clientIp, 17) || "NA"}</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="text-2xl font-bold">{messages?.data?.leadScore || "0%"}</div>
              <div className="text-sm text-gray-400">Lead Score</div>
            </div>
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="text-2xl font-bold">
                {messages?.data?.conversionRate || "0%"}
              </div>
              <div className="text-sm text-gray-400">Opportunity</div>
            </div>
          </div>

          <div className="space-y-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
            >
              <div className="flex items-center space-x-2">
                <Activity size={16} className="text-blue-400" />
                <span>Activity</span>
              </div>
              <ChevronDown size={16} />
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
            >
              <div className="flex items-center space-x-2">
                <span className="w-4 h-4 bg-green-500 rounded" />
                <span>Clickinpedia Desk</span>
              </div>
              <ChevronDown size={16} />
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
            >
              <div className="flex items-center space-x-2">
                <span className="w-4 h-4 bg-blue-500 rounded" />
                <span>Clickinpedia CRM</span>
              </div>
              <ChevronDown size={16} />
            </motion.button>
          </div>
        </div>
      </RightSidebarOverlay>

      <DeskotopViewRightSidebar getUpdateUserLive={getUpdateUserLive} setShowRightSidebar={setShowRightSidebar} setopenLiveChat={setopenLiveChat} visitorInfo={messages?.data} />
    </div>
    
  );
}
