import React from 'react';
import { motion } from 'framer-motion';
import { Globe, MoreVertical,  Bot, PhoneCall, } from 'lucide-react';
import { getCountryFlag } from '../../utils/functions/functions';
import { Toaster } from '../../Toaster';
import { useDispatch, useSelector } from 'react-redux';
import { getLiverUserHeaderCountAction, updateAiModeAction } from '../../../services/action/common';

const DesktopHeaderLiveDash = ({toggleAIMode,isAIMode,currentData}) => 
{
  const {user}=useSelector((state)=>state.authenticationReducer)

  const dispatch=useDispatch();
  const { chatHeaderInfo, } = useSelector(
    (state) => state.commonReducer
  );


  const changeAiMode=()=>{
     dispatch(updateAiModeAction({userType:user?.userType?.toLowerCase(),data:{
      aiMode:!chatHeaderInfo?.isAIMode}, cb:()=>{
        dispatch(getLiverUserHeaderCountAction({userType:user?.userType}))
      }}))
  }

  return (
    <div className="flex items-center p-4 border-b border-gray-700/50">
      <div className="flex-1 flex items-center space-x-4 overflow-x-auto whitespace-nowrap scrollbar-hide">
        <Globe className="text-blue-400 flex-shrink-0" size={20} />
        <span className="font-medium text-lg flex-shrink-0">{currentData?.name || "#"+currentData?.visitorId}</span>
        <img src={getCountryFlag(currentData?.country || 'IN')} alt="India" className="w-6 h-4 flex-shrink-0" />
        <span className="text-gray-400 flex-shrink-0">{currentData?.website}</span>
        {/* <span className="text-gray-400 flex-shrink-0">myassignmenthelp</span> */}
        {/* <span className="text-gray-400 flex-shrink-0">Naira</span> */}
      </div>
      <div className="flex items-center space-x-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={changeAiMode}
          className={`p-2 rounded-lg flex items-center space-x-2 ${
            chatHeaderInfo?.isAIMode ? 'bg-purple-500 text-white' : 'bg-gray-700/50 hover:bg-gray-600/50'
          }`}
        >
          <Bot size={18} />
          <span className="text-sm">{chatHeaderInfo?.isAIMode?"Enabled AI":"Disabled AI"}</span>
        </motion.button>
        <button className="p-2 hover:bg-gray-700/50 rounded-lg"
        onClick={()=>Toaster.info("Coming soon!")}
        >
          <PhoneCall size={20} />
        </button>
        <button className="p-2 hover:bg-gray-700/50 rounded-lg">
          <MoreVertical size={20} />
        </button>
      </div>
    </div>
  );
}

  export default DesktopHeaderLiveDash;