import React, { useRef, useState, useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import { Paperclip,Loader ,} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../services/action/common";

const UploadFile = ({ onSubmit }) => {
  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const fileRef = useRef();

  const onSend = (images) => {
    let messageFile = "";
    images?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="delivery-chat-box"><p class="file-list">${messageFile} </p></div>`;
    onSubmit(message);
  };

  const uploadImage = (e) => {
    const callBackImage = (res) => {
      onSend(res?.imageUrls);
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  return (
    <Fragment>
      {/* // <span className="chat-file-btn pointer" onClick={()=>fileRef.current?.click()}> */}
      <input
        type="file"
        onChange={uploadImage}
        className="display-none"
        ref={fileRef}
        multiple={true}
      />
      {/* //   <Icons
    //     title={isImageLoading ? "loader" : "file"}
    //     color={"#cccccc"}
    //     size={isImageLoading?25:32}
    //   />
    // </span> */}

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => fileRef.current?.click()}
        className="p-2 hover:bg-gray-700/50 rounded-lg"
      >
        
        {isImageLoading?<Loader size={20}/>:<Paperclip size={20} />}
      </motion.button>
    </Fragment>
  );
};

export default UploadFile;
