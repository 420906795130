import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  BookOpen,
  Home,
  LogIn,
  MessageCircle,
  Search,
  User,
  UserPlus,
  AlignJustify,
} from "lucide-react";

const DashboardPreview = ({ editorData }) => {
  const [activeTab, setActiveTab] = useState("home");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="mobile-preview">
      <div
        style={{ width: "430px" }}
        className="bg-gray-200 p-4 overflow-hidden margin-auto"
      >
        <div className="bg-white rounded-lg shadow-sm overflow-hidden h-full">
          <div className="flex flex-col h-screen bg-gray-50">
            {/* App Bar */}
            <header
              className="text-white shadow-md"
              style={{ backgroundColor: "#0A376A" }}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-2">
                  <AlignJustify />
                </div>
                <div className="flex items-center space-x-2">
                  <button className="flex items-center px-3 py-2 text-sm font-medium text-white hover:bg-white/20 transition-colors duration-300 rounded-md">
                    <LogIn className="h-4 w-4 mr-2" />
                    Login
                  </button>
                  <button className="flex items-center px-3 py-2 text-sm font-medium bg-white text-[#0A376A] hover:bg-opacity-90 transition-colors duration-300 rounded-md">
                    <UserPlus className="h-4 w-4 mr-2" />
                    Register
                  </button>
                </div>
              </div>
            </header>

            {/* Main Content */}
            <main className="flex-1 overflow-y-auto">
              <div className="p-4 space-y-6">
                {/* Premium Academic Services Slider */}
                <section className="space-y-4">
                  <div className="relative overflow-hidden rounded-2xl">
                    <motion.div
                      className="flex"
                      animate={{ x: `-${currentSlide * 100}%` }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                      }}
                    >
                      {editorData?.premiumServices.map((service, index) => (
                        <motion.div
                          key={index}
                          className="w-full flex-shrink-0 px-1"
                          initial={{ scale: 0.9, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ delay: index * 0.1 }}
                        >
                          <div className={`rounded-lg overflow-hidden`}>
                            <div className="p-1">
                              <div className="flex items-start justify-between">
                                {/* <div className="space-y-4"> */}
                                <img src={service?.image} alt="" />
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </motion.div>
                    <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-2">
                      {editorData?.premiumServices?.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentSlide(index)}
                          className={`w-2 h-2 rounded-full transition-all duration-300 ${
                            index === currentSlide
                              ? "bg-white w-4"
                              : "bg-white/50"
                          }`}
                          aria-label={`Go to slide ${index + 1}`}
                        />
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </main>

            {/* Floating Action Button */}
            <button className="fixed bottom-20 right-4 h-14 w-14 rounded-full shadow-lg bg-[#0A376A] hover:bg-[#0A376A]/90 transition-all duration-300 flex items-center justify-center">
              <MessageCircle className="h-6 w-6 text-white" />
            </button>

            {/* Bottom Navigation */}
            <nav className="sticky bottom-0 bg-white border-t shadow-lg">
              <div className="flex justify-around items-center">
                {[
                  { icon: Home, label: "Home", value: "home" },
                  { icon: BookOpen, label: "Services", value: "services" },
                  { icon: Search, label: "Search", value: "search" },
                  { icon: User, label: "Profile", value: "profile" },
                ].map((item) => (
                  <button
                    key={item.value}
                    className={`flex-1 flex flex-col items-center justify-center h-16 gap-1 ${
                      activeTab === item.value
                        ? "text-[#0A376A] border-t-2 border-[#0A376A]"
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      if (item.value === "search")
                        setIsSearchOpen(!isSearchOpen);
                      else setActiveTab(item.value);
                    }}
                  >
                    <item.icon className="h-5 w-5" />
                    <span className="text-xs">{item.label}</span>
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPreview;
