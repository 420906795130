import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X} from 'lucide-react';
const SidebarOverlayLiveDash = ({ show, onClose, children }) => (
    <AnimatePresence>
      {show && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-40 md:hidden"
          />
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'tween' }}
            className="fixed inset-y-0 left-0 w-full max-w-[320px] bg-[#00234a] z-50 md:hidden"
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between p-4 border-b border-gray-700/50">
                <h2 className="text-lg font-semibold">Live chat</h2>
                <button onClick={onClose} className="p-2 hover:bg-gray-700/50 rounded-lg">
                  <X size={20} />
                </button>
              </div>
              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
  

  export default SidebarOverlayLiveDash;