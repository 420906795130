import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import DashboardEditor from "./dashboard/DashboardEditor";
import DashboardPreview from "./dashboard/DashboardPreview";
import { useDispatch, useSelector } from "react-redux";
import { getWelcomeScreenDataAction } from "../../services/action/common";

const ClientDashboardDesign = () => {
    const dispatch=useDispatch();
    const {androidWelcome} =useSelector((state)=>state.commonReducer);
    useEffect(()=>{
     dispatch(getWelcomeScreenDataAction({data:{screenName:"clientDashboard"}}))
    },[dispatch])
 
   const [editorData, setEditorData] = useState(null)
 
 useEffect(()=>{
   if(androidWelcome){
     setEditorData(androidWelcome)
   }
 },[androidWelcome])
  return <DashboardLayout>
    <div className="flex h-screen bg-gray-100">
        {editorData && (
          <DashboardEditor setEditorData={setEditorData} editorData={editorData} />
        )}
        {editorData && <DashboardPreview editorData={editorData} />}
      </div>
  </DashboardLayout>;
};

export default ClientDashboardDesign;
