// import { X } from 'lucide-react'

import { useSelector } from "react-redux";
import { returnDateWithTime } from "../utils/functions/functions";
import Icons from "../utils/Icons";
const getClass = (row) => {
  switch (row) {
    case "Created":
      return "bg-success text-white";
    case "Updated":
      return "btn-primary text-white";
    case "Reopen":
      return "bg-warning text-white";
    case "Closed":
      return "bg-danger text-white";

    case "NEW":
      return "bg-success text-white";
    case "INPROGRESS":
      return "btn-primary text-white";
    case "TESTING":
      return "bg-warning text-white";
    case "REJECTED":
      return "bg-danger text-white";

    default:
      return "";
  }
};


export default function OrderHistoryActivity({ onClose ,title="Order Activity" }) {
  const { orderActivity,leadActivity } = useSelector((state) => state.commonReducer);

  const list = leadActivity || orderActivity;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">{title}</h2>
        <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
          <Icons title={"cross"} color="#000" />
        </button>
      </div>
      <div className="space-y-6">
        {list?.map((entry, index) => (
          <div key={index} className="flex">
            <div className="flex flex-col items-center mr-4">
              <div
                className={`w-20 h-18 rounded-full flex items-center justify-center ${
                  getClass(entry?.actions)
                }`}
              >
                <span className="text-xs font-semibold">{entry?.actions}</span>
              </div>
              {index < list?.length - 1 && (
                <div className="w-0.5 h-full bg-gray-200 mt-2"></div>
              )}
            </div>
            <div>
              <p className="font-medium">{entry?.description}</p>
              <p className="text-sm text-gray-500">
                {entry?.author?.name} • {entry?.author?.userType} •{" "}
                {returnDateWithTime(entry.timeAgo)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
