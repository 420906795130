import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import {
  Mail,
  Lock,
  ExternalLink,
  ArrowRight,
  X,
  Apple,
  PlaySquare,
} from "lucide-react";
import {
  getUserAction,
  loginAction,
  verifyGoogleTokenAction,
} from "../../services/action/authentication";
import { checkLogin } from "../../components/utils/functions/functions";
import GoogleSignupButton from "./social-auth/GoogleSignUpButton";

const Login = () => {
  const [obj, setObj] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin, user } = useSelector((state) => state.authenticationReducer);

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    if (tokens) {
      dispatch(getUserAction());
    }
  }, [dispatch]);

  useEffect(() => {
    checkLogin(navigate, user);
  }, [user, navigate]);

  const callBackSuccess = (res) => {
    checkLogin(navigate, res);
  };

  const loginNow = (e) => {
    e.preventDefault();
    const tempError = { ...errors };
    if (!obj.email) tempError.email = "Email is required!";
    else tempError.email = "";
    if (!obj.password) tempError.password = "Password is required!";
    else tempError.password = "";

    if (tempError.email || tempError.password) {
      setErrors(tempError);
      return false;
    }
    dispatch(loginAction({ data: obj, cb: callBackSuccess }));
  };

  const handleCallbackResponse = (response) => {
    if (!response.credential) {
      console.error("No credential returned from Google");
      return;
    }
    try {
      const payload = { token: response.credential };
      dispatch(
        verifyGoogleTokenAction({
          data: payload,
          cb: (res) => {
            if (res?.userType) {
              localStorage.setItem("token", res?.token);
              checkLogin(navigate, res);
            } else {
              const userObject = JSON.parse(
                atob(response?.credential.split(".")[1])
              );
              localStorage.setItem(
                "emailVerifyToken",
                JSON.stringify(userObject)
              );
              navigate("/auth/google");
            }
          },
        })
      );
    } catch (error) {
      console.error("Error decoding token or extracting user data:", error);
    }
  };

  const partnerWebsites = [
    { url: "https://casestudyhelp.uk/", logo: "/images/casestudy.png" },
    {
      url: "https://www.assignmentwriter.au/",
      logo: "/images/assignmentwriter.png",
    },
    {
      url: "https://www.courseworkhelp.uk/",
      logo: "/images/coursework-help-logo.png",
    },
    {
      url: "https://www.assessmenthelp.uk/",
      logo: "https://www.assessmenthelp.uk/assessment-help-logo.webp",
    },
    {
      url: "https://www.programmingassignmenthelp.uk/",
      logo: "https://www.programmingassignmenthelp.uk/logo.png",
    },
    {
      url: "https://www.bestsophelp.com/",
      logo: "https://www.bestsophelp.com/assets/img/bestsoplogo.webp",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-white">
      <motion.div
        initial={{ opacity: 0, x: -30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full lg:w-3/5 flex items-center justify-center p-4 lg:p-8"
      >
        <div className="w-full max-w-md space-y-4 lg:space-y-6">
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-center space-y-2 lg:space-y-4"
          >
            <img
              src="/images/logo.png"
              alt="Logo"
              className="mx-auto w-auto h-12 lg:h-16"
            />
            <div className="space-y-1 lg:space-y-2">
              <h1 className="text-xl lg:text-2xl font-bold text-gray-900">
                Welcome back
              </h1>
              <p className="text-sm lg:text-base text-gray-500">
                Sign in to continue your journey
              </p>
            </div>
          </motion.div>

          <form onSubmit={loginNow} className="space-y-4 lg:space-y-6">
            <motion.div
              className="space-y-3 lg:space-y-4"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <div className="relative group">
                  <Mail
                    className="absolute top-3 left-3 text-gray-400 group-hover:text-[#0080FF] transition-colors duration-300"
                    size={18}
                  />
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full px-10 py-3 bg-gray-50 border border-gray-200 rounded-xl text-sm lg:text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#0080FF] focus:border-[#0080FF] transition-all duration-300"
                    placeholder="Email address"
                    value={obj.email}
                    onChange={(e) => setObj({ ...obj, email: e.target.value })}
                  />
                </div>
                {errors.email && (
                  <p
                    className="mt-1 text-red-500 text-xs lg:text-sm pl-2"
                    role="alert"
                  >
                    {errors.email}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative group">
                  <Lock
                    className="absolute top-3 left-3 text-gray-400 group-hover:text-[#0080FF] transition-colors duration-300"
                    size={18}
                  />
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="w-full px-10 py-3 bg-gray-50 border border-gray-200 rounded-xl text-sm lg:text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#0080FF] focus:border-[#0080FF] transition-all duration-300"
                    placeholder="Password"
                    value={obj.password}
                    onChange={(e) =>
                      setObj({ ...obj, password: e.target.value })
                    }
                  />
                </div>
                {errors.password && (
                  <p
                    className="mt-1 text-red-500 text-xs lg:text-sm pl-2"
                    role="alert"
                  >
                    {errors.password}
                  </p>
                )}
              </div>
            </motion.div>

            <motion.div
              className="flex items-center justify-between"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded-md border-gray-300 text-[#0080FF] focus:ring-[#0080FF] transition-colors duration-300"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 text-xs lg:text-sm text-gray-600 hover:text-gray-900 transition-colors duration-300"
                >
                  Remember me
                </label>
              </div>
              <Link
                to="/reset-password"
                className="text-xs lg:text-sm text-[#0080FF] hover:text-[#00C2FF] transition-colors duration-300"
              >
                Forgot password?
              </Link>
            </motion.div>

            <motion.button
              whileHover={{
                scale: 1.02,
                boxShadow: "0 10px 20px rgba(0, 128, 255, 0.2)",
              }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              className="w-full flex items-center justify-center py-3 px-4 rounded-xl bg-gradient-to-r from-[#0080FF] to-[#00C2FF] text-white text-sm lg:text-base font-medium shadow-lg hover:from-[#0070FF] hover:to-[#00B2FF] transition-all duration-300 group"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              {isLogin ? (
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <circle
                    className="opacity-75"
                    cx="12"
                    cy="12"
                    r="4"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                <>
                  Sign in
                  <ArrowRight className="ml-2 w-4 h-4 lg:w-5 lg:h-5 group-hover:translate-x-1 transition-transform duration-300" />
                </>
              )}
            </motion.button>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-xs lg:text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <motion.div
              className="flex justify-center"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="shadow-md w-full max-w-[240px]"
              >
                <GoogleSignupButton onSignupSuccess={handleCallbackResponse} />
              </motion.div>
            </motion.div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-xs lg:text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Download our apps
                </span>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
              <motion.a
                href="https://play.google.com/store/apps/details?id=com.clickinpedia.com&hl=en"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="flex items-center justify-center px-4 py-2 bg-gradient-to-r from-[#0080FF] to-[#00C2FF] text-white rounded-xl space-x-2 hover:shadow-lg hover:shadow-[#0080FF]/20 transition-all duration-300"
              >
                <PlaySquare className="w-5 h-5 text-white" />
                <span className="text-xs lg:text-sm font-semibold">
                  Play Store
                </span>
              </motion.a>
              <motion.button
                onClick={() => setIsModalOpen(true)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="flex items-center justify-center px-4 py-2 bg-gradient-to-r from-[#0080FF] to-[#00C2FF] text-white rounded-xl space-x-2 hover:shadow-lg hover:shadow-[#0080FF]/20 transition-all duration-300"
              >
                <Apple className="w-5 h-5 text-white" />
                <span className="text-xs lg:text-sm font-semibold">
                  App Store
                </span>
              </motion.button>
            </div>
          </form>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="w-full lg:w-2/5 p-4 lg:p-8 bg-gray-50 flex items-center justify-center border-t lg:border-l border-gray-100"
      >
        <div className="w-full max-w-lg">
          <h3 className="text-xl lg:text-2xl font-semibold text-gray-900 mb-6 text-center">
            Trusted by Industry Leaders
          </h3>
          <motion.div
            className="grid grid-cols-2 lg:grid-cols-3 gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            {partnerWebsites.map((site, index) => (
              <PartnerLogo key={index} site={site} index={index} />
            ))}
          </motion.div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
      </AnimatePresence>
    </div>
  );
};

const PartnerLogo = ({ site, index }) => {
  const controls = useAnimation();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered) {
      controls.start({
        scale: 1.1,
        y: -5,
        transition: { type: "spring", stiffness: 300, damping: 10 },
      });
    } else {
      controls.start({ scale: 1, y: 0 });
    }
  }, [isHovered, controls]);

  return (
    <motion.a
      href={site.url}
      target="_blank"
      rel="noopener noreferrer"
      className="group relative flex items-center justify-center aspect-square rounded-xl bg-gradient-to-br from-gray-50 to-gray-100 overflow-hidden border border-gray-100 hover:border-[#0080FF]/20 hover:shadow-lg hover:shadow-[#0080FF]/10 transition-all duration-300"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-[#0080FF]/5 to-[#00C2FF]/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        initial={false}
        animate={isHovered ? { opacity: 1 } : { opacity: 0 }}
      />
      <motion.img
        src={site.logo}
        alt={`${site.url} logo`}
        className="w-2/3 h-2/3 object-contain opacity-90 group-hover:opacity-100 transition-opacity duration-300 mix-blend-multiply"
        animate={controls}
      />
      <motion.div
        className="absolute bottom-1 right-1 opacity-0 group-hover:opacity-100"
        initial={false}
        animate={isHovered ? { scale: 1 } : { scale: 0 }}
      >
        <ExternalLink className="w-3 h-3 text-[#0080FF]" />
      </motion.div>
    </motion.a>
  );
};

const Modal = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-2xl p-6 max-w-md w-full relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="text-center">
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1 }}
          >
            <Apple className="w-16 h-16 mx-auto text-[#0080FF]" />
          </motion.div>
          <motion.h2
            className="text-2xl font-bold text-gray-900 mt-4"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Coming Soon to App Store
          </motion.h2>
          <motion.p
            className="text-gray-600 mt-2"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            We're working hard to bring our app to iOS. Stay tuned for updates!
          </motion.p>
          <motion.div
            className="mt-6"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gradient-to-r from-[#0080FF] to-[#00C2FF] text-white rounded-lg hover:shadow-lg transition-all duration-300"
            >
              Got it
            </button>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Login;
