import { useState } from "react"
import { CheckCircle2, Star, ChevronDown, ChevronUp, Shield, X } from "lucide-react"

export default function ExpertProfileModal({ expert, isOpen, onClose }) {
  const [showFullBio, setShowFullBio] = useState(false)
  const [selectedTab, setSelectedTab] = useState("All")

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto shadow-2xl">
        {/* Header */}
        <div className="sticky top-0 bg-white border-b p-6 flex justify-between items-center z-10">
          <h2 className="text-2xl font-bold text-gray-900">Expert Profile</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            aria-label="Close"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-6">
          {/* Profile Header */}
          <div className="flex items-center gap-4 mb-6">
            <div className="w-24 h-24 relative rounded-full overflow-hidden shadow-lg">
              <img src={expert.image || "/placeholder.svg"} alt={expert.name} className="object-cover w-full h-full" />
            </div>
            <div>
              <div className="flex items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{expert.name}</h1>
                <CheckCircle2 className="w-5 h-5 text-blue-500" />
              </div>
              <div className="flex items-center gap-2">
                <span className="font-semibold text-xl">{expert.rating.toFixed(1)}</span>
                <div className="flex gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className={`w-4 h-4 ${
                        i < Math.floor(expert.rating) ? "text-yellow-400 fill-current" : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
                <span className="text-blue-600 font-medium text-sm">({expert.reviews} reviews)</span>
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-50 rounded-lg p-4">
            {[
              { label: "Completed orders", value: expert.ordersFinished },
              { label: "Years on Platform", value: expert.yearsActive },
              { label: "Satisfied students", value: expert.satisfiedStudents },
              { label: "Last completed", value: expert.lastCompleted },
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-2xl font-bold text-gray-900">{stat.value}</div>
                <div className="text-xs text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>

          {/* Skills */}
          <div>
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Skilled in:</h3>
            <div className="flex flex-wrap gap-2">
              {expert.skills.map((skill, index) => (
                <span key={index} className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-xs font-medium">
                  {skill}
                </span>
              ))}
            </div>
          </div>

          {/* Education */}
          <div>
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Education</h3>
            <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-3">
              <div className="text-sm text-gray-900">Major in {expert.education.major}</div>
              {expert.education.isVerified && (
                <div className="flex items-center text-green-600 text-xs font-medium bg-green-50 px-2 py-1 rounded-full">
                  <CheckCircle2 className="w-3 h-3 mr-1" />
                  Verified
                </div>
              )}
            </div>
          </div>

          {/* Bio */}
          <div>
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Bio</h3>
            <div className="bg-gray-50 rounded-lg p-4">
              <p className={`text-sm text-gray-700 leading-relaxed ${!showFullBio && "line-clamp-3"}`}>{expert.bio}</p>
              <button
                onClick={() => setShowFullBio(!showFullBio)}
                className="text-blue-600 hover:text-blue-700 mt-2 flex items-center text-sm font-medium transition-colors duration-200"
              >
                {showFullBio ? (
                  <>
                    Show less
                    <ChevronUp className="w-4 h-4 ml-1" />
                  </>
                ) : (
                  <>
                    Show more
                    <ChevronDown className="w-4 h-4 ml-1" />
                  </>
                )}
              </button>
            </div>
          </div>

          {/* Satisfaction Guarantee */}
          <div className="bg-blue-50 rounded-lg p-4">
            <div className="flex items-start gap-4">
              <Shield className="w-10 h-10 text-blue-500 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-1 text-gray-900">Release funds only if you are satisfied</h3>
                <p className="text-sm text-gray-700 leading-relaxed">
                  Our platform lets you withhold funds if the result doesn't satisfy you, ensuring you only pay for work
                  that meets your standards. Your satisfaction is our priority.
                </p>
              </div>
            </div>
          </div>

          {/* Ratings & Reviews */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-900">Rating and reviews</h3>
            <div className="flex items-center gap-6 mb-6 bg-gray-50 rounded-lg p-4">
              <div className="flex items-center gap-2">
                <Star className="w-10 h-10 text-yellow-400 fill-current" />
                <span className="text-3xl font-bold text-gray-900">{expert.rating.toFixed(1)}</span>
              </div>
              <div>
                <div className="text-lg font-semibold text-gray-900">Very good</div>
                <div className="text-sm text-gray-600">Based on {expert.reviews} reviews</div>
              </div>
            </div>

            {/* Rating Breakdown */}
            <div className="space-y-3 mb-6">
              {Object.entries(expert.ratingBreakdown).map(([key, data]) => (
                <div key={key} className="flex items-center gap-3">
                  <div className="w-36 text-sm text-gray-700">
                    {key.replace(/([A-Z])/g, " $1").trim()} ({data.count})
                  </div>
                  <div className="flex-1">
                    <div className="h-2 bg-gray-200 rounded-full">
                      <div className="h-2 bg-blue-600 rounded-full" style={{ width: `${(data.score / 5) * 100}%` }} />
                    </div>
                  </div>
                  <div className="w-10 text-right text-sm font-semibold text-gray-900">{data.score.toFixed(2)}</div>
                </div>
              ))}
            </div>

            {/* Review Filters */}
            <div className="flex gap-2 mb-4 overflow-x-auto pb-2">
              {["All", "Nursing", "Sociology", "Literature"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setSelectedTab(tab)}
                  className={`px-3 py-1 rounded-full whitespace-nowrap text-xs font-medium transition-colors duration-200 ${
                    selectedTab === tab ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                >
                  {tab} {tab === "All" && `(${expert.reviews})`}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

