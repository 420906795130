import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

const FullScreenModal = ({ bgContent,content, open, onClose, size = "lg",maxWidth="" }) => {
  useEffect(()=>{
    const element=document.getElementsByClassName("full-screen-modal");
    if(element[0]){
    element[0].removeAttribute("tabindex")
    }
  })
  return (
    <Modal
    size={size}
    show={open}
    onHide={onClose}
    aria-labelledby="full-screen-modal"
  
    className={`full-screen-modal ${bgContent} ${maxWidth}`}
 
  >
    {content()}
    </Modal>
  )
}

export default FullScreenModal