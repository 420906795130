import React, { useState, useEffect, useRef, useCallback } from "react";
import { Clock, X, User, Calendar, DollarSign, UserCheck, History, ChevronRight, Search, AlertCircle, Eye } from 'lucide-react';
import { useSelector } from "react-redux";
import UserDetailsModal from "./UserDetailsModal";

const OrderListReport = ({
  selectedMetric,
  setSelectedMetric,
  metrics,
  fetchOrderList,
  setPageData,
  pageData,
  setSearch,
  search,
  selectedDateFilter,
  handleDateFilterChange
}) => {
  const { reports, orderListReport } = useSelector((state) => state.commonReducer);
  const [expandedHistory, setExpandedHistory] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const tableRef = useRef(null);

  const observer = useRef();
  const lastOrderElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListReport?.orders.length < orderListReport?.totalResults) {
          fetchMoreOrders();
        }
      });
      if (node) observer.current.observe(node);
    },
    [orderListReport?.orders.length, orderListReport?.totalResults]
  );

  const fetchMoreOrders = () => {
    fetchOrderList({ page: pageData.page + 1 });
    setPageData((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case "DELIVERED":
        return "bg-[#00FF9D]/10 text-[#00FF9D] border border-[#00FF9D]/20";
      case "ASSIGNED":
        return "bg-[#9D00FF]/10 text-[#9D00FF] border border-[#9D00FF]/20";
      case "IN PROGRESS":
        return "bg-[#00A3FF]/10 text-[#00A3FF] border border-[#00A3FF]/20";
      case "PENDING":
        return "bg-[#FFD100]/10 text-[#FFD100] border border-[#FFD100]/20";
      default:
        return "bg-gray-500/10 text-gray-400 border border-gray-500/20";
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUser(userId);
  };

  return (
    selectedMetric && (
      <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-start justify-center z-50 p-2 overflow-y-auto">
        <div className="bg-[#001b3d] rounded-lg w-full max-w-[98vw] mx-auto relative shadow-2xl mt-2">
          {/* Header */}
          <div className="p-3 border-b border-white/10 bg-white/5">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-2">
                <div className="p-1.5 bg-blue-500/20 rounded-lg border border-blue-500/30">
                  <User className="h-4 w-4 text-blue-400" />
                </div>
                <h2 className="text-base font-semibold text-white">
                  {metrics(reports?.current)?.find((m) => m.id === selectedMetric)?.title} Details
                </h2>
              </div>
              <button
                onClick={() => setSelectedMetric(null)}
                className="p-1.5 hover:bg-white/10 rounded-lg transition-colors"
              >
                <X className="h-4 w-4 text-white/70 hover:text-white" />
              </button>
            </div>

            {/* Filters */}
            <div className="flex flex-wrap gap-2 items-center">
              <div className="relative flex-1 min-w-[250px]">
                <Search className="absolute left-2.5 top-1/2 -translate-y-1/2 h-3.5 w-3.5 text-white/50" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    fetchOrderList({ tempSearch: e.target.value, firstTime: true, page: 1 });
                    setSearch(e.target.value);
                    setPageData({ page: 1 });
                  }}
                  placeholder="Search orders..."
                  className="w-full pl-8 pr-3 py-1.5 text-xs rounded-md bg-white/5 border border-white/10 text-white placeholder-white/50 focus:outline-none focus:border-blue-500/50"
                />
              </div>
              <div className="flex gap-1.5 flex-wrap">
                {["all", "today", "yesterday", "previous7days"].map((filter) => (
                  <button
                    key={filter}
                    onClick={() => handleDateFilterChange(filter)}
                    className={`px-3 py-1.5 rounded-md text-xs font-medium ${
                      selectedDateFilter === filter
                        ? "bg-blue-500/80 text-white"
                        : "bg-white/5 text-white/70 hover:bg-white/10 hover:text-white"
                    }`}
                  >
                    {filter.charAt(0).toUpperCase() + filter.slice(1).replace(/([A-Z])/g, ' $1')}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Table Container */}
          <div className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent" ref={tableRef}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-white/5">
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">#</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Order ID</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Status</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Client</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Expert</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Order Date</th>
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Deadline</th>
                  <th className="py-2 px-3 text-right text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Total</th>
                  <th className="py-2 px-3 text-center text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/10">
                {orderListReport?.orders.map((order, index) => (
                  <tr
                    key={order.id}
                    ref={index === orderListReport.orders.length - 1 ? lastOrderElementRef : null}
                    className="hover:bg-white/5 transition-colors"
                    onMouseEnter={() => setHoveredRow(order.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">{index + 1}</td>
                    <td className="py-1.5 px-3 text-[11px] text-white/90 font-medium align-middle whitespace-nowrap">{order.id}</td>
                    <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                      <span className={`px-2 py-0.5 rounded-md text-[10px] font-medium inline-block ${getStatusColor(order.status)}`}>
                        {order.status}
                      </span>
                    </td>
                    <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                      <button
                        onClick={() => handleUserClick(order?.clientId)}
                        className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                      >
                        {order.customer}
                        <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                      </button>
                    </td>
                    <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                      <button
                        onClick={() => handleUserClick(order.expert?.id)}
                        className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                      >
                        {order.expert.name}
                        <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                      </button>
                    </td>
                    <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">{order.date}</td>
                    <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">{order.deadline}</td>
                    <td className="py-1.5 px-3 text-[11px] text-white/90 font-medium text-right align-middle whitespace-nowrap">{order.payments.total}</td>
                    <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                      <button
                        onClick={() => setExpandedHistory(expandedHistory === order.id ? null : order.id)}
                        className="flex items-center gap-1.5 text-white/50 hover:text-white mx-auto"
                      >
                        <History className="h-3.5 w-3.5" />
                        <ChevronRight
                          className={`h-3 w-3 transition-transform ${
                            expandedHistory === order.id ? "rotate-90" : ""
                          }`}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* History Modal */}
          {expandedHistory && (
            <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[60]">
              <div className="bg-[#001b3d] rounded-lg p-4 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto border border-white/10 shadow-2xl">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <div className="p-1.5 bg-blue-500/20 rounded-lg border border-blue-500/30">
                      <History className="h-4 w-4 text-blue-400" />
                    </div>
                    <h3 className="text-sm font-semibold text-white">Order History</h3>
                  </div>
                  <button
                    onClick={() => setExpandedHistory(null)}
                    className="p-1.5 hover:bg-white/10 rounded-lg transition-colors"
                  >
                    <X className="h-4 w-4 text-white/70 hover:text-white" />
                  </button>
                </div>
                <div className="space-y-3">
                  {orderListReport?.orders
                    .find((order) => order.id === expandedHistory)
                    ?.history.map((event, index) => (
                      <div key={index} className="flex gap-3 items-start">
                        <div className="relative">
                          <div className="w-2 h-2 rounded-full bg-blue-500 mt-1.5 z-10 relative" />
                          {index !== orderListReport?.orders.find((order) => order.id === expandedHistory)?.history.length - 1 && (
                            <div className="absolute top-2.5 left-1 bottom-0 w-px bg-gradient-to-b from-blue-500 to-transparent" />
                          )}
                        </div>
                        <div className="flex-1 bg-white/5 p-2.5 rounded-lg border border-white/10">
                          <p className="text-xs text-white/90">{event.event}</p>
                          <p className="text-[11px] text-white/50 mt-0.5">
                            {event.date} - {event.status}
                          </p>
                          {event.user?.name && (
                            <button
                              onClick={() => handleUserClick(event.user.id)}
                              className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors mt-1 flex items-center gap-1.5"
                            >
                              {event.user.name} ({event.user.userType})
                              <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedUser && (
          <UserDetailsModal userId={selectedUser} onClose={() => setSelectedUser(null)} />
        )}
      </div>
    )
  );
};

export default OrderListReport;

