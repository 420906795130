import React, { useState } from "react"
import { useSelector } from "react-redux"
import ClickToCall from "./CallNow"
import UserContactDetails from "./contact-details/UserContactDetails"
import { returnDateWithTime } from "../utils/functions/functions"

const PersonalCard = ({ close, data, onEditClick, onDeleteClick, hideAction }) => {
  const [showActions, setShowActions] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(null)
  const { user } = useSelector((state) => state.authenticationReducer)
  const isManagerOrAdminOrOperator =
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "OPERATOR" ||
    user?.userType === "SUPER_ADMIN"
  const image =
    "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"

  const toggleAccordion = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section)
  }

  const getInitials = (name) => {
    return name
      ?.split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase()
  }

  const renderOperatorInfo = () => (
    <div className="space-y-3 p-2">
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Name
        </span>
        <span className="text-[#00234a] font-medium">{data?.name}</span>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Email
        </span>
        <span className="text-[#00234a]">{data?.email}</span>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Phone
        </span>
        <span className="text-[#00234a]">
          {data?.number?.includes("+") ? "" : data?.countryCode} {data?.number}
        </span>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Department
        </span>
        <span className="text-[#00234a]">{data?.userType}</span>
      </div>
    </div>
  )

  const renderClientInfo = () => (
    <div className="space-y-3 p-2">
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Email
        </span>
        <span className="text-[#00234a]">{data?.email}</span>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-2">
        <span className="text-gray-600">
          Phone
        </span>
        <span className="text-[#00234a]">
          {data?.number?.includes("+") ? "" : data?.countryCode} {data?.number}
        </span>
      </div>
    </div>
  )

  return (
    <div className="flex justify-center items-center min-h-screen p-4 bg-transparent">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Header */}
        <div className="relative bg-[#00234a] text-white p-4">
          <button className="absolute top-4 right-4 text-white/80 hover:text-white" onClick={close}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="flex items-center gap-3">
            <div className="w-12 h-12 rounded-full bg-[#c73a1d] flex items-center justify-center text-xl font-bold">
              {getInitials(data?.name) || "U"}
            </div>
            <div>
              <h2 className="text-lg font-semibold leading-tight text-white">{data?.name}</h2>
              <p className="text-sm text-white/80">
                {data?.userType} • {data?.departMent || data?.expertType || ""}
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="p-4">
          {/* Phone and Actions */}
          <div className="flex items-center justify-between mb-4">
            <div className="bg-[#00234a]/10 text-[#00234a] px-3 py-1.5 rounded-md text-sm font-medium">
              {data?.number?.includes("+") ? "" : data?.countryCode} {data?.number}
            </div>
            {!hideAction && (
              <div className="relative">
                <button
                  className="bg-[#00234a] text-white px-4 py-1.5 rounded text-sm font-medium hover:bg-[#001a38] transition-colors flex items-center gap-1"
                  onClick={() => setShowActions(!showActions)}
                >
                  Actions
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-4 w-4 transition-transform ${showActions ? "rotate-180" : ""}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {showActions && (
                  <div className="absolute right-0 mt-1 w-36 bg-white border border-gray-100 rounded shadow-lg z-10">
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-[#00234a] hover:bg-gray-50 transition-colors"
                      onClick={() => onEditClick(data)}
                    >
                      Edit
                    </button>
                    {data?._id && (
                      <button
                        className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                        onClick={() => onDeleteClick(data?._id)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex gap-2 mb-4">
            {user?.userType === "MANAGER" && (
              <button
                onClick={() => ClickToCall(data?.countryCode + data?.number)}
                className="flex-1 bg-[#00234a] text-white py-2 px-4 rounded text-sm font-medium hover:bg-[#001a38] transition-colors"
              >
                Call Now
              </button>
            )}
            {isManagerOrAdminOrOperator && !!data?.contactDetails?.length && (
              <UserContactDetails contactDetails={data?.contactDetails} />
            )}
          </div>

          {/* Conditional Content Based on UserType */}
          {data?.userType === "OPERATOR" && renderOperatorInfo()}
          {data?.userType === "CLIENT" && renderClientInfo()}

          {/* Expert Accordion Sections */}
          {data?.userType === "EXPERT" && (
            <div className="space-y-1">
              {[
                { id: "personal-info", title: "Personal Info" },
                { id: "education", title: "Education" },
                { id: "expertise", title: "Expertise" },
                { id: "documents", title: "Documents" },
                { id: "work-samples", title: "Work Samples" },
              ].map((section) => (
                <div key={section.id} className="border-b border-gray-100 last:border-0">
                  <button
                    className="flex items-center justify-between w-full py-3 text-left"
                    onClick={() => toggleAccordion(section.id)}
                  >
                    <span className="text-[#00234a] font-medium">{section.title}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-4 w-4 text-[#00234a] transition-transform ${
                        activeAccordion === section.id ? "rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>

                  {activeAccordion === section.id && (
                    <div className="pb-3 text-sm">
                      {section.id === "personal-info" && (
                        <div className="space-y-2">
                          <p className="flex justify-between">
                            <span className="text-gray-600">Email</span>
                            <span className="text-[#00234a]">{data?.email}</span>
                          </p>
                          <p className="flex justify-between">
                            <span className="text-gray-600">Status</span>
                            <span className="text-[#00234a]">{data?.status}</span>
                          </p>
                          <p className="flex justify-between">
                            <span className="text-gray-600">Department</span>
                            <span className="text-[#00234a]">{data?.userType}</span>
                          </p>
                          <p className="flex justify-between">
                            <span className="text-gray-600">Joined</span>
                            <span className="text-[#00234a]">{returnDateWithTime(data?.createdAt)}</span>
                          </p>
                        </div>
                      )}

                      {section.id === "education" && (
                        <div className="flex flex-wrap gap-1.5">
                          {data?.educations?.map((edu, index) => (
                            <span key={index} className="px-2 py-1 bg-[#00234a]/10 text-[#00234a] rounded-md text-xs">
                              {edu}
                            </span>
                          ))}
                          {data?.degrees?.map((degree, index) => (
                            <span key={index} className="px-2 py-1 bg-[#00234a]/5 text-[#00234a] rounded-md text-xs">
                              {degree}
                            </span>
                          ))}
                        </div>
                      )}

                      {section.id === "expertise" && (
                        <div className="space-y-3">
                          {Array.isArray(data?.primaryExpertise) && data?.primaryExpertise.length > 0 && (
                            <div>
                              <h4 className="text-gray-600 mb-1.5">Primary</h4>
                              <div className="flex flex-wrap gap-1.5">
                                {data.primaryExpertise.map((exp, index) => (
                                  <span
                                    key={index}
                                    className="px-2 py-1 bg-[#00234a]/10 text-[#00234a] rounded-md text-xs"
                                  >
                                    {exp}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )}
                          {Array.isArray(data?.secondaryExpertise) && data?.secondaryExpertise.length > 0 && (
                            <div>
                              <h4 className="text-gray-600 mb-1.5">Secondary</h4>
                              <div className="flex flex-wrap gap-1.5">
                                {data.secondaryExpertise.map((exp, index) => (
                                  <span
                                    key={index}
                                    className="px-2 py-1 bg-[#00234a]/5 text-[#00234a] rounded-md text-xs"
                                  >
                                    {exp}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {section.id === "documents" && (
                        <div className="space-y-3">
                          {data?.pancardImage && (
                            <div>
                              <h4 className="text-gray-600 mb-1.5">PAN Card</h4>
                              <img
                                src={data.pancardImage || "/placeholder.svg"}
                                alt="PAN Card"
                                className="w-full rounded-lg border border-gray-200"
                              />
                            </div>
                          )}
                          {data?.bankDetails?.document && (
                            <div>
                              <h4 className="text-gray-600 mb-1.5">Bank Document</h4>
                              <img
                                src={data.bankDetails.document || "/placeholder.svg"}
                                alt="Bank Document"
                                className="w-full rounded-lg border border-gray-200"
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {section.id === "work-samples" && data?.workSamples?.length > 0 && (
                        <div className="grid grid-cols-2 gap-2">
                          {data.workSamples.map((sample, index) => (
                            <a
                              key={index}
                              href={sample}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="px-3 py-2 bg-[#00234a]/5 hover:bg-[#00234a]/10 text-[#00234a] rounded-md text-xs transition-colors"
                            >
                              Sample {index + 1}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonalCard

