import { Row, Col, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import {
  getLeadDetailAction,
  getNotificationListAction,
  startChatAction,
  updateNotificationStatusAction,
} from "../../../../services/action/common";
import { Badge } from "@mui/material";
import CustomModal from "../../../utils/modals/CustomModal";
import LiveUserNewDashboard from "../../../Live-chat-users/LiveUserNewDashboard";
import { useState } from "react";
import FullScreenModal from "../../../utils/modals/FullScreenModal";

const NotificationList = () => {
  const { notificationList } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [openLiveChat, setopenLiveChat] = useState(false);
  const dispatch = useDispatch();
  const userType = user?.userType?.toLowerCase();
  const navigate = useNavigate();

  const cbNotification = () => {
    dispatch(getNotificationListAction({ userType }));
  };
  const handleOpenChat = (
    leadId,
    subject,
    senderName,
    messageId,
    notificationId,
    isNew,
    orderId
  ) => {
    if (isNew) {
      dispatch(
        updateNotificationStatusAction({
          userType,
          data: { leadId: leadId },
          cb: cbNotification,
        })
      );
    }
    if (userType !== "client") {
      dispatch(getLeadDetailAction({ userType, data: { leadId } }));
    }
    dispatch(
      startChatAction({
        leadDetail: { senderName, orderId, messageId, _id: leadId, subject },
        userId: user?._id,
      })
    );
  };

  const startOpenChat = ({ userId, isNew }) => {
    setopenLiveChat(userId);
    if (isNew) {
      dispatch(
        updateNotificationStatusAction({
          userType,
          data: { userId },
          cb: cbNotification,
        })
      );
    }
  };

  return (
    <SimpleBar style={{ maxHeight: "300px" }}>
      <FullScreenModal
        size="xl"
        open={openLiveChat}
        maxWidth="max-width-modal-dialog"
        content={() => (
          <LiveUserNewDashboard
            visitorId={openLiveChat}
            setopenLiveChat={setopenLiveChat}
            isContentOnly={true}
          />
        )}
        onClose={() => setopenLiveChat(false)}
      />
      <ListGroup variant="flush">
        {notificationList?.data?.map(function (item, index) {
          return (
            <ListGroup.Item
              className={!item?.isSeen ? "bg-light" : ""}
              key={index}
            >
              <Row>
                <Col>
                  <Link
                    href="#"
                    className="text-muted"
                    onClick={() => {
                      if (item?.messageType == "EXTERNAL") {
                        startOpenChat({
                          userId: item?.userId,
                          isNew: !item?.isSeen,
                          notificationId: item?._id,
                          visitorId: item?.visitorId,
                        });
                      } else {
                        handleOpenChat(
                          item?.leadId,
                          item?.subject,
                          item?.senderName,
                          item?.messageId,
                          item?._id,
                          !item?.isSeen,
                          item?.orderId
                        );
                      }
                    }}
                  >
                    {" "}
                    <Badge
                      badgeContent={item?.messageNewMessageCount}
                      color="secondary"
                      className="notification-badge pr-3"
                    >
                      <h5 className=" mb-1 ">
                        {/* <Badge badgeContent={"New"} color="secondary" className="notification-badge"> */}
                        {!item?.isSeen && (
                          <span className="notification-badge">•</span>
                        )}
                        {item?.senderName} (#{item?.orderId || item?.visitorId})
                        {/* </Badge> */}
                      </h5>
                    </Badge>
                    {/* <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: item?.message }}
                    ></p> */}
                  </Link>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
        {notificationList?.data?.length < 1 && (
          <p className="pl-4">Notification is empty</p>
        )}
      </ListGroup>
    </SimpleBar>
  );
};

export default NotificationList;
