import React, { useState, useRef, useCallback } from "react";
import {  X, User, Search } from "lucide-react";
import { useSelector } from "react-redux";
import UserDetailsModal from "./UserDetailsModal";

const UserReportList = ({
  selectedMetric,
  setSelectedMetric,
  metrics,
  fetchOrderList,
  setPageData,
  pageData,
  setSearch,
  search,
}) => {
  const { reports, userReportList } = useSelector((state) => state.commonReducer);

  const [selectedUser, setSelectedUser] = useState(null);

  const observer = useRef();
  const lastOrderElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && userReportList?.data?.length < userReportList?.totalUsers) {
          fetchMoreOrders();
        }
      });
      if (node) observer.current.observe(node);
    },
    [userReportList?.data?.length, userReportList?.totalUsers]
  );

  const fetchMoreOrders = () => {
    fetchOrderList({ page: pageData.page + 1 });
    setPageData((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case "ACTIVE":
        return "bg-emerald-600 text-emerald-100";
      case "NOT ACTIVE":
        return "bg-gray-600 text-gray-100";
      default:
        return "bg-gray-600 text-gray-100";
    }
  };



  return (
    selectedMetric && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-gradient-to-br from-[#001B3D] to-[#003366] rounded-lg w-full max-w-7xl mx-4 relative overflow-hidden max-h-[90vh] flex flex-col shadow-2xl">
          {/* Header Section */}
          <div className="p-6 flex-shrink-0 border-b border-white/10">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-blue-500 rounded-full">
                  <User className="h-6 w-6 text-white" />
                </div>
                <h2 className="text-3xl font-bold text-white">
                  {metrics(reports?.current)?.find((m) => m.id === selectedMetric)?.title} Details
                </h2>
              </div>
              <button
                onClick={() => setSelectedMetric(null)}
                className="text-white/60 hover:text-white transition-colors p-2 rounded-full hover:bg-white/10"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="Search users..."
                value={search}
                onChange={(e) => {
                  fetchOrderList({ tempSearch: e.target.value, firstTime: true, page: 1 });
                  setSearch(e.target.value);
                  setPageData({ page: 1 });
                }}
                className="bg-[#0A2547] text-white py-3 px-4 pr-12 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            </div>
          </div>

          {/* User Report Section */}
          <div className="overflow-y-auto flex-grow p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {userReportList?.data?.map((user, index) => (
                <div
                  key={user.id}
                  ref={index === userReportList?.data?.length - 1 ? lastOrderElementRef : null}
                  className="bg-[#0A2547] rounded-lg p-4 hover:bg-[#0F2F5B] transition-colors shadow-lg"
                >
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-2">
                      <span className="text-white text-sm font-semibold bg-blue-600 rounded-full w-6 h-6 flex items-center justify-center">
                        {index + 1}
                      </span>
                      <span className="text-white text-lg font-semibold">{user.email}</span>
                    </div>
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(user.status)}`}
                    >
                      {user.status}
                    </span>
                  </div>

                  {/* User Details */}
                  <div className="grid gap-2 text-sm">
                    <InfoItem label="Name" value={user.name} />
                    <InfoItem label="Number" value={user.number} />
                    <InfoItem label="Wallet" value={user.wallet || 0} />
                    {user.userType === "CLIENT" && (
                      <>
                        <InfoItem label="Total Orders" value={user.totalOrders} />
                        <InfoItem label="Revenue Generated" value={user.revenueGenerated+ ` (${user?.currency || "INR"})`} />
                        <InfoItem label="Balance Amount" value={user.balanceAmount+ ` (${user?.currency  || "INR"})`} />
                      </>
                    )}
                    {user.userType === "EXPERT" && (
                      <>
                        <InfoItem label="Expert Type" value={user.expertType} />
                        <InfoItem label="Assigned Orders" value={user.assignedOrder} />
                        <InfoItem label="Completed Orders" value={user.completedOrder} />
                        <InfoItem label="Missed Orders" value={user.missedOrder} />
                        <InfoItem label="Total Earnings" value={user.totalEarning} />
                      </>
                    )}
                    {["OPERATOR", "MANAGER"].includes(user.userType) && (
                      <InfoItem label="Orders Created" value={user.orderCreated} />
                    )}
                    <InfoItem label="Last Login" value={user.lastLogin || "N/A"} />
                    <InfoItem label="Rating" value={user.rating || "4.5"} />
                    <InfoItem label="Is Online" value={user.isOnline || "Offline"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {selectedUser && (
          <UserDetailsModal userId={selectedUser} onClose={() => setSelectedUser(null)} />
        )}
      </div>
    )
  );
};

const InfoItem = ({ label, value }) => (
  <div className="flex items-center gap-2 text-gray-400">
    <span className="text-white">{label}: </span>
    <span className="text-blue-400">{value}</span>
  </div>
);

export default UserReportList;
