import {
	Briefcase,
    ListTask,
    People,
    Bullseye
} from 'react-bootstrap-icons';

export const ProjectsStats = (data)=>[
    {
       id:1,
       title : "Client",
       value : data?.clients,
       icon: <Briefcase size={18}/>,
       statInfo: '<span className="text-dark me-2">2</span> Completed' 
    },
    {
        id:2,
        title : "Operators",
        value : data?.operators,
        icon: <ListTask size={18}/>,
        statInfo: '<span className="text-dark me-2">28</span> Completed' 
     },
     {
        id:3,
        title : "Experts",
        value : data?.experts,
        icon: <People size={18}/>,
        statInfo: '<span className="text-dark me-2">1</span> Completed' 
     },
     {
        id:4,
        title : "Leads",
        value : data?.leads,
        icon: <Bullseye size={18}/>,
        statInfo: '<span className="text-dark me-2">5%</span> Completed' 
     }
];
export default ProjectsStats;

export const DashboardCardClient = (data)=>[
    {
       id:1,
       title : "Orders",
       value : data?.totalLeads,
       icon: <People size={18}/>,
       statInfo: '<span className="text-dark me-2">1</span> Completed' 
    }
];

export const DashboardCardExpert = (data)=>[
   {
      id:2,
      title : "Total Orders",
      value : data?.totalLeads || data?.totalOrders ||0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:2,
      title : "Completed Orders",
      value : data?.completedOrders,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {  
      id:2,
      title : "Today's Deadline orders",
      value : data?.todayDeadlineLeads || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:2,
      title : "Total Applied",
      value : data?.totalApplied || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   }
];
export const DashboardCardOperator = (data)=>[
   {
      id:1,
      title : "Client",
      value : data?.clients,
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
       id:2,
       title : "Operators",
       value : data?.operators,
       icon: <ListTask size={18}/>,
       statInfo: '<span className="text-dark me-2">28</span> Completed' 
    },
    {
       id:3,
       title : "Experts",
       value : data?.experts,
       icon: <People size={18}/>,
       statInfo: '<span className="text-dark me-2">1</span> Completed' 
    },
    {
       id:4,
       title : "Leads",
       value : data?.leads,
       icon: <Bullseye size={18}/>,
       statInfo: '<span className="text-dark me-2">5%</span> Completed' 
    }
];


export const ReportCardOrders = (data)=>[
   {
      id:1,
      title : "Total Orders",
      value : data?.totalOrders || 0,
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
      id:2,
      title : "Completed Orders",
      value : data?.completedOrder || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
       id:3,
       title : "Missed Deadline Orders",
       value : data?.missedOrder || 0,
       icon: <ListTask size={18}/>,
       statInfo: '<span className="text-dark me-2">28</span> Completed' 
    },
    {
       id:4,
       title : "Order Inprogress",
       value : data?.inProgressOrder || 0,
       icon: <People size={18}/>,
       statInfo: '<span className="text-dark me-2">1</span> Completed' 
    },
];

export const ReportCardUser = (data)=>[
   {
      id:1,
      title : "Clients",
      value : data?.clients || 0,
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
      id:2,
      title : "Operators (Sales)",
      value : data?.operatorSales || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:3,
      title : "Operators (Operations)",
      value : data?.operatorOperations || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
      id:4,
      title : "Operators (Accounts)",
      value : data?.operatorAccounts || 0,
      icon: <Bullseye size={18}/>,
      statInfo: '<span className="text-dark me-2">5%</span> Completed' 
   },
   {
       id:5,
       title : "Experts(Internal)",
       value : data?.expertInternal || 0,
       icon: <ListTask size={18}/>,
       statInfo: '<span className="text-dark me-2">28</span> Completed' 
    },
    {
      id:6,
      title : "Experts(External)",
      value : data?.expertExternal || 0,
      icon: <ListTask size={18}/>,
      statInfo: '<span className="text-dark me-2">28</span> Completed' 
   },
];

export const ReportCardRevenue = (data)=>[
   {
      id:1,
      title : "Order Amount",
      value :!data?.totalOrderAmount && data?.totalOrderAmount!==0 ?"Loading...": Math.abs(data?.totalOrderAmount)+" INR",
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
      id:2,
      title : "Payment Received",
      value :!data?.paymentReceived && data?.paymentReceived!==0?"Loading...": data?.paymentReceived+" INR",
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
      id:3,
      title : "Balance Amount",
      value :!data?.balanceAmount  && data?.balanceAmount!==0?"Loading...":  Math.abs(data?.balanceAmount)+" INR",
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
   {
      id:4,
      title : "Expert Amount",
      value :  !data?.expertAmount && data?.expertAmount!==0?"Loading...":data?.expertAmount+" INR",
      icon: <Briefcase size={18}/>,
      statInfo: '<span className="text-dark me-2">2</span> Completed' 
   },
];
