"use client"

import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import { Users, Share2 ,MonitorCog, Headset, Logs , ClipboardList, BarChart2, ShoppingCart, UserPlus, CreditCard, FileText, LogOut, Activity, HelpCircle,  ChevronUp, ChevronDown, LayoutDashboard } from 'lucide-react'
import {
  SidebarOptionSuperAdmin,
  DashboardMenuClient,
  DashboardMenuExpert,
  DashboardMenuManager,
  DashboardMenuOperator,
  SidebarOptionAdmin,
  SidebarOptionMarketing,
} from "../../utils/LayoutConstant"
import { useSelector } from "react-redux"
import { MobileOffRounded } from "@mui/icons-material"
import { checkLogin } from "../../utils/functions/functions"

const NavbarVertical = () => {
  const { user } = useSelector((state) => state.authenticationReducer)
  const location = useLocation()
  const navigate = useNavigate()

  // Dynamically calculate sidebar based on user object
  const sideBar = useMemo(() => {
    if (user?.userType === "MANAGER") {
      return DashboardMenuManager(user)
    } else if (user?.userType === "CLIENT") {
      return DashboardMenuClient
    } else if (user?.userType === "OPERATOR") {
      return DashboardMenuOperator(user)
    } else if (user?.userType === "EXPERT") {
      return DashboardMenuExpert
    } else if (user?.userType === "SUPER_ADMIN") {
      return SidebarOptionSuperAdmin(user)
    } else if (user?.userType === "ADMIN") {
      return SidebarOptionAdmin(user)
    } else if (user?.userType === "MARKETING") {
      return SidebarOptionMarketing(user)
    }
    return []
  }, [user])

  // Manage open/close state of menus
  const openMenus = useMemo(() => {
    const currentPath = location.pathname
    const newOpenMenus = {}
    sideBar.forEach((menu) => {
      if (menu.children) {
        const isChildActive = menu.children.some((child) => child.link === currentPath)
        if (isChildActive) {
          newOpenMenus[menu.title] = true
        }
      }
    })
    return newOpenMenus
  }, [location.pathname, sideBar])

  const [menuState, setMenuState] = useState(openMenus)

  useEffect(() => {
    setMenuState(openMenus)
  }, [openMenus])

  const toggleMenu = useCallback((menuTitle) => {
    setMenuState((prev) => ({ ...prev, [menuTitle]: !prev[menuTitle] }))
  }, [])

  const handleNavigation = useCallback(
    (link, menuTitle) => {
      navigate(link)
      if (menuTitle) {
        setMenuState((prev) => ({ ...prev, [menuTitle]: true }))
      }
    },
    [navigate]
  )

  const getRelevantIcon = useCallback((menuTitle) => {
    const IconProps = { className: "text-lg" }
    
    switch (menuTitle.toLowerCase()) {
      case "dashboard":
        return <LayoutDashboard {...IconProps} />
      case "users":
        return <Users {...IconProps} />
      case "leads & order":
        return <Logs {...IconProps} />
      case "analytics":
        return <BarChart2 {...IconProps} />
      case "orders":
        return <ShoppingCart {...IconProps} />
      case "subscribers":
        return <UserPlus {...IconProps} />
      case "payouts":
        return <CreditCard {...IconProps} />
      case "posts":
        return <FileText {...IconProps} />
      case "logout":
        return <LogOut {...IconProps} />
      case "live users":
        return <Activity {...IconProps} />
      case "reports":
        return <BarChart2 {...IconProps} />
      case "queries":
        return <Headset {...IconProps} />
      case "android app panel":
          return <MonitorCog/>
      case "share":
        return <Share2/>
      case "form leads":
        return <Headset/>
      default:
        return <Headset {...IconProps} />
    }
  }, [])

  return (
    <Fragment>
      <SimpleBar className="h-screen text-gray-100">
        <nav className="py-4">
          <div className="px-4 mb-6">
            <span  onClick={()=>checkLogin(navigate,user)} className="flex items-center justify-center">
              <img
                src="/assets/images/logo.png"
                alt="Clickinpedia"
                className="h-10 w-auto filter invert"
              />
            </span>
          </div>
          <ul className="space-y-2 px-2">
            {sideBar.map((menu, index) => (
              <li key={index}>
                <div
                  className={`flex items-center justify-between p-2 rounded-lg cursor-pointer transition-colors duration-150 ease-in-out ${
                    menu.link === location.pathname
                      ? "bg-secondary-2 text-white"
                      : "text-gray-300 hover:bg-gray-800"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (menu.children) {
                      toggleMenu(menu.title)
                    } else if (menu.link) {
                      handleNavigation(menu.link)
                      menu?.callFunction && menu?.callFunction()
                    }
                  }}
                >
                  <div className="flex items-center space-x-3">
                    {getRelevantIcon(menu.title)}
                    <span
                      className="font-medium"
                      onClick={() => menu?.callFunction && menu?.callFunction()}
                    >
                      {menu.title}
                    </span>
                  </div>
                  {menu.children && (
                    menuState[menu.title] ? (
                      <ChevronUp className="text-sm" />
                    ) : (
                      <ChevronDown className="text-sm" />
                    )
                  )}
                </div>
                {menu.children && menuState[menu.title] && (
                  <ul className="mt-2 ml-6 space-y-1">
                    {menu.children.map((child, childIndex) => (
                      <li key={childIndex}>
                        <div
                          className={`p-2 rounded-md cursor-pointer transition-colors duration-150 ease-in-out ${
                            child.link === location.pathname
                              ? "bg-gray-700 text-white"
                              : "text-gray-400 hover:bg-gray-800 hover:text-gray-200"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleNavigation(child.link, menu.title)
                          }}
                        >
                          {child.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </SimpleBar>
    </Fragment>
  )
}

export default React.memo(NavbarVertical)

