import moment from "moment/moment";
import "moment-timezone";

export const returnDateWithTime = (date,timeZone) => {
  // impletement later dynamic timezone
  if (!date) {
    return "NA";
  }
  // Convert the date from UTC to Asia/Kolkata timezone
  const time = moment.utc(date).tz("Asia/Kolkata");
  return time.format("DD MMM YY h:mmA");
};
export const returnDateOnlyDate = (date) => {
  if (!date) {
    return "NA";
  }
  const time = moment(date);
  return time.format("DD MMM YY");
};

export const getFileNameFromUrl = (url) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export const sanitizeMessage = (msg, data) => {
  let checkPaymentLink =
    data?.oldMessage?.includes("PAYAMENT_LINK") ||
    data?.oldMessage?.includes("DOCUMENT_LINK");
  if (checkPaymentLink) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data?.oldMessage;
    const linkElement = tempDiv.firstChild;
    linkElement.textContent = msg;
    return linkElement.outerHTML;
  } else {
    return msg;
  }
};

export const truncateString = (inputString, maxStringLength = 6) => {
  if (!inputString) {
    return "";
  }
  if (inputString.length > maxStringLength + 4) {
    return inputString.slice(0, maxStringLength) + "...";
  }

  return inputString;
};

export const returnFirstDigit = (str) => {
  if (!str) {
    return "NA";
  } else {
    return str[0]?.toUpperCase();
  }
};

export const removeUnnecessaryTags = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");

  const paragraphs = Array.from(doc.querySelectorAll("p"));

  // Filter out only paragraphs that are either empty or contain only <br> at the start and end
  const filteredParagraphs = paragraphs.filter((p, index) => {
    const innerHTML = p.innerHTML.trim();
    const isEmptyOrBreak = innerHTML === "<br>" || innerHTML === "";

    // Remove <p><br></p> or empty <p></p> if it's at the start or end
    if (isEmptyOrBreak && (index === 0 || index === paragraphs.length - 1)) {
      return false;
    }

    return true;
  });

  // Reconstruct the HTML with the preserved tags
  const filteredHTML = filteredParagraphs.map((p) => p.outerHTML).join("");

  // Preserve other tags like <h1>, <h2>, <h3>, <a>, etc., in the final output
  const preservedHTML = doc.body.innerHTML
    .replace(/<p><br><\/p>/g, "")
    .replace(/<p><\/p>/g, "");

  return preservedHTML;
};

export function isMissedExpertDeadline(expertDeadline, orderStatus) {
  const expertDate = moment(expertDeadline);
  const currentDate = moment();
  return (
    currentDate.isAfter(expertDate) &&
    orderStatus !== "DELIVERED"
  );
}

export const sortByOnlineStatus = (users) => {
  return users?.sort((a, b) => {
    return (b.isOnline === true) - (a.isOnline === true);
  });
};

export const formatMessageTime = (timestamp, country, message) => {
  // Assume timestamp is saved in UTC and convert it to IST
  const now = moment().tz("Asia/Kolkata"); // Current time in IST
  const messageTime = moment.utc(timestamp).tz("Asia/Kolkata"); // Convert timestamp from UTC to IST

  const differenceInMinutes = now.diff(messageTime, "minutes"); // Calculate difference once

  if (message?.includes("testing")) {
    console.log("messageTime", messageTime, message);
  }

  if (differenceInMinutes < 1) {
    return "just now";
  } else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${
      differenceInMinutes === 1 ? "" : "s"
    } ago`;
  } else if (differenceInMinutes < 1440) {
    const differenceInHours = now.diff(messageTime, "hours");
    return `${differenceInHours} hour${differenceInHours === 1 ? "" : "s"} ago`;
  } else if (differenceInMinutes < 10080) {
    const differenceInDays = now.diff(messageTime, "days");
    return `${differenceInDays} day${differenceInDays === 1 ? "" : "s"} ago`;
  } else if (now.diff(messageTime, "years") < 1) {
    return messageTime.format("D MMM"); // Format for messages within the same year
  } else {
    return messageTime.format("D MMM YYYY"); // Format for older messages
  }
};

export const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]{9,12}$/;
  return phoneRegex.test(phone);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateName = (name) => {
  const nameRegex = /^[a-zA-Z\s]{2,50}$/;
  return nameRegex.test(name);
};

export const convertSecondsToMinutes = (seconds) => {
  if (seconds < 0) return "Invalid input";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(2); // Convert remaining seconds, including decimals
  return `${minutes} min ${remainingSeconds} sec`;
};



export function extractAllHrefsFromFileList(htmlString) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  const fileList = tempDiv.querySelector(".file-list");
  const anchors = fileList ? fileList.querySelectorAll("a") : [];
  const hrefs = Array.from(anchors).map((anchor) => anchor.href);

  return hrefs; 
}

export function getFirstElementClassNameFromHTML(htmlString) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const firstElement = tempDiv.firstElementChild;
  return firstElement ? firstElement.className : '';
}


export const getFileNameByUrl=(url)=>{
  if(url?.includes("http")){
    let arr=url.split(".");
    arr=arr[arr.length-2].split("/")
    let fileName=arr[arr.length-1];
    return fileName;
  }
  return url;
}


export const checkLogin = (navigate,tempUser) => {
  if (tempUser && tempUser?.userType === "MANAGER") {
    navigate("/manager/live-users");
  } else if (tempUser && tempUser?.userType === "OPERATOR") {
    navigate("/operator/live-users");
  } else if (tempUser && tempUser?.userType === "CLIENT") {
    navigate("/client/dashboard");
  } else if (tempUser && tempUser?.userType === "EXPERT") {
    navigate("/expert/dashboard");
  }else if (tempUser && tempUser?.userType === "SUPER_ADMIN") {
    navigate("/super-admin/live-users");
  }
  else if (tempUser && tempUser?.userType === "ADMIN") {
    navigate("/super-admin/live-users");
  }else if(tempUser && tempUser?.userType ==="MARKETING"){
    navigate("/marketings/live/leads")
  }
};


export const getCountryFlag = (countryCode) => `https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`;