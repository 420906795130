'use client'

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOpenUserChatListAction, updateLiveUserDetailAction } from "../../../services/action/common";

const UserEditForm = ({cb}) => {
    const {user}=useSelector((state)=>state.authenticationReducer);
    const {openUserChatList}=useSelector((state)=>state.commonReducer)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: ""
  });

  const dispatch=useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const currentUser=()=>{
    cb()
    dispatch(getOpenUserChatListAction({data: { userId:openUserChatList?.data?._id, isOpenUser: true }}))
  }

  useEffect(()=>{
    if(openUserChatList?.data){
        setFormData({
            name:openUserChatList?.data?.name,
            email:openUserChatList?.data?.email,
            number:openUserChatList?.data?.number
        })
    }
  },[openUserChatList])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLiveUserDetailAction({cb:currentUser,userType:user?.userType?.toLowerCase(),data:{...formData,userId:openUserChatList?.data?._id}}))
    console.log("Form submitted:", formData);
  };

  return (
    <div className="min-h-screen flex items-center justify-center from-purple-100 to-blue-100 p-4">
      <form onSubmit={handleSubmit} className="w-full max-w-lg !bg-[#00234a] rounded-lg shadow-xl overflow-hidden">
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-6 text-center text-white animate-pulse">
            Edit User Profile
          </h2>
          <div className="grid grid-cols-1 gap-6 ">
            <div className="group">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
                id="name"
                type="text"
                placeholder="John Doe"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
                id="email"
                type="email"
                placeholder="john@example.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="number">
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300 transition duration-300"
                id="number"
                type="tel"
                placeholder="123-456-7890"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="px-8 py-4 !bg-[#00234a] border-t border-gray-200 flex items-center justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 transform hover:scale-105"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserEditForm;

