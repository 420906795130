import React from 'react';
import { motion } from 'framer-motion';
import { getStatusStylesChat } from "../../constants/commonConstant";
import { formatMessageTime, getCountryFlag } from "../../utils/functions/functions";

const ChatItem = ({ chat,onClickRow ,currentIndex}) => {
    const statusStyle = getStatusStylesChat(chat.status);
    return (
      <motion.div
        onClick={()=>onClickRow && onClickRow(chat)}
        whileHover={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
        className={`p-4 border-b border-l-4 border-gray-700/50 ${statusStyle.border} cursor-pointer relative bg-[#1a2d4a]`}
      >
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-gray-400">{currentIndex+1} - {chat?.name || chat?.visitorId}</span>
            <img src={getCountryFlag(chat.country)} alt={chat.country} className="w-6 h-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-400">{formatMessageTime(chat?.updatedAt)}</span>
            {/* {
              <span className="bg-red-500 text-white text-xs font-medium rounded-full w-5 h-5 flex items-center justify-center">
                {currentIndex+1}
              </span>
            } */}
          </div>
        </div>
        <div className="space-y-1">
          <div className="font-medium text-base">{chat.visitor}</div>
          <div className="flex justify-between items-start">
            <div className="text-sm text-gray-400 truncate pr-20">{chat?.chatList?.length?chat?.chatList[chat?.chatList?.length-1]?.message:"No Message"}</div>
            <span className={`text-xs px-3 py-1.5 rounded-full font-medium inline-flex items-center justify-center ${chat?.isOnline?"text-success":"text-warning"}`}>
              {chat?.isOnline?"Online":"Offline"}
            </span>
          </div>
        </div>
      </motion.div>
    );
  };
export default ChatItem;  