import { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "simplebar/dist/simplebar.min.css";
import useMounted from "../../hooks/useMounted";
import QuickMenuDesktop from "./notification/QuickMenuDesktop";
import QuickMenuMobile from "./notification/QuickMenuMobile";
import socket from "../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationListAction,
  getOperatorOrManagerListAction,
  setNotificationPermissionAction,
  updateNotificationAction,
} from "../../services/action/common";
// import AllowNotificationConfirmation from "../utils/dialoge/AllowNotificationConfirmation";
import { getUserAction } from "../../services/action/authentication";

const QuickMenu = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticationReducer);
  const audioRef = useRef();
  const newLeadRef=useRef();
  const [audioAllowed, setAudioAllowed] = useState(false); // Track if audio is allowed
  const [showPermissionPrompt, setShowPermissionPrompt] = useState(true); // Show permission prompt
  const { isShowNotificationPopup } = useSelector(
    (state) => state.commonReducer
  );

  // Only register the notification action when the user is present
  useEffect(() => {
    if (user?.userType !== "MARKETING") {
      let userType = user?.userType?.toLowerCase();
      dispatch(getNotificationListAction({ userType }));
    }
  }, [user, dispatch]);

  const hasMounted = useMounted();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const enableAudioPlayback = () => {
    if (audioRef.current) {
      dispatch(setNotificationPermissionAction({ isShown: true }));
      setAudioAllowed(true);
      setShowPermissionPrompt(false);
    }
  };

  // Socket connection handling and audio playback
  useEffect(() => {
    if (!audioAllowed) return; // Only add listener if audio is allowed

    const handleNotification = ({
      orderId,
      notificationId,
      subject,
      messageId,
      senderId,
      sendTime,
      leadId,
      senderName,
      message,
      messageType,
      userId,
      visitorId,
      messageNewMessageCount,
    }) => {
      if (messageType === "EXTERNAL") {
        let userType = user?.userType?.toLowerCase();
        dispatch(getNotificationListAction({ userType }));
      } else {
        dispatch(
          updateNotificationAction({
            orderId,
            _id: notificationId,
            subject,
            visitorId,
            messageId,
            senderId,
            sendTime,
            leadId,
            senderName,
            message,
            messageType,
            userId,
            messageNewMessageCount,
          })
        );
      }

      // Play audio when notification is received
      if (audioRef.current) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log("Playback prevented:", error);
          });
        }
      }
    };

    // Register socket event only once
    socket.on("send_notification", handleNotification);

    return () => {
      socket.off("send_notification", handleNotification); // Cleanup listener on unmount
    };
  }, [dispatch, audioAllowed]);

  // Fetch operator or manager list based on user type
  useEffect(() => {
    if (user?.userType === "MANAGER" || user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN" ) {
      dispatch(
        getOperatorOrManagerListAction({
          userType: user?.userType?.toLowerCase(),
        })
      );
    }
  }, [user]);

  useEffect(() => {
    socket.on("new_lead_ads", (_) => {
      dispatch(getUserAction())
      if (newLeadRef.current) {
        const playPromise = newLeadRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log("Playback prevented:", error);
          });
        }
      }
    });

    return () => {
      socket.off("new_lead_ads");
    };
  }, [dispatch]);

  return (
    <Fragment>
      <audio ref={audioRef} src="/assets/ringtone.wav" />
      <audio ref={newLeadRef} src="/assets/ads-leads.wav" />
      {/* {user?.userType !== "MARKETING" && (
        <AllowNotificationConfirmation
          handleClose={() => setShowPermissionPrompt(false)}
          onAllow={enableAudioPlayback}
          open={showPermissionPrompt && !isShowNotificationPopup}
        />
      )} */}

      {hasMounted && isDesktop ? <QuickMenuDesktop /> : <QuickMenuMobile />}
    </Fragment>
  );
};

export default QuickMenu;
