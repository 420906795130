import { useState, useEffect } from "react";
import { Star, CheckCircle2 } from "lucide-react";
import ExpertProfileModal from "./ExpertProfileModal";
const experts = [
  {
    id: 1,
    name: "Tutor_Lisa",
    image:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=500&h=500",
    ordersFinished: 492,
    ordersInProgress: 23,
    bio: "As an academic writer, I have spent many years honing my skills to meet my clients' expectations. Throughout my academic career, I have devoted myself to understanding various subjects deeply and helping students achieve their academic goals. My approach combines thorough research, clear writing, and attention to detail.",
    rating: 4.86,
    reviews: 262,
    yearsActive: 1.1,
    satisfiedStudents: 357,
    lastCompleted: "Jan 20",
    skills: [
      "African-American Studies",
      "Agricultural Studies",
      "Alternative Medicine",
      "Psychology",
      "Research Methods",
    ],
    education: {
      major: "Psychology",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 127, score: 4.91 },
      grammarAndStyle: { count: 109, score: 4.95 },
      responseSpeed: { count: 105, score: 4.99 },
      formatting: { count: 103, score: 4.96 },
      other: { count: 15, score: 4.47 },
    },
  },
  {
    id: 2,
    name: "Prof_Sarah",
    image:
      "https://images.unsplash.com/photo-1607746882042-944635dfe10e?w=500&h=500",
    ordersFinished: 867,
    ordersInProgress: 14,
    bio: "With over 15 years of experience in academia, I specialize in helping students master complex subjects. My background in research methodology and statistics allows me to provide comprehensive support for dissertations and research papers. I believe in fostering critical thinking while maintaining academic integrity.",
    rating: 4.92,
    reviews: 433,
    yearsActive: 2.3,
    satisfiedStudents: 589,
    lastCompleted: "Jan 21",
    skills: [
      "Research Methodology",
      "Statistics",
      "Sociology",
      "Data Analysis",
      "Academic Writing",
    ],
    education: {
      major: "Research Methodology",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 198, score: 4.94 },
      grammarAndStyle: { count: 187, score: 4.89 },
      responseSpeed: { count: 167, score: 4.95 },
      formatting: { count: 156, score: 4.92 },
      other: { count: 23, score: 4.88 },
    },
  },
  {
    id: 3,
    name: "Dr_Michael",
    image:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=500&h=500",
    ordersFinished: 1243,
    ordersInProgress: 18,
    bio: "As a PhD holder in Computer Science, I bring technical expertise and academic rigor to every project. My experience spans programming, algorithm analysis, and technical writing. I enjoy breaking down complex concepts into understandable components for students.",
    rating: 4.89,
    reviews: 678,
    yearsActive: 3.5,
    satisfiedStudents: 892,
    lastCompleted: "Jan 19",
    skills: [
      "Computer Science",
      "Programming",
      "Algorithm Analysis",
      "Database Systems",
      "Machine Learning",
    ],
    education: {
      major: "Computer Science",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 289, score: 4.88 },
      grammarAndStyle: { count: 267, score: 4.91 },
      responseSpeed: { count: 245, score: 4.87 },
      formatting: { count: 234, score: 4.9 },
      other: { count: 34, score: 4.89 },
    },
  },
  {
    id: 4,
    name: "Emma_Research",
    image:
      "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=500&h=500",
    ordersFinished: 756,
    ordersInProgress: 12,
    bio: "Specializing in medical and health sciences, I help students navigate complex research topics. My background in nursing and public health enables me to provide expert guidance in healthcare-related assignments. I emphasize evidence-based approaches and current research practices.",
    rating: 4.95,
    reviews: 389,
    yearsActive: 1.8,
    satisfiedStudents: 445,
    lastCompleted: "Jan 21",
    skills: [
      "Medical Science",
      "Public Health",
      "Nursing",
      "Healthcare Management",
      "Clinical Research",
    ],
    education: {
      major: "Public Health",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 167, score: 4.96 },
      grammarAndStyle: { count: 156, score: 4.94 },
      responseSpeed: { count: 145, score: 4.97 },
      formatting: { count: 134, score: 4.93 },
      other: { count: 28, score: 4.95 },
    },
  },
  {
    id: 5,
    name: "Prof_David",
    image:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=500&h=500",
    ordersFinished: 934,
    ordersInProgress: 15,
    bio: "With expertise in business administration and economics, I help students understand market dynamics and organizational behavior. My practical experience in consulting adds real-world context to academic concepts. I specialize in case studies and strategic analysis.",
    rating: 4.88,
    reviews: 467,
    yearsActive: 2.7,
    satisfiedStudents: 623,
    lastCompleted: "Jan 20",
    skills: [
      "Business Administration",
      "Economics",
      "Strategic Management",
      "Marketing",
      "Financial Analysis",
    ],
    education: {
      major: "Business Administration",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 198, score: 4.87 },
      grammarAndStyle: { count: 187, score: 4.89 },
      responseSpeed: { count: 167, score: 4.86 },
      formatting: { count: 156, score: 4.9 },
      other: { count: 29, score: 4.88 },
    },
  },
  {
    id: 6,
    name: "Anna_Writer",
    image:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=500&h=500",
    ordersFinished: 645,
    ordersInProgress: 9,
    bio: "As a literature and creative writing specialist, I help students develop their analytical and writing skills. My background in comparative literature enables me to approach texts from various cultural perspectives. I excel in literary analysis and academic writing.",
    rating: 4.91,
    reviews: 312,
    yearsActive: 1.5,
    satisfiedStudents: 378,
    lastCompleted: "Jan 21",
    skills: [
      "Literature",
      "Creative Writing",
      "Literary Analysis",
      "Comparative Literature",
      "Academic Writing",
    ],
    education: {
      major: "Comparative Literature",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 145, score: 4.92 },
      grammarAndStyle: { count: 134, score: 4.93 },
      responseSpeed: { count: 123, score: 4.89 },
      formatting: { count: 112, score: 4.91 },
      other: { count: 19, score: 4.9 },
    },
  },
  {
    id: 7,
    name: "Dr_Robert",
    image:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=500&h=500",
    ordersFinished: 1089,
    ordersInProgress: 16,
    bio: "Specializing in physics and engineering, I help students tackle complex technical problems. My research background in quantum mechanics and experience in engineering projects allows me to provide comprehensive support in STEM subjects. I focus on problem-solving and analytical thinking.",
    rating: 4.87,
    reviews: 534,
    yearsActive: 2.9,
    satisfiedStudents: 712,
    lastCompleted: "Jan 20",
    skills: [
      "Physics",
      "Engineering",
      "Quantum Mechanics",
      "Mathematics",
      "Technical Writing",
    ],
    education: {
      major: "Physics",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 234, score: 4.86 },
      grammarAndStyle: { count: 223, score: 4.88 },
      responseSpeed: { count: 212, score: 4.85 },
      formatting: { count: 201, score: 4.89 },
      other: { count: 31, score: 4.87 },
    },
  },
  {
    id: 8,
    name: "Maria_Tutor",
    image:
      "https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=500&h=500",
    ordersFinished: 823,
    ordersInProgress: 11,
    bio: "With expertise in social sciences and psychology, I help students understand human behavior and social phenomena. My research experience in cognitive psychology enables me to provide insights into both theoretical frameworks and practical applications. I emphasize critical thinking and methodological rigor.",
    rating: 4.93,
    reviews: 412,
    yearsActive: 2.1,
    satisfiedStudents: 567,
    lastCompleted: "Jan 21",
    skills: [
      "Psychology",
      "Social Sciences",
      "Research Methods",
      "Cognitive Psychology",
      "Statistical Analysis",
    ],
    education: {
      major: "Psychology",
      isVerified: true,
    },
    ratingBreakdown: {
      followingInstructions: { count: 178, score: 4.94 },
      grammarAndStyle: { count: 167, score: 4.92 },
      responseSpeed: { count: 156, score: 4.95 },
      formatting: { count: 145, score: 4.91 },
      other: { count: 25, score: 4.93 },
    },
  },
];

export default function ExpertListOpen() {
  const [visibleExperts, setVisibleExperts] = useState(6);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const totalExperts = experts.length;

  const loadMore = () => {
    setVisibleExperts((prev) => Math.min(prev + 6, totalExperts));
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
    document.body.style.backgroundColor = "#f9fafb";
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6  sm:py-18 mt-14 sm:mt-230 py-18">
        <div className="text-center space-y-6 mb-8 !sm:mt-30">
          <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 tracking-tight">
            Meet Our Academic Experts
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            Global Assignment Help connects you with premium quality academic
            experts, ensuring your assignments meet the highest standards.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12 max-w-6xl mx-auto">
          {experts.slice(0, visibleExperts).map((expert) => (
            <div
              key={expert.id}
              className="bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col group"
            >
              <div className="p-6 flex-grow space-y-4">
                <div className="flex items-start space-x-4">
                  <img
                    src={expert.image || "/placeholder.svg"}
                    alt={expert.name}
                    className="w-14 h-14 rounded-full object-cover ring-2 ring-gray-100"
                  />
                  <div className="min-w-0 flex-1">
                    <h3 className="text-lg font-bold text-gray-900 flex items-center gap-2 mb-1">
                      {expert.name}
                      <CheckCircle2 className="w-4 h-4 text-green-500 flex-shrink-0" />
                    </h3>
                    <p className="text-sm text-gray-600 flex items-center gap-2">
                      <span>{expert.ordersFinished} Orders</span>
                      <span className="w-1 h-1 rounded-full bg-gray-300" />
                      <span>{expert.ordersInProgress} In Progress</span>
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className={`w-4 h-4 ${
                        i < Math.floor(expert.rating)
                          ? "text-yellow-400 fill-current"
                          : "text-gray-200"
                      }`}
                    />
                  ))}
                  <span className="ml-2 text-sm font-medium text-gray-700">
                    {expert.rating.toFixed(2)}
                  </span>
                </div>

                <p className="text-sm text-gray-600 line-clamp-3 leading-relaxed">
                  {expert.bio}
                </p>

                <div className="flex flex-wrap gap-2">
                  {expert.skills.slice(0, 3).map((skill, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700 group-hover:bg-blue-100 transition-colors duration-200"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>

              <div className="p-4 bg-gray-50 border-t border-gray-100 grid grid-cols-2 gap-3">
                <button className="inline-flex justify-center items-center px-4 py-2.5 rounded-full text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200">
                  Hire Now
                </button>
                <button
                  onClick={() => setSelectedExpert(expert)}
                  className="inline-flex justify-center items-center px-4 py-2.5 rounded-full text-sm font-semibold text-blue-600 bg-white border border-blue-200 hover:bg-blue-50 hover:border-blue-300 transition-colors duration-200"
                >
                  View Profile
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center space-y-6">
          <p className="text-lg text-gray-600">
            Showing{" "}
            <span className="font-semibold">
              {Math.min(visibleExperts, totalExperts)}
            </span>{" "}
            of <span className="font-semibold">{totalExperts}</span> experts
          </p>
          {visibleExperts < totalExperts && (
            <button
              onClick={loadMore}
              className="inline-flex justify-center items-center px-8 py-3 rounded-full text-lg font-semibold text-blue-600 bg-white border-2 border-blue-200 hover:bg-blue-50 hover:border-blue-300 transition-colors duration-200"
            >
              Load More Experts
            </button>
          )}
        </div>
      </div>

      {selectedExpert && (
        <ExpertProfileModal
          expert={selectedExpert}
          isOpen={!!selectedExpert}
          onClose={() => setSelectedExpert(null)}
        />
      )}
    </div>
  );
}
