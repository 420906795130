import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import {
  ClipboardList,
  Wallet,
  DollarSign,
  Home,
  MessageCircle,
  Share2,
  Plus,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardAction } from "../../services/action/common";
import { returnDateWithTime } from "../../components/utils/functions/functions";
import CustomModal from "../../components/utils/modals/CustomModal";
import FormEditLeadGenerate from "../../components/manager/leads/components/FormEditLeadGenerate";

const ORDER_STATUS = {
  OPEN: "bg-yellow-500/20 text-yellow-400",
  ASSIGNED: "bg-blue-500/20 text-blue-400",
  UNDERQC: "bg-purple-500/20 text-purple-400",
  DELIVERED: "bg-green-500/20 text-green-400",
  REOPEN: "bg-red-500/20 text-red-400",
  ONHOLD: "bg-orange-500/20 text-orange-400",
  REFUND: "bg-pink-500/20 text-pink-400",
  MISSED_ORDER: "bg-red-500/20 text-red-400",
  CLIENTDEADLINE: "bg-red-500/20 text-red-400",
  DONE: "bg-green-500/20 text-green-400",
};

const ClientDashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentAdSlide, setCurrentAdSlide] = useState(0);
  const [currentServiceSlide, setCurrentServiceSlide] = useState(0);

  useEffect(() => {
    dispatch(getDashboardAction({ userType: "client" }));
  }, []);

  const bannerSlides = [
    {
      title: "Welcome to Clickinpedia",
      subtitle: "12+ Years of Academic Excellence",
      image:
        "https://ik.imagekit.io/f8k0o3wfp/1_7sv1xm4fMX.png?height=200&width=400",
    },
    {
      title: "Special Offer",
      subtitle: "Get 50% off on your first order",
      image:
        "https://ik.imagekit.io/f8k0o3wfp/1_7sv1xm4fMX.png?height=200&width=400",
    },
    {
      title: "24/7 Support",
      subtitle: "Expert assistance always available",
      image:
        "https://ik.imagekit.io/f8k0o3wfp/1_7sv1xm4fMX.png?height=200&width=400",
    },
  ];

  const adSlides = [
    {
      title: "Premium Writing Services",
      description: "Professional academic writing assistance",
    },
    {
      title: "Research Excellence",
      description: "Expert research and analysis",
    },
    {
      title: "24/7 Support",
      description: "Round-the-clock assistance",
    },
  ];

  const stats = [
    {
      icon: <ClipboardList className="w-5 h-5 text-white" />,
      title: "Orders",
      value: dashboardData?.totalOrders || "0",
    },
    {
      icon: <Wallet className="w-5 h-5 text-white" />,
      title: "Wallet",
      value: `$${user?.wallet?.amount || 0}`,
    },
    {
      icon: <DollarSign className="w-5 h-5 text-white" />,
      title: "Balance",
      value: dashboardData?.balancePayment || "$0",
    },
  ];

  const services = [
    {
      title: "Assignment Help",
      icon: "📚",
      description: "Expert academic assistance",
    },
    {
      title: "Research Assistance",
      icon: "🔍",
      description: "In-depth research support",
    },
    {
      title: "Essay Writing",
      icon: "✍️",
      description: "Professional essay services",
    },
    {
      title: "Exam Prep",
      icon: "📝",
      description: "Comprehensive exam preparation",
    },
    {
      title: "24/7 Support",
      icon: "💬",
      description: "Round-the-clock assistance",
    },
    {
      title: "Dissertation Help",
      icon: "📖",
      description: "Expert dissertation guidance",
    },
  ];

  const nextSlide = (setter, length) => {
    setter((prev) => (prev + 1) % length);
  };

  const prevSlide = (setter, length) => {
    setter((prev) => (prev - 1 + length) % length);
  };

  useEffect(() => {
    const bannerTimer = setInterval(
      () => nextSlide(setCurrentSlide, bannerSlides.length),
      5000
    );
    const adTimer = setInterval(
      () => nextSlide(setCurrentAdSlide, adSlides.length),
      4000
    );
    const serviceTimer = setInterval(
      () => nextSlide(setCurrentServiceSlide, Math.ceil(services.length / 3)),
      6000
    );
    return () => {
      clearInterval(bannerTimer);
      clearInterval(adTimer);
      clearInterval(serviceTimer);
    };
  }, []);

  const [newOrderModal, setNewOrderModal] = useState(false);
  const afterSave = () => {
    dispatch(getDashboardAction({ userType: "client" }));
    setNewOrderModal(false);
  };

  return (
    <DashboardLayout>
      <CustomModal
        title={"Add New Orders"}
        bgContent="dark-bg"
        open={newOrderModal}
        onClose={() => setNewOrderModal(false)}
        content={() => <FormEditLeadGenerate close={afterSave} />}
      />
      <div className="min-h-screen bg-[#001B3D] pb-20">
        <Container fluid className="px-4 py-3">
          {/* Banner Slider */}
          <div className="relative mb-4 overflow-hidden rounded-xl bg-[#002952]">
            <div className="relative h-40">
              <AnimatePresence initial={false}>
                <motion.div
                  key={currentSlide}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }}
                  className="absolute inset-0 flex items-center"
                >
                  <div className="relative w-full h-full">
                    <img
                      src={bannerSlides[currentSlide].image}
                      alt={bannerSlides[currentSlide].title}
                      className="absolute inset-0 w-full h-full object-cover opacity-20"
                    />
                    <div className="absolute inset-0 p-6 flex flex-col justify-center">
                      <h2 className="text-2xl font-bold text-white mb-2">
                        {bannerSlides[currentSlide].title}
                      </h2>
                      <p className="text-sm text-white/80">
                        {bannerSlides[currentSlide].subtitle}
                      </p>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
              <button
                onClick={() => prevSlide(setCurrentSlide, bannerSlides.length)}
                className="absolute left-2 top-1/2 -translate-y-1/2 p-1.5 bg-black/20 rounded-full text-white hover:bg-black/40 transition-colors"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button
                onClick={() => nextSlide(setCurrentSlide, bannerSlides.length)}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-black/20 rounded-full text-white hover:bg-black/40 transition-colors"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
              <div className="absolute bottom-3 left-1/2 -translate-x-1/2 flex gap-1">
                {bannerSlides.map((_, index) => (
                  <div
                    key={index}
                    className={`w-1.5 h-1.5 rounded-full ${
                      currentSlide === index ? "bg-white" : "bg-white/50"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Advertisement Slider */}
          <div className="mb-4 overflow-hidden rounded-xl bg-[#002952]">
            <div className="relative h-32">
              <AnimatePresence initial={false}>
                <motion.div
                  key={currentAdSlide}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.3 }}
                  className="absolute inset-0 flex items-center"
                >
                  <div className="relative w-full h-full p-4 flex flex-col justify-center bg-gradient-to-r from-blue-500/20 to-purple-500/20">
                    <h3 className="text-lg font-bold text-white mb-1">
                      {adSlides[currentAdSlide].title}
                    </h3>
                    <p className="text-xs text-white/80">
                      {adSlides[currentAdSlide].description}
                    </p>
                  </div>
                </motion.div>
              </AnimatePresence>
              <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
                {adSlides.map((_, index) => (
                  <div
                    key={index}
                    className={`w-1.5 h-1.5 rounded-full ${
                      currentAdSlide === index ? "bg-white" : "bg-white/50"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="grid grid-cols-3 gap-2 mb-4">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileTap={{ scale: 0.95 }}
                className="rounded-xl p-3 bg-[#002952] relative overflow-hidden group hover:bg-[#003366] transition-colors"
              >
                <motion.div
                  className="absolute inset-0 bg-white opacity-0"
                  whileTap={{ opacity: 0.1, scale: 2 }}
                  transition={{ duration: 0.5 }}
                />
                <div className="relative z-10">
                  <div className="flex flex-col items-center">
                    <div className="mb-1 p-2 bg-blue-500/20 rounded-lg">
                      {stat.icon}
                    </div>
                    <p className="text-lg font-bold text-white mb-0.5">
                      {stat.value}
                    </p>
                    <p className="text-[10px] text-white/80">{stat.title}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Quick Actions */}
          <div className="mb-4" onClick={() => setNewOrderModal(true)}>
            <motion.button
              whileTap={{ scale: 0.98 }}
              className="w-full bg-[#002952] text-white rounded-xl p-3 flex items-center justify-between hover:bg-[#003366] transition-colors"
            >
              <div className="flex items-center gap-2">
                <Plus className="w-4 h-4" />
                <span className="text-sm font-medium">Create New Order</span>
              </div>
              <span className="text-xs bg-blue-500/30 px-2 py-0.5 rounded">
                50% OFF
              </span>
            </motion.button>
          </div>

          {/* Recent Activity */}
          <div className="bg-[#002952] rounded-xl p-3">
            <h2 className="text-base font-bold text-white mb-3">
              Recent Activity
            </h2>
            <div className="space-y-2">
              {dashboardData?.leadList?.map(
                (activity, i) =>
                  i < 5 && (
                    <motion.div
                      key={activity.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: i * 0.1 }}
                      className="flex items-center gap-2 p-2 bg-[#001B3D] rounded-lg hover:bg-[#002952] transition-colors"
                    >
                      <div className="w-8 h-8 bg-blue-500/20 rounded-lg flex items-center justify-center">
                        <ClipboardList className="w-4 h-4 text-blue-400" />
                      </div>
                      <div className="flex-1">
                        <h3 className="text-xs font-medium text-white">
                          {activity?.subject} #(
                          {activity?.orderId || activity?.leadId || "NA"})
                        </h3>
                        <div className="flex items-center gap-2">
                          <p className="text-[10px] text-blue-400">
                            DL : {returnDateWithTime(activity.clientDeadline)}
                          </p>
                          <span
                            className={`text-[10px] px-1.5 py-0.5 rounded-full ${
                              ORDER_STATUS[activity.orderStatus]
                            }`}
                          >
                            {activity?.orderStatus}
                          </span>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="text-xs font-medium text-white">
                          ${activity.totalPayment}
                        </p>
                        {activity.paymentRecieved < activity.totalPayment && (
                          <button className="text-[10px] bg-blue-500 hover:bg-blue-600 text-white px-2 py-0.5 rounded-full transition-colors">
                            Pay $
                            {(
                              activity.totalPayment - activity.paymentRecieved
                            ).toFixed(2)}
                          </button>
                        )}
                        {activity.paymentRecieved > 0 &&
                          activity.paymentRecieved < activity.totalPayment && (
                            <p className="text-[10px] text-green-400">
                              ${activity.paymentRecieved} paid
                            </p>
                          )}
                        {activity.paymentRecieved === activity.totalPayment && (
                          <p className="text-[10px] text-green-400">
                            Payment Complete
                          </p>
                        )}
                      </div>
                    </motion.div>
                  )
              )}
            </div>
          </div>

          {/* Services */}
          <div className="mb-4 mt-3">
            <h2 className="text-base font-bold text-white mb-3">
              Our Services
            </h2>
            <div className="relative overflow-hidden">
              <div className="relative h-48">
                <AnimatePresence initial={false}>
                  <motion.div
                    key={currentServiceSlide}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.3 }}
                    className="absolute inset-0 grid grid-cols-3 gap-2"
                  >
                    {services
                      .slice(
                        currentServiceSlide * 3,
                        (currentServiceSlide + 1) * 3
                      )
                      .map((service, index) => (
                        <motion.div
                          key={service.title}
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          onClick={() => setNewOrderModal(true)}
                          transition={{ delay: index * 0.1 }}
                          className="relative bg-[#002952] rounded-xl overflow-hidden hover:bg-[#003366] transition-colors group"
                        >
                          <div className="relative p-3 flex flex-col items-center">
                            <div className="text-2xl mb-1 transform group-hover:scale-110 transition-transform">
                              {service.icon}
                            </div>
                            <span className="text-xs font-medium text-white text-center mb-1">
                              {service.title}
                            </span>
                            <p className="text-[10px] text-white/70 text-center">
                              {service.description}
                            </p>
                          </div>
                        </motion.div>
                      ))}
                  </motion.div>
                </AnimatePresence>
              </div>
              <button
                onClick={() =>
                  prevSlide(
                    setCurrentServiceSlide,
                    Math.ceil(services.length / 3)
                  )
                }
                className="absolute left-2 top-1/2 -translate-y-1/2 p-1.5 bg-black/20 rounded-full text-white hover:bg-black/40 transition-colors"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button
                onClick={() =>
                  nextSlide(
                    setCurrentServiceSlide,
                    Math.ceil(services.length / 3)
                  )
                }
                className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-black/20 rounded-full text-white hover:bg-black/40 transition-colors"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
              <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
                {Array.from({ length: Math.ceil(services.length / 3) }).map(
                  (_, index) => (
                    <div
                      key={index}
                      className={`w-1.5 h-1.5 rounded-full ${
                        currentServiceSlide === index
                          ? "bg-white"
                          : "bg-white/50"
                      }`}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </Container>

        {/* Mobile Navigation */}

        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          className="fixed bottom-0 left-0 right-0 bg-[#002952] border-t border-blue-900/50 md:hidden"
        >
          <div className="flex justify-around items-center p-3">
            {[
              {
                icon: <Home className="w-5 h-5" />,
                label: "Home",
                href: "/dashboard",
              },
              {
                icon: <ClipboardList className="w-5 h-5" />,
                label: "Orders",
                href: "/client/leads",
              },
              {
                icon: <MessageCircle className="w-5 h-5" />,
                label: "Chat",
                href: "/client/leads",
                badge: "14",
              },
              {
                icon: <Share2 className="w-5 h-5" />,
                label: "Share",
                href: "/client/share",
              },
            ].map((item, _) => (
              <Link
                key={item.label}
                to={item.href}
                className="relative flex flex-col items-center"
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="text-white"
                >
                  {item.icon}
                  {item.badge && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-[10px] w-3.5 h-3.5 flex items-center justify-center rounded-full">
                      {item.badge}
                    </span>
                  )}
                </motion.div>
                <span className="text-[10px] text-white/80 mt-0.5">
                  {item.label}
                </span>
              </Link>
            ))}
          </div>
        </motion.div>
      </div>
    </DashboardLayout>
  );
};

export default ClientDashboard;
