import React, { useEffect, useRef } from "react";

const GoogleSignupButton = ({ className, externalBtnRef, onSignupSuccess }) => {
  let signupBtnRef = useRef(null);
  signupBtnRef = externalBtnRef || signupBtnRef;

  const initializeGoogleSignup = () => {
    if (window.google && window.google.accounts) {
      try {
        window.google.accounts.id.initialize({
          client_id:
            "584683567611-5tt0pqs208l539qvvbqol18vudmjl9qe.apps.googleusercontent.com",
          callback: onSignupSuccess, // Handle signup success callback
        });

        window.google.accounts.id.renderButton(signupBtnRef.current, {
          theme: "outline",
          size: "large",
          text: "signup_with", // Customize the button text for "Sign Up"
          width: "100%", // Set width to 100% for responsiveness
        });
      } catch (error) {
        console.error("Error initializing Google signup:", error);
      }
    } else {
      console.error("Google SDK is not available.");
    }
  };

  useEffect(() => {
    if (!window.google || !window.google.accounts) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignup;
      script.onerror = () =>
        console.error("Failed to load the Google Identity Services SDK.");
      document.body.appendChild(script);
    } else {
      initializeGoogleSignup();
    }
  }, []);

  return (
    <div className={className} ref={signupBtnRef} id="googleSignupButton" />
  );
};

export default GoogleSignupButton;
