import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserAction } from "../services/action/authentication";
import { checkLogin } from "../components/utils/functions/functions";
import { useDispatch } from "react-redux";

const CheckUserLoggedOrNot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const location = window.location;
    const queryParams = new URLSearchParams(location.search);
    const temptoken = queryParams.get("token");
    let token = decodeURIComponent(temptoken);

    token=token?.replaceAll('"',"")
    if (token) {
      localStorage.setItem("token", token);
      setLoading(true);
      dispatch(
        getUserAction({
          cb: (res) => {
            setLoading(false);
            checkLogin(navigate, res?.user);
          },
          errorCb: () => {
            setLoading(false);
            navigate("/login");
          },
        })
      );
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
        {/* Loading spinner container */}
        <div className="relative">
          {/* Outer spinning ring */}
          <div className="w-20 h-20 border-4 border-white/30 rounded-full animate-spin">
            {/* Inner gradient arc */}
            <div className="absolute top-0 left-0 w-20 h-20 border-t-4 border-l-4 border-white rounded-full animate-pulse"></div>
          </div>
          {/* Center dot */}
          <div className="absolute top-1/2 left-1/2 w-3 h-3 bg-white rounded-full -translate-x-1/2 -translate-y-1/2 animate-pulse"></div>
        </div>
        
        {/* Loading text */}
        <div className="mt-8 text-center">
          <h2 className="text-2xl font-semibold text-white mb-2 animate-pulse">
            Loading...
          </h2>
          <p className="text-white/80 text-sm">
            Please wait while we verify your credentials
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default CheckUserLoggedOrNot;

