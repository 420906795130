import React, { useState } from 'react'
import { motion } from "framer-motion";
import {
  ChevronDown,
  Copy,
  Edit2,
  Activity,
} from "lucide-react";
import { getCountryFlag, truncateString } from '../../utils/functions/functions'
import { Tooltip } from '@mui/material';
import FullScreenModal from '../../utils/modals/FullScreenModal';
import UserEditForm from '../components/UserEditForm';
import { CloseButton } from 'react-bootstrap';

const DeskotopViewRightSidebar = ({getUpdateUserLive,setopenLiveChat,visitorInfo}) => {
  const [edit,setEdit]=useState(false)
  return (
          <div className="hidden md:flex w-80 border-l border-gray-700/50 flex-col">
            <FullScreenModal
             open={edit}
             content={()=><UserEditForm cb={()=>{
             
              setEdit(false)
              getUpdateUserLive &&  getUpdateUserLive();
            }
            
            }/>}
             onClose={()=>setEdit(false)}
            />
          <div className="p-4 border-b border-gray-700/50">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 bg-gray-700/50 rounded-full flex items-center justify-center">
                  <span className="text-xl font-semibold">T</span>
                </div>
                <div>
                  <div className="font-medium text-lg flex items-center space-x-2">
                    <span>{visitorInfo?.name || "NA"}</span>
                    <img
                      src={getCountryFlag(visitorInfo?.country || "IN")}
                      alt="India"
                      className="w-6 h-4"
                      
                    />
                    <Edit2 onClick={()=>{
                        setEdit(true)
                      }} size={14} className="text-gray-400 cursor-pointer" />
                  </div>
                  <div className="text-sm text-gray-400">
                    {visitorInfo?.contryName || "NA"}
                  </div>
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-2 hover:bg-gray-700/50 rounded-lg"
              >
                <Copy size={16} />
              </motion.button>
            </div>
          </div>
  
          <div className="p-4 space-y-4 overflow-y-auto flex-1">
            <div className="space-y-2">
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-400">Email</span>
                <span>{visitorInfo?.email || "NA"}</span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-400">Phone</span>
                <span>{visitorInfo?.number || "NA"}</span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-400">Type</span>
                <span>{visitorInfo?.type}</span>
              </div>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-400">IP Address</span>
                <Tooltip title={visitorInfo?.clientIp}>
                <span>{truncateString(visitorInfo?.clientIp,17) || "NA"}</span>
                </Tooltip>
              </div>
            </div>
  
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-700/50 p-4 rounded-lg">
                <div className="text-2xl font-bold">{visitorInfo?.leadScore || "0%"}</div>
                <div className="text-sm text-gray-400">Lead Score</div>
              </div>
              <div className="bg-gray-700/50 p-4 rounded-lg">
                <div className="text-2xl font-bold">
                  {visitorInfo?.conversionRate || "0%"}
                </div>
                <div className="text-sm text-gray-400">Opportunity</div>
              </div>
            </div>
  
            <div className="space-y-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
              >
                <div className="flex items-center space-x-2">
                  <Activity size={16} className="text-blue-400" />
                  <span>Activity</span>
                </div>
                <ChevronDown size={16} />
              </motion.button>
  
              <motion.button
                whileHover={{ scale: 1.02 }}
                className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
              >
                <div className="flex items-center space-x-2">
                  <span className="w-4 h-4 bg-green-500 rounded" />
                  <span>Clickinpedia Desk</span>
                </div>
                <ChevronDown size={16} />
              </motion.button>
  
              <motion.button
                whileHover={{ scale: 1.02 }}
                className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
              >
                <div className="flex items-center space-x-2">
                  <span className="w-4 h-4 bg-blue-500 rounded" />
                  <span>Clickinpedia CRM</span>
                </div>
                <ChevronDown size={16} />
              </motion.button>
             {setopenLiveChat && <motion.button
                onClick={()=>setopenLiveChat(false)}
                whileHover={{ scale: 1.02 }}
                className="w-full flex items-center justify-between p-3 bg-gray-700/50 rounded-lg"
              >
                <div className="flex items-center space-x-2">
                  <span className="w-4 h-4 bg-red-500 rounded" />
                  <span>Close Chat</span>
                </div>
                <CloseButton size={16}  style={{backgroundColor:"red",color:"white"}}/>
              </motion.button>}
            </div>
          </div>
        </div>
  )
}

export default DeskotopViewRightSidebar