import React from 'react';
import { motion } from 'framer-motion';

const FilterButton = ({  icon: Icon, label, count, activeFilter, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={`relative flex items-center w-full px-3 py-2 rounded-xl transition-all duration-200 ${
      activeFilter ? 'bg-[#0051FF] text-white' : 'bg-[#1a2d4a] text-gray-300 hover:bg-[#1e3359]'
    }`}
  >
    <div className="flex items-center space-x-2">
      <Icon size={16} /> {/* Reduced icon size */}
      <span className="text-sm font-normal">{label}</span> {/* Reduced font size */}
    </div>
    <div
      className={`ml-auto px-1.5 py-0.5 rounded-full text-xs font-normal min-w-[1.5rem] text-center ${
        activeFilter ? 'bg-[#1a90ff]/20' : 'bg-[#1e3359]'
      }`}
    >
      {count}
    </div>
  </motion.button>
);

export default FilterButton;
