import React, { useState, useEffect, useRef } from "react";
import CustomDataTable from "../../components/utils/data-tables/CustomDataTable";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { LiveLeadColumn } from "./LiveLeadColumn";
import socket from "../../components/socket/socket";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiveLeadsAction, getLeadActivityAction } from "../../services/action/common";
import UserDetailsModal from "./component/UserDetailsModal";
import ProfileViewsModal from "./component/ProfileViewsModal";
import CustomModal from "../../components/utils/modals/CustomModal";
import LeadDetails from "./component/LeadStatusUpdate";
import { getUserAction } from "../../services/action/authentication";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderHistoryActivity from "../../components/modal/OrderHistoryActivity";
import FullScreenModal from "../../components/utils/modals/FullScreenModal";
import SelectBox from "../../components/utils/select/SelectBox";
import {  subDays, startOfDay, endOfDay } from "date-fns";

const initialData = [
  {
    formId: "265045193327873",
    formName: "Assignment Samples Leads",
    userName: "Satendra",
    userPhone: "+918510001300",
    userEmail: "help@assignmentwriter.io",
    userAddress: "Subject",
    leadTime: "2024-11-27T06:16:26+0000",
    leadComingFrom: "Facebook",
  },
];


export default function LiveLeads() {
  const { liveLeads } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [leads, setLeads] = useState(initialData);
  const [pageData, setPageData] = useState({ page: 1 });
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSource, setFilterSource] = useState({ value: "", label: "All Sources" });
  const [selectedUser, setSelectedUser] = useState(null);
  const [showProfileViews, setShowProfileViews] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [filter, setFilter] = useState({
    status: [{ value: "NEW", label: "New" }],
    leadComingFrom: "",
    startDate: startOfDay(new Date()), // Set to start of today
    endDate: endOfDay(new Date()),     // Set to end of today
  });
  const [orderHistory, setOrderHistory] = useState("");
  const [selectedDatePreset, setSelectedDatePreset] = useState("today");
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const isAdmin =
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN" ||
    user?.userType === "MANAGER";
  const downloadBtnRef = useRef(null);
  const dateDropdownRef = useRef(null);
  const dispatch = useDispatch();

  const marketingSourceWeb = [
    { value: "", label: "All Sources" },
    { value: "Facebook", label: "Facebook" },
    { value: "clickinpedia.com", label: "Clickinpedia.com" },
  ];
  const sourceOptions = [
    ...marketingSourceWeb,
    { value: "assignmentwriter.iu", label: "Assignmentwriter.au" },
    { value: "assignmentwriter.io", label: "Assignmentwriter.io" },
    { value: "www.clickinpedia.io", label: "Clickinpedia.io" },
    { value: "dissertationwritingservices.io", label: "Dissertationwritingservices.io" },
    { value: "managementassignmentwriter.com", label: "Managementassignmentwriter.com" },
    { value: "myobassignmenthelp.com", label: "Myobassignmenthelp.com" },
    { value: "xeroassignmenthelp.com", label: "Xeroassignmenthelp.com" },
    { value: "assignmentexpert.io", label: "Assignmentexpert.io" },
    { value: "asignmenthelp.io", label: "Asignmenthelp.io" },
    { value: "marketingassignmenthelp.io", label: "Marketingassignmenthelp.io" },
    { value: "onlinetafeassignmenthelp.com", label: "Onlinetafeassignmenthelp.com" },
    { value: "scienceassignmenthelp.com", label: "Scienceassignmenthelp.com" },
    { value: "onlineexamhelp.io", label: "Onlineexamhelp.io" },
    { value: "assignmentwriter.au", label: "Assignmentwriter.au" },
    { value: "financeassignmentwriter.com", label: "Financeassignmentwriter.com" },
    { value: "nursingassignments.io", label: "Nursingassignments.io" },
    { value: "lawassignmentwriter.com", label: "Lawassignmentwriter.com" },
    { value: "assignmenthelpuk.io", label: "Assignmenthelpuk.io" },
    { value: "dissertationwriter.uk", label: "Dissertationwriter.uk" },
    { value: "assessmenthelp.uk", label: "Assessmenthelp.uk" },
    { value: "nursingassignments.uk", label: "Nursingassignments.uk" },
    { value: "accountingassignmenthelp.uk", label: "Accountingassignmenthelp.uk" },
    { value: "programmingassignmenthelp.uk", label: "Programmingassignmenthelp.uk" },
    { value: "examhelp.uk", label: "Examhelp.uk" },
    { value: "courseworkhelp.uk", label: "Courseworkhelp.uk" },
    { value: "engineeringassignmenthelp.uk", label: "Engineeringassignmenthelp.uk" },
    { value: "247assignmenthelp.com", label: "247assignmenthelp.com" },
    { value: "247myassignmenthelp.com", label: "247myassignmenthelp.com" },
    { value: "academicassignmenthelp.com", label: "Academicassignmenthelp.com" },
    { value: "accountingassignmentshelp.uk", label: "Accountingassignmentshelp.uk" },
    { value: "assignmenthelp.in", label: "Assignmenthelp.in" },
    { value: "assignmentsamples.com", label: "Assignmentsamples.com" },
    { value: "assignmentsamples.io", label: "Assignmentsamples.io" },
    { value: "bestassignmenthelp.ca", label: "Bestassignmenthelp.ca" },
    { value: "bestassignmenthelp.de", label: "Bestassignmenthelp.de" },
    { value: "bestassignmenthelp.io", label: "Bestassignmenthelp.io" },
    { value: "bestonlineassignmenthelp.co.uk", label: "Bestonlineassignmenthelp.co.uk" },
    { value: "bestonlinedissertationhelp.com", label: "Bestonlinedissertationhelp.com" },
    { value: "bestsophelp.com", label: "Bestsophelp.com" },
    { value: "bestsopservices.com", label: "Bestsopservices.com" },
    { value: "bestsopwriter.com", label: "Bestsopwriter.com" },
    { value: "domyassignmentsforme.uk", label: "Domyassignmentsforme.uk" },
    { value: "managementassignmenthelp.uk", label: "Managementassignmenthelp.uk" },
    { value: "marketingassignmenthelp.uk", label: "Marketingassignmenthelp.uk" },
    { value: "mbaassignmenthelp.uk", label: "Mbaassignmenthelp.uk" },
    { value: "myassignment.uk", label: "Myassignment.uk" },
    { value: "payforassignment.uk", label: "Payforassignment.uk" },
    { value: "sophelpers.com", label: "Sophelpers.com" },
    { value: "sopwork.in", label: "Sopwork.in" },
    { value: "universityassignmenthelp.uk", label: "Universityassignmenthelp.uk" },
    { value: "codingassignmenthelp.io", label: "Codingassignmenthelp.io" },
    { value: "statisticsassignmenthelp.io", label: "Statisticsassignmenthelp.io" },
    { value: "engineeringassignmenthelp.com", label: "Engineeringassignmenthelp.com" },
    { value: "courseworkhelp.io", label: "Courseworkhelp.io" },
    { value: "assignmentsample.io", label: "Assignmentsample.io" },
    { value: "cookeryassignmenthelp.io", label: "Cookeryassignmenthelp.io" },
    { value: "researchproposalwriter.com", label: "Researchproposalwriter.com" },
    { value: "subjectfiles.com", label: "Subjectfiles.com" },
    { value: "subjectfile.com", label: "Subjectfile.com" },
    { value: "myassignmethelp.io", label: "Myassignmethelp.io" },
    { value: "casestudyhelp.uk", label: "Casestudyhelp.uk" },
    { value: "researchproposalhelp.uk", label: "Researchproposalhelp.uk" },
  ];

  const StatusFilters = [
    {label:"New",value:"NEW"},
    {label:"Rejected",value:"REJECTED"},
    {label:"In progress",value:"INPROGRESS"},
    {label:"Testing",value:"TESTING"},
    {label: "Sale Made", value:"SALEMADE"},
    {label: "SPAM", value: "SPAM"},
    {label: "Hot Leads", value: "HOTLEADS"}
  ];

  const datePresets = [
    {
      label: "Today",
      value: "today",
      getDate: () => ({
        start: startOfDay(new Date()),
        end: endOfDay(new Date())
      })
    },
    {
      label: "Yesterday",
      value: "yesterday",
      getDate: () => ({
        start: startOfDay(subDays(new Date(), 1)),
        end: endOfDay(subDays(new Date(), 1))
      })
    },
    {
      label: "Last 7 days",
      value: "last7days",
      getDate: () => ({
        start: startOfDay(subDays(new Date(), 6)),
        end: endOfDay(new Date())
      })
    },
    {
      label: "Custom Range",
      value: "custom"
    }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateDropdownRef.current && !dateDropdownRef.current.contains(event.target)) {
        setShowDateDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSourceChange = (selectedOption) => {
    const leadComingFrom = selectedOption?.value === "" ? "" : selectedOption?.label;
    setFilter((prev) => ({
      ...prev,
      leadComingFrom: leadComingFrom,
    }));
    setFilterSource(selectedOption);
    getLeads({
      tempPage: 1,
      tempLeadComingFrom: leadComingFrom,
      firstTime: true,
    });
    setPageData({ page: 1 });
  };

  useEffect(() => {
    socket.on("new_lead_ads", (newLead) => {
      setLeads((prevLeads) => [newLead, ...prevLeads]);
    });

    return () => {
      socket.off("new_lead_ads");
    };
  }, []);

  const getLeads = ({
    cb,
    tempPage = pageData.page,
    firstTime = false,
    tempStatus = filter?.status,
    tempLeadComingFrom = filter?.leadComingFrom,
    tempStartDate = filter?.startDate,
    tempEndDate = filter?.endDate,
  }) => {
    tempStatus = tempStatus?.map((item) => item.value);
    const payload = {
      page: tempPage,
      firstTime,
      data: {
        status: tempStatus,
        leadComingFrom: tempLeadComingFrom,
        startDate: tempStartDate ? tempStartDate.toISOString() : null,
        endDate: tempEndDate ? tempEndDate.toISOString() : null,
      },
    };
    dispatch(
      getAllLiveLeadsAction({
        userType: user?.userType,
        data: payload,
        cb: cb ? cb : () => {},
      })
    );
  };

  useEffect(() => {
    if (liveLeads?.data) {
      setLeads(liveLeads?.data);
    }
  }, [liveLeads]);

  useEffect(() => {
    if (user) {
      const todayStart = startOfDay(new Date());
      const todayEnd = endOfDay(new Date());
      setFilter((prev) => ({
        ...prev,
        startDate: todayStart,
        endDate: todayEnd,
      }));
      getLeads({
        firstTime: true,
        tempStartDate: todayStart,
        tempEndDate: todayEnd,
      });
    }
  }, [user]);
  

  const fetchMoreData = () => {
    getLeads({ tempPage: pageData?.page + 1 });
    setPageData((prev) => ({ ...prev, page: prev?.page + 1 }));
  };

  const filteredLeads = leads.filter((lead) => {
    const matchesSearch = Object.values(lead).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    return matchesSearch;
  });

  const handleDateChange = (date, type) => {
    setFilter((prev) => ({ ...prev, [type]: date }));
    getLeads({
      tempPage: 1,
      firstTime: true,
      [type === "startDate" ? "tempStartDate" : "tempEndDate"]: date,
    });
    setPageData({ page: 1 });
  };

  const handleDatePresetChange = (preset) => {
    setSelectedDatePreset(preset.value);
    setShowDateDropdown(false);

    if (preset.value === "custom") {
      setFilter((prev) => ({
        ...prev,
        startDate: null,
        endDate: null
      }));
      getLeads({
        tempPage: 1,
        firstTime: true,
        tempStartDate: null,
        tempEndDate: null
      });
    } else {
      const { start, end } = preset.getDate();
      setFilter((prev) => ({
        ...prev,
        startDate: start,
        endDate: end
      }));
      getLeads({
        tempPage: 1,
        firstTime: true,
        tempStartDate: start,
        tempEndDate: end
      });
    }
    setPageData({ page: 1 });
  };

  const getLeadActity = (leadId) => {
    dispatch(
      getLeadActivityAction({
        data: { leadId },
        userType: user?.userType?.toLowerCase(),
        cb: () => setOrderHistory(true),
      })
    );
  };

  return (
    <DashboardLayout>
      {selectedUser?._id && (
        <UserDetailsModal
          isOpen={!!selectedUser?._id}
          onClose={() => setSelectedUser(null)}
          leads={selectedUser}
        />
      )}
      {isAdmin && (
        <ProfileViewsModal
          isOpen={showProfileViews}
          onClose={() => setShowProfileViews(false)}
          leads={showProfileViews}
        />
      )}
      <CustomModal
        open={changeStatus}
        content={() => (
          <LeadDetails
            liveLeadDetails={changeStatus}
            getLeadList={() => {
              setPageData({ page: 1 });
              getLeads({
                firstTime: true,
                tempPage: 1,
                cb: () => dispatch(getUserAction()),
              });
              setChangeStatus("");
            }}
          />
        )}
        onClose={() => setChangeStatus("")}
      />
      <FullScreenModal
        open={orderHistory}
        onClose={() => setOrderHistory("")}
        content={() => (
          <OrderHistoryActivity
            title="Lead Activity"
            onClose={() => setOrderHistory("")}
          />
        )}
      />

      <div className="min-h-screen bg-gray-50">
        <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Lead Management
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  Total Leads: {liveLeads?.pagination?.totalRecords}
                </p>
              </div>
              <button
                onClick={() => downloadBtnRef.current?.click()}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150"
                title="Download all leads data"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Export to Excel
              </button>
            </div>

            {/* Search and Filter Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search leads..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <svg
                  className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <SelectBox
                options={user?.userType==="MARKETING"?marketingSourceWeb: sourceOptions}
                onChange={handleSourceChange}
                value={filterSource}
                className="basic-single"
                classNamePrefix="select"
                isClearable={false}
              />

              <SelectBox
                options={StatusFilters}
                isMulti={true}
                value={filter?.status}
                onChange={(row)=>{
                  getLeads({
                    tempPage: 1,
                    tempStatus: row,
                    firstTime: true,
                  });
                  setPageData({ page: 1 });
                  setFilter({...filter,status:row})
                }}
              />

              <div className="relative col-span-1 xl:col-span-2" ref={dateDropdownRef}>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="relative">
                    <button
                      onClick={() => setShowDateDropdown(!showDateDropdown)}
                      className="inline-flex items-center justify-between w-full sm:w-[200px] px-4 py-2 text-sm border border-gray-300 rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <span className="flex items-center">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        {datePresets.find(preset => preset.value === selectedDatePreset)?.label}
                      </span>
                      <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {showDateDropdown && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                        {datePresets.map((preset) => (
                          <button
                            key={preset.value}
                            className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:outline-none"
                            onClick={() => handleDatePresetChange(preset)}
                          >
                            {preset.label}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

                  {selectedDatePreset === "custom" && (
                    <div className="flex flex-col sm:flex-row gap-4">
                      <DatePicker
                        selected={filter.startDate}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        placeholderText="Start Date"
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        maxDate={filter.endDate || new Date()}
                      />
                      <DatePicker
                        selected={filter.endDate}
                        onChange={(date) => handleDateChange(date, "endDate")}
                        placeholderText="End Date"
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        minDate={filter.startDate}
                        maxDate={new Date()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Table Section */}
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <CustomDataTable
                columns={LiveLeadColumn({
                  setShowProfileViews,
                  setSelectedUser,
                  setChangeStatus,
                  getLeadActity,
                })}
                hasMore={filteredLeads.length < liveLeads?.pagination?.totalRecords}
                data={filteredLeads}
                isInfinitScroll={true}
                fetchMoreData={fetchMoreData}
                isNotOrderId={true}
                downLoadBtnRef={downloadBtnRef}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
