import React, { useState } from "react";
import moment from "moment";
import { Eye, ListFilter } from "lucide-react";
import { truncateString } from "../../components/utils/functions/functions";
import { Tooltip } from "@mui/material";

export const LiveLeadColumn = ({ getLeadActity,setSelectedUser, setShowProfileViews, setChangeStatus }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const statusClass=(row)=>{
   return `
      text-xs px-2 py-0.5 rounded-full w-fit
      ${
        row.status === "NEW"
          ? "bg-blue-50 text-blue-700"
          : row.status === "REJECTED"
          ? "bg-red-50 text-red-700"
          : row.status === "INPROGRESS"
          ? "bg-amber-50 text-amber-700"
          : row.status === "TESTING"
          ? "bg-purple-50 text-purple-700"
          : "bg-gray-50 text-gray-600"
      }
    `
  }

  return [
    {
      name: "S...",
      selector: (_, index) => index + 1,
      sortable: true,
      width: "60px",
      cell: (_, index) => (
        <div className="py-4 px-4">
          <span className="text-sm text-gray-400 tabular-nums">
            {String(index + 1).padStart(2, "0")}
          </span>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.userName,
      sortable: true,
      width: "240px",
      cell: (row) => (
        <div className="py-4 px-4" >
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center">
              <span className="text-blue-600 text-sm font-medium">
                {row.userName === "NA" ? "N" : row.userName.charAt(0)}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-900">{row.userName}</span>
              <span
                onClick={() => setChangeStatus(row)}
                className={`${statusClass(row)} pointer`}
              >
                {row.status || "new"}
              </span>
              <Tooltip title={row?.leadActivity?.length?row?.leadActivity[row?.leadActivity?.length-1]?.description:""} >
              <span
                onClick={()=>getLeadActity(row?._id)}
                className={`mt-1 pointer`}
              >
                {"Activity"}
              </span>
              </Tooltip>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Contact",
      selector: (row) => row.userEmail,
      sortable: true,
      width: "280px",
      cell: (row) => (
        <div
          className="py-4 px-4 space-y-1.5 relative group"
          onMouseEnter={() => setHoveredRow(row?._id)}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <div className="text-sm text-blue-600">
            <span>{row.userEmail}</span>
          </div>
          <div className="text-sm text-gray-600">
            <span>{row.userPhone}</span>
          </div>
          {hoveredRow === row?._id && (
            <div className="absolute right-4 top-1/2 -translate-y-1/2 flex items-center gap-2">
              <button
                className="p-1.5 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                onClick={() => setShowProfileViews(row)}
                title="View profile views"
              >
                <ListFilter className="w-4 h-4" />
              </button>
              <button
                className="p-1.5 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                onClick={() => setSelectedUser(row)}
                title="View details"
              >
                <Eye className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Subject/Message",
      selector: (row) => row?.userSubject,
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div className="py-4 px-4">
          <Tooltip title={row.userSubject}>        
              <span className="text-sm text-gray-600">{truncateString(row.userSubject,20) || "-"}</span>
          </Tooltip>

        </div>
      ),
    },
    {
      name: "Lead Time",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "180px",
      cell: (row) => (
        <div className="py-4 px-4">
          <div className="text-sm text-gray-900">
            {/* {console.log("row.leadTime",row.leadTime,row?.leadComingFrom)} */}
            {moment(row.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="text-xs text-gray-400">
            {moment(row.createdAt).format("HH:mm")}
          </div>
        </div>
      ),
    },
    {
      name: "Source",
      selector: (row) => row.leadComingFrom,
      sortable: true,
      width: "240px",
      cell: (row) => (
        <div className="py-4 px-4">
          <span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs bg-gray-50 text-gray-700">
            {row.leadComingFrom}
          </span>
        </div>
      ),
    },
    
    {
      name: "Address",
      selector: (row) => row.userAddress,
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div className="py-4 px-4">
          <span className="text-sm text-gray-600">{row.userAddress || "-"}</span>
        </div>
      ),
    },
  ];
};
