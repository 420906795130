import React, { useState } from 'react'
import SidebarOverlayLiveDash from '../layouts/SidebarOverlayLiveDash'
import FilterButton from '../components/FilterButton'
import ChatItem from '../components/ChatItem'
import { ActionModal } from '../components/ActionModal'
import { motion } from "framer-motion";
import { MoreVertical, RefreshCcw, Search, MessageCircle, AlertCircle, CheckCircle2, Clock } from 'lucide-react';
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

const MobileViewsSidebar = ({
  onClickRow,
  chatList,
  chatStats,
  loadMoreChats,
  hasMoreData = true,
  paginations,
  getAllLiveUser,
  setFilter,
  setPageData,
  filter,
  setShowLeftSidebar,
  showLeftSidebar,
  setDetailReport
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const handleMoreVerticalClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setModalPosition({ x: rect.left, y: rect.top });
    setIsModalOpen(true);
  };
  const {chatHeaderInfo}=useSelector((state)=>state.commonReducer)


  return (
    <SidebarOverlayLiveDash show={showLeftSidebar} onClose={() => setShowLeftSidebar(false)}>
      <div className="p-4 space-y-4 bg-[#00234a]">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <span className="text-lg font-semibold text-white" onClick={()=>setDetailReport(true)}>Detail Report</span>
            <span className="bg-gray-700/50 px-2.5 py-0.5 rounded-full text-sm font-medium">
              {chatStats.all}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg"
            >
              <RefreshCcw size={18} />
            </motion.button>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg"
              onClick={handleMoreVerticalClick}
            >
              <MoreVertical size={18} />
            </motion.button>
          </div>
        </div>
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search chats..."
            className="w-full bg-[#1a2d4a] rounded-2xl py-3.5 px-8 pl-12 focus:outline-none focus:ring-2 focus:ring-blue-500 text-base placeholder-gray-400"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
        <div className="grid grid-cols-2 gap-3">
        <FilterButton
            filter="all"
            icon={MessageCircle}
            label="Total"
            count={chatHeaderInfo?.total}
            activeFilter={filter?.status === "ALL"}
            onClick={() => {
              let newValue = filter?.status === "ALL" ? "" : "ALL";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={AlertCircle}
            label="Missed"
            count={chatHeaderInfo?.missed || 0}
            activeFilter={filter?.status === "MISSED"}
            onClick={() => {
              let newValue = filter?.status === "MISSED" ? "" : "MISSED";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={CheckCircle2}
            label="Inprogress"
            count={chatHeaderInfo?.inProgress || 0}
            activeFilter={filter?.status === "INPROGRESS"}
            onClick={() => {
              let newValue = filter?.status === "INPROGRESS" ? "" : "INPROGRESS";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={Clock}
            label="Online"
            count={chatHeaderInfo?.onlineUsers}
            activeFilter={filter?.status === "ISONLINE"}
            onClick={() => {
              let newValue = filter?.status === "ISONLINE" ? "" : "ISONLINE";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />

<FilterButton
            icon={Clock}
            label="Initiated"
            count={chatHeaderInfo?.intitiated}
            activeFilter={filter?.status === "INITIATED"}
            onClick={() => {
              let newValue = filter?.status === "INITIATED" ? "" : "INITIATED";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />

<FilterButton
            icon={Clock}
            label="Foreign"
            count={chatHeaderInfo?.foregnData}
            activeFilter={filter?.status === "FOREIGN"}
            onClick={() => {
              let newValue = filter?.status === "FOREIGN" ? "" : "FOREIGN";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
        </div>
      </div>
      <div
        id="scrollableDiv"
        className="overflow-y-auto flex-1 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
        style={{ height: "calc(100vh - 280px)" }}
      >
        <InfiniteScroll
          dataLength={chatList.length}
          next={loadMoreChats}
          hasMore={hasMoreData}
          scrollableTarget="scrollableDiv"
        >
          {chatList.map((chat, index) => (
            <ChatItem
              onClickRow={onClickRow}
              currentIndex={index}
              key={chat?._id}
              chat={chat}
            />
          ))}
        </InfiniteScroll>
      </div>
      <ActionModal 
      getAllLiveUser={getAllLiveUser}
        filter={filter}
        setFilter={setFilter}
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        buttonPosition={modalPosition}
      />
    </SidebarOverlayLiveDash>
  )
}

export default MobileViewsSidebar

