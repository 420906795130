import { appActions } from "../../action";

export const uploadImageAction=payload=>({
  type: appActions.UPLOAD_IMAGE,
  payload,
})

export const uploadAudioAction=payload=>({
  type: appActions.UPLOAD_AUDIO,
  payload,
})

export const startChatAction=payload=>({
  type: appActions.START_CHAT,
  payload,
})

export const getChatListAction=payload=>({
  type: appActions.GET_CHAT,
  payload,
})


export const getDashboardAction=payload=>({
  type: appActions.GET_DASHBOARD,
  payload,
})
export const closeChatAction=payload=>({
  type: appActions.CLOSE_CHAT,
  payload,
})

export const getExpertListOptionAction=payload=>({
  type: appActions.GET_EXPERT_LIST_OPTION,
  payload,
})

export const getNotificationListAction=payload=>({
  type: appActions.GET_NOTIFICATION_LIST,
  payload,
})

export const updateNotificationAction=payload=>({
  type: appActions.UPDATE_NOTIFICATION,
  payload,
})

export const updateNotificationStatusAction=payload=>({
  type: appActions.UPDATE_NOTIFICATION_STATUS,
  payload,
})

export const generatePaymentLinkAction=payload=>({
  type: appActions.GENERATE_PAYMENT_LINK,
  payload,
})

export const getCountryListAction=payload=>({
  type: appActions.GET_COUNTRY_LIST,
  payload,
})

export const sendPaymentLinkOnWhatsappAction=payload=>({
  type: appActions.SENT_PAYMENT_LINK_WHATSAPP,
  payload,
})
export const getOperatorOrManagerListAction=payload=>({
  type: appActions.GET_OPERATOR_MANAGER_LIST_ACTION,
  payload,
})

export const requestForAssignLeadAction=payload=>({
  type: appActions.REQUEST_FOR_ASSIGN_LEAD,
  payload,
})

export const getAllLiveUserListAction=payload=>({
  type: appActions.GET_ALL_LIVE_USER_LIST,
  payload,
})

export const getOpenUserChatListAction=payload=>({
  type: appActions.GET_OPEN_USER_CHAT_LIST,
  payload,
})

export const getOpenUseNoticationAction=payload=>({
  type: appActions.GET_OPEN_NOTIFICATION,
  payload,
})

export const startOpenChatAction=payload=>({
  type: appActions.START_OPEN_CHAT,
  payload,
})
export const closeOpenChatAction=payload=>({
  type: appActions.CLOSE_OPEN_CHAT,
  payload,
})
export const setNotificationPermissionAction=payload=>({
  type: appActions.SET_NOTIFICATION_PERMISSION,
  payload,
})

export const getReportsAction=payload=>({
  type: appActions.GET_REPORTS,
  payload,
})

export const getLeadDetailAction=payload=>({
  type:appActions.GET_LEAD_DETAILS,
  payload
})

export const getExpertQueriesAction=payload=>({
  type:appActions.GET_EXPERT_QUERIES,
  payload
})

export const updateQueryStatusAction=payload=>({
  type:appActions.UPDATE_QUERY_STATUS,
  payload
})

export const updateCurrencyAction=payload=>({
  type:appActions.UPDATE_CURRENCY,
  payload
})
export const getCurrencyListAction=payload=>({
  type:appActions.GET_CURRENCY_LIST,
  payload
})

export const getOrderActivityAction=payload=>({
  type:appActions.GET_ORDER_ACTIVITY,
  payload
})

export const setPenaltyAction=payload=>({
  type:appActions.SET_PENALTY,
  payload
})

export const getWelcomeScreenDataAction=payload=>({
  type:appActions.GET_WELCOME_SCREEN_DATA,
  payload
})

export const updateAndroidDesignAction=payload=>({
  type:appActions.ON_UPDATE_ANDROID_DESIGN,
  payload
})

export const updateReferalAmount=payload=>({
  type:appActions.UPDATE_REFERAL_AMOUNT,
  payload
})

export const getReferalAmountAction=payload=>({
  type:appActions.GET_REFERAL_AMOUNT,
  payload
})

export const verifyCouponCodeAction=payload=>({
  type:appActions.VERIFY_COUPON_CODE,
  payload
})

export const updateClientWalletAction=payload=>({
  type:appActions.UPDATE_CLIENT_WALLET,
  payload
})

export const getAllLiveLeadsAction=payload=>({
  type:appActions.ALL_LIVE_LEADS,
  payload
})

export const getLiveLeadDetailAction=payload=>({
  type:appActions.GET_LIVE_LEADS_DETAILS,
  payload
})

export const updateLiveAdsStatusAction=payload=>({
  type:appActions.LIVE_ADS_STATUS_UPDATE,
  payload
})


export const getLeadActivityAction=payload=>({
  type:appActions.GET_LEAD_ACTIVITY,
  payload
})


export const getReportOrderListAction=payload=>({
  type:appActions.GET_REPORT_ORDER_LIST,
  payload
})

export const getUserDetailsAction=payload=>({
  type:appActions.GET_USER_DETAILS,
  payload
})

export const getUserReportListAction=payload=>({
  type:appActions.GET_USER_REPORTLIST,
  payload
})

export const getLiverUserHeaderCountAction=payload=>({
  type:appActions.GET_LIVE_USER_TITLE_COUNT,
  payload
})

export const updateLiveUserDetailAction=payload=>({
  type:appActions.UPDATE_LIVE_USER,
  payload
})

export const updateAiModeAction=payload=>({
  type:appActions.UPDATE_AI_MODE,
  payload
})

export const deleteUserAcountAction=payload=>({
  type:appActions.DELETE_USER_ACTION,
  payload
})

export const updateBasicDetailAction=payload=>({
  type:appActions.UPDATE_DETAILS,
  payload
})