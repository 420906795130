import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { countryCodes } from "../../../components/constants/commonConstant";
import { signupAction } from "../../../services/action/authentication";
import { verifyCouponCodeAction } from "../../../services/action/common"; // Import coupon logic action
import { useDispatch } from "react-redux";
import { checkLogin } from "../../../components/utils/functions/functions";
import { useLocation, useNavigate } from "react-router-dom";

const FormContainer = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const ProfileCompletionForm = ({ userInfo }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  // Example: Get a specific query parameter by name
  const isAndroidApp = queryParams.get("clickinpedia");

  const [userType, setUserType] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("idle");
  const [couponMessage, setCouponMessage] = useState("");
  const [isVerifyingCoupon, setIsVerifyingCoupon] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const validateForm = (data) => {
    let valid = true;

    // Clear previous errors
    clearErrors();

    // Phone validation
    if (!data.phone) {
      setError("phone", {
        type: "manual",
        message: "Phone number is required",
      });
      valid = false;
    } else if (!/^[0-9]{10,15}$/.test(data.phone)) {
      setError("phone", { type: "manual", message: "Invalid phone number" });
      valid = false;
    }

    // Password validation
    if (!data.password) {
      setError("password", { type: "manual", message: "Password is required" });
      valid = false;
    } else if (data.password.length < 6) {
      setError("password", {
        type: "manual",
        message: "Password must be at least 6 characters long",
      });
      valid = false;
    }

    // Confirm password validation
    if (!data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Confirm password is required",
      });
      valid = false;
    } else if (data.confirmPassword !== data.password) {
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords must match",
      });
      valid = false;
    }

    // User type validation
    if (!data.userType) {
      setError("userType", {
        type: "manual",
        message: "User type is required",
      });
      valid = false;
    }

    return valid;
  };

  const verifyCouponCode = () => {
    if (!couponCode.trim()) return;

    setIsVerifyingCoupon(true);
    setCouponStatus("idle");

    const payload = {
      data: { couponCode },
      cb: (res) => {
        setCouponMessage(res?.msg);
        setCouponStatus("valid");
        setIsVerifyingCoupon(false);
      },
      errorCb: (res) => {
        setCouponMessage(res?.response?.data?.msg || "Invalid coupon code");
        setCouponStatus("invalid");
        setIsVerifyingCoupon(false);
      },
    };
    dispatch(verifyCouponCodeAction(payload));
  };

  const onSubmit = (data) => {
    if (couponStatus === "invalid") {
      setError("couponCode", {
        type: "manual",
        message: "Please resolve the coupon issue before submitting.",
      });
      return;
    }

    if (validateForm(data)) {
      const callBack = (res) => {
        if (res?.data) {
          localStorage.setItem("token", res?.data?.token);
          if (isAndroidApp) {
            const url = "myapp://Login?token=" + res?.data?.token;
            window.location.href = url;
          } else {
            checkLogin(navigate, res?.data);
          }
        }
      };
      const signupData = {
        ...data,
        name: userInfo?.name,
        image: userInfo?.picture,
        email: userInfo?.email,
        isLogin: true,
        couponCode,
      };
      dispatch(signupAction({ data: signupData, cb: callBack }));
    }
  };

  const handleUserTypeChange = (event) => setUserType(event.target.value);

  const reset = () => {
    localStorage.removeItem("emailVerifyToken");
    if (isAndroidApp === "app") {
      window.location.href =
        window.location.origin + "/auth/google?clickinpedia=app";
    } else {
      window.location.href = window.location.origin + "/auth/google";
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Complete Your Profile
      </Typography>
      <Box
        component="img"
        sx={{ height: 100, width: 100, borderRadius: "50%", m: 1 }}
        alt={userInfo.name}
        src={userInfo.picture}
      />
      <Typography variant="subtitle1">{userInfo.name}</Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {userInfo.email}
      </Typography>

      <span onClick={reset} className="pointer">
        <Typography variant="subtitle" color="blue">
          Reset
        </Typography>
      </span>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="country-code-label">Country Code</InputLabel>
          <Controller
            name="countryCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="country-code-label"
                id="country-code"
                label="Country Code"
                error={!!errors.countryCode}
              >
                {countryCodes.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.code} {item.country}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Controller
          name="phone"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Phone Number"
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          )}
        />

        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Confirm Password"
              type="password"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="user-type-label">User Type</InputLabel>
          <Controller
            name="userType"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="user-type-label"
                id="user-type"
                label="User Type"
                onChange={(e) => {
                  field.onChange(e);
                  handleUserTypeChange(e);
                }}
                error={!!errors.userType}
              >
                <MenuItem value="CLIENT">Student</MenuItem>
                <MenuItem value="EXPERT">Expert</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        {userType === "expert" && (
          <>
            <Controller
              name="pancard"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="pancard"
                  label="Upload PAN Card"
                  type="file"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Controller
              name="bankDocument"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="bankDocument"
                  label="Upload Cancelled Check or Bank Passbook"
                  type="file"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </>
        )}

        {/* Coupon Code Section */}
        <Controller
          name="couponCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Coupon Code (Optional)"
              value={couponCode}
              onChange={(e) => {
                field.onChange(e);
                setCouponCode(e.target.value);
                if (couponStatus !== "idle") setCouponStatus("idle");
              }}
              error={couponStatus === "invalid"}
              helperText={couponStatus === "invalid" ? couponMessage : ""}
            />
          )}
        />
        <Button
          onClick={verifyCouponCode}
          variant="contained"
          color="primary"
          disabled={!couponCode.trim() || isVerifyingCoupon}
        >
          {isVerifyingCoupon ? "Verifying..." : "Verify Coupon"}
        </Button>
        {couponStatus === "valid" && (
          <Typography variant="body2" color="green">
            {couponMessage}
          </Typography>
        )}

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Complete Registration
        </SubmitButton>
      </FormContainer>
    </>
  );
};

export default ProfileCompletionForm;
