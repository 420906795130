import React, { Fragment, useState } from "react";
import CustomModal from "../../utils/modals/CustomModal";
import UserConatactContent from "./UserConatactContent";

const UserContactDetails = ({ contactDetails }) => {
  const [contactList, setContactList] = useState("");
  return (
    <Fragment>
      <CustomModal
        open={contactList}
        onClose={() => setContactList("")}
        content={() => <UserConatactContent data={contactList} />}
      />
      <button
        onClick={() => {
          setContactList(contactDetails)
        }}
        className="flex-1 border border-[#00234a] text-[#00234a] py-2 px-4 rounded text-sm font-medium hover:bg-[#00234a]/5 transition-colors"
      >
        View Contact
      </button>
    </Fragment>
  );
};

export default UserContactDetails;
