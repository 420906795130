import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileUp, Trash2, Plus, FileText, AlertCircle, Loader2, X } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { uploadImageAction } from '../../../services/action/common';

const DocumentsForm = ({
  documentPreviews,
  workSamples,
  onDocumentUpload,
  onWorkSampleUpload,
  onRemoveDocument,
  onRemoveWorkSample,
}) => {
  const dispatch = useDispatch();
  const [draggedZone, setDraggedZone] = useState(null);
  const [uploadingType, setUploadingType] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [hoveredDocument, setHoveredDocument] = useState(null);

  const validateFile = (file, type) => {
    const maxSize = type === 'workSamples' ? 10 : 5;
    const allowedTypes = type === 'workSamples' 
      ? ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
      : ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

    if (!allowedTypes.includes(file.type)) {
      alert(`Invalid file type. Please upload ${type === 'workSamples' ? 'PDF, DOC, DOCX, or TXT' : 'PNG, JPG, or PDF'}`);
      return false;
    }

    if (file.size > maxSize * 1024 * 1024) {
      alert(`File size should not exceed ${maxSize}MB`);
      return false;
    }

    return true;
  };

  const uploadImage = useCallback(async (files, type) => {
    const validFiles = Array.from(files).filter(file => validateFile(file, type));
    
    if (!validFiles.length) return;

    if (type === 'workSamples' && workSamples.length + validFiles.length > 5) {
      alert('Maximum 5 work samples allowed');
      return;
    }

    setUploadingType(type);
    setUploadProgress({ [type]: 0 });

    const formData = new FormData();
    validFiles.forEach(file => formData.append('images', file));

    try {
      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => ({
          ...prev,
          [type]: Math.min((prev[type] || 0) + 10, 90)
        }));
      }, 200);

      await new Promise(resolve => dispatch(uploadImageAction({
        data: formData,
        cb: (res) => {
          clearInterval(progressInterval);
          setUploadProgress(prev => ({ ...prev, [type]: 100 }));
          
          if (!res?.imageUrls?.length) {
            alert('Upload failed. Please try again.');
            return;
          }

          if (type === 'workSamples') {
            const uploadedSamples = res.imageUrls.map(url => ({
              name: url.split('/').pop(),
              preview: url,
              file: validFiles[0],
            }));
            onWorkSampleUpload([...workSamples, ...uploadedSamples]);
          } else {
            onDocumentUpload(type, { preview: res.imageUrls[0] });
          }
          resolve();
        }
      })));

      // Clear progress after a short delay
      setTimeout(() => {
        setUploadProgress(prev => ({ ...prev, [type]: null }));
        setUploadingType(null);
      }, 500);

    } catch (error) {
      console.error('Upload error:', error);
      alert('Upload failed. Please try again.');
      setUploadingType(null);
      setUploadProgress(prev => ({ ...prev, [type]: null }));
    }
  }, [dispatch, workSamples, onWorkSampleUpload, onDocumentUpload]);

  const handleDragOver = useCallback((e, zone) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedZone(zone);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedZone(null);
  }, []);

  const handleDrop = useCallback((e, type) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedZone(null);
    const files = Array.from(e.dataTransfer.files);
    uploadImage(files, type);
  }, [uploadImage]);

  const handleRemoveDocument = (type) => {
    if (window.confirm(`Are you sure you want to remove this ${type === 'pan' ? 'PAN Card' : 'Bank Proof'}? This action cannot be undone.`)) {
      onRemoveDocument(type);
    }
  };

  const handleRemoveWorkSample = (index) => {
    if (window.confirm('Are you sure you want to remove this work sample? This action cannot be undone.')) {
      onRemoveWorkSample(index);
    }
  };

  const renderUploadZone = (type, label) => (
    <div
      className={`relative transition-all duration-300 ${
        draggedZone === type ? 'bg-blue-500/10 scale-[1.02]' : ''
      }`}
      onDragOver={(e) => handleDragOver(e, type)}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop(e, type)}
      onMouseEnter={() => setHoveredDocument(type)}
      onMouseLeave={() => setHoveredDocument(null)}
    >
      <AnimatePresence mode="wait">
        {documentPreviews[type] ? (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="relative group"
          >
            <div className="relative">
              <img
                src={documentPreviews[type] || "/placeholder.svg"}
                alt={`${label} Preview`}
                className="w-full h-48 object-cover rounded-xl border-2 border-blue-500/30 shadow-lg"
              />
              <AnimatePresence>
                {hoveredDocument === type && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute inset-0 bg-black/60 rounded-xl flex items-center justify-center gap-4"
                  >
                    <button
                      onClick={() => handleRemoveDocument(type)}
                      className="p-3 bg-red-500 rounded-full hover:bg-red-600 transform hover:scale-110 transition-all duration-200 group flex items-center gap-2"
                    >
                      <Trash2 className="w-5 h-5 text-white" />
                      <span className="text-white text-sm font-medium opacity-0 group-hover:opacity-100 absolute left-full ml-2 whitespace-nowrap">
                        Remove {type === 'pan' ? 'PAN Card' : 'Bank Proof'}
                      </span>
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="mt-2 flex items-center justify-between px-1">
              <span className="text-sm text-gray-300">
                {type === 'pan' ? 'PAN Card' : 'Bank Proof'} uploaded
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-green-400">Verified</span>
                <div className="w-2 h-2 rounded-full bg-green-400"></div>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.label
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={`flex flex-col items-center justify-center w-full h-48 border-2 border-dashed border-[#003366] rounded-xl cursor-pointer hover:border-blue-500 transition-all duration-300 group bg-[#001B3D]/50 relative ${
              uploadingType === type ? 'pointer-events-none' : ''
            }`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {uploadingType === type ? (
                <div className="flex flex-col items-center">
                  <Loader2 className="w-12 h-12 mb-3 text-blue-500 animate-spin" />
                  <p className="text-sm text-gray-400">Uploading... {uploadProgress[type]}%</p>
                </div>
              ) : (
                <>
                  <FileUp className="w-12 h-12 mb-3 text-blue-500 group-hover:scale-110 transition-transform" />
                  <p className="mb-2 text-sm text-gray-400">
                    <span className="font-semibold text-blue-500">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-400">PNG, JPG or PDF (max. 5MB)</p>
                </>
              )}
            </div>
            <input
              type="file"
              accept="image/*,.pdf"
              onChange={(e) => uploadImage(e.target.files, type)}
              className="hidden"
              disabled={uploadingType === type}
            />
            {uploadProgress[type] && (
              <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-500/20">
                <motion.div
                  className="h-full bg-blue-500"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress[type]}%` }}
                  transition={{ duration: 0.2 }}
                />
              </div>
            )}
          </motion.label>
        )}
      </AnimatePresence>
    </div>
  );

  return (
    <div className="space-y-8 max-w-2xl mx-auto">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold text-white">Required Documents</h3>
          <div className="flex items-center text-xs text-yellow-400">
            <AlertCircle className="w-4 h-4 mr-1" />
            Verification Required
          </div>
        </div>

        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200">PAN Card</label>
          {renderUploadZone('pan', 'PAN Card')}
        </div>

        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200">Bank Proof (Cancelled Cheque/Passbook)</label>
          {renderUploadZone('bankProof', 'Bank Proof')}
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold text-white">Work Samples</h3>
          <div className="text-xs text-blue-400">
            {workSamples.length}/5 samples uploaded
          </div>
        </div>

        <div className="space-y-4">
          <AnimatePresence>
            {workSamples.map((sample, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                className="relative group bg-[#001B3D] p-4 rounded-xl border border-[#003366] hover:border-blue-500 transition-colors shadow-md"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-blue-500/10 rounded-lg">
                      <FileText className="w-6 h-6 text-blue-500" />
                    </div>
                    <div>
                      <p className="text-sm font-medium truncate max-w-[200px] text-white">{sample.name}</p>
                      <p className="text-xs text-gray-400">
                        {sample.file ? `${(sample.file.size / 1024 / 1024).toFixed(2)} MB` : 'Size unavailable'}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleRemoveWorkSample(index)}
                    className="p-2 hover:bg-red-500/20 rounded-lg transition-colors group"
                    disabled={uploadingType === 'workSamples'}
                  >
                    <Trash2 className="w-5 h-5 text-red-400 group-hover:scale-110 transition-transform" />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>

          {workSamples.length < 5 && (
            <div
              className={`transition-all duration-300 ${
                draggedZone === 'workSamples' ? 'bg-blue-500/10 scale-[1.02]' : ''
              }`}
              onDragOver={(e) => handleDragOver(e, 'workSamples')}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, 'workSamples')}
            >
              <motion.label
                className={`flex items-center justify-center p-6 border-2 border-dashed border-[#003366] rounded-xl cursor-pointer hover:border-blue-500 transition-all duration-300 group bg-[#001B3D]/50 relative ${
                  uploadingType === 'workSamples' ? 'pointer-events-none' : ''
                }`}
                whileHover={{ scale: 1.01 }}
              >
                <div className="flex flex-col items-center space-y-2 text-blue-500">
                  {uploadingType === 'workSamples' ? (
                    <>
                      <Loader2 className="w-8 h-8 animate-spin" />
                      <span className="font-medium text-sm">Uploading... {uploadProgress['workSamples']}%</span>
                    </>
                  ) : (
                    <>
                      <Plus className="w-8 h-8 group-hover:scale-110 transition-transform" />
                      <span className="font-medium text-sm">Add Work Sample</span>
                      <p className="text-xs text-gray-400">PDF, DOC, DOCX, TXT (max. 10MB)</p>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={(e) => uploadImage(e.target.files, 'workSamples')}
                  className="hidden"
                  multiple
                  disabled={uploadingType === 'workSamples'}
                />
                {uploadProgress['workSamples'] && (
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-500/20">
                    <motion.div
                      className="h-full bg-blue-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${uploadProgress['workSamples']}%` }}
                      transition={{ duration: 0.2 }}
                    />
                  </div>
                )}
              </motion.label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsForm;

