"use client";

import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Diamond,
  Crown,
  Users,
  ArrowLeft,
  CheckCircle2,
  HelpCircle,
} from "lucide-react";
import ClientHeader from "../../../components/layout-new/HeaderNew";

const DUMMY_COUPON = "EXTRA25";

export default function NewOrders() {
  const [currentStep, setCurrentStep] = useState(1);
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service: "Assignment",
      pages: "1 Page / 250 Words",
      urgency: "30 Days",
      writerLevel: "all",
      handPickedWriter: false,
    },
  });

  const onSubmit = (data) => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      console.log("Form submitted:", data);
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleApplyCoupon = () => {
    if (couponCode.toUpperCase() === DUMMY_COUPON) {
      setCouponApplied(true);
      setCouponError("");
    } else {
      setCouponApplied(false);
      setCouponError("Invalid coupon code");
    }
  };

  // Progress steps component
  const ProgressSteps = () => (
    <div className="flex items-center justify-center gap-4">
      {[
        { step: 1, label: "Order Details" },
        { step: 2, label: "Select Writer" },
        { step: 3, label: "Payment" },
      ].map((item, index) => (
        <div key={item.step} className="flex items-center">
          {index > 0 && <div className="w-16 h-0.5 bg-gray-200" />}
          <div
            className={`flex items-center gap-2 ${
              currentStep === item.step ? "text-[#2563EB]" : "text-gray-400"
            }`}
          >
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                currentStep === item.step
                  ? "bg-[#2563EB] text-white"
                  : "bg-gray-200"
              }`}
            >
              {item.step}
            </div>
            <span className="hidden sm:inline text-sm font-medium">
              {item.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  // Form Fields Component
  const FormFields = () => (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 px-0">
      <div className="bg-[#F0FFF4] p-4 rounded-t-lg">
        <div className="flex items-center gap-2 mb-2">
          <label className="text-sm font-medium text-gray-700">
            Your selected service
          </label>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <select
          {...register("service", { required: "Service is required" })}
          className={`w-full border ${
            errors.service ? "border-red-500" : "border-gray-200"
          } rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white`}
        >
          <option>Assignment</option>
          <option>Essay</option>
          <option>Research Paper</option>
        </select>
        {errors.service && (
          <p className="text-red-500 text-sm mt-1">{errors.service.message}</p>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <div className="flex items-center gap-2 mb-2">
            <label className="text-sm font-medium text-gray-700">
              Select Subject
            </label>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("subject", { required: "Subject is required" })}
            className={`w-full border ${
              errors.subject ? "border-red-500" : "border-gray-200"
            } rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
          >
            <option value="">Select Subject</option>
            <option value="mathematics">Mathematics</option>
            <option value="science">Science</option>
            <option value="english">English</option>
          </select>
          {errors.subject && (
            <p className="text-red-500 text-sm mt-1">
              {errors.subject.message}
            </p>
          )}
        </div>

        <div>
          <div className="flex items-center gap-2 mb-2">
            <label className="text-sm font-medium text-gray-700">
              Select Pages/Words
            </label>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("pages")}
            className="w-full border border-gray-200 rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent"
          >
            <option>1 Page / 250 Words</option>
            <option>2 Pages / 500 Words</option>
            <option>3 Pages / 750 Words</option>
          </select>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-2">
            <label className="text-sm font-medium text-gray-700">
              Select Urgency
            </label>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("urgency")}
            className="w-full border border-gray-200 rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent"
          >
            <option>4 hours</option>
            <option>8 hours</option>
            <option>24 hours</option>
            <option>2 days</option>
            <option>3 days</option>
            <option>7 days</option>
          </select>
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="text-sm font-medium text-gray-700">
            Enter Topic
          </label>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <input
          type="text"
          {...register("topic", { required: "Topic is required" })}
          className={`w-full border ${
            errors.topic ? "border-red-500" : "border-gray-200"
          } rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
          placeholder="Enter your topic"
        />
        {errors.topic && (
          <p className="text-red-500 text-sm mt-1">{errors.topic.message}</p>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="text-sm font-medium text-gray-700">
            Specify Your Requirements Here
          </label>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <textarea
          {...register("requirements", {
            required: "Requirements are required",
          })}
          className={`w-full border ${
            errors.requirements ? "border-red-500" : "border-gray-200"
          } rounded-md p-2.5 text-sm h-32 focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
          placeholder="The more instructions you provide, the better our writers can craft a paper that meets your needs."
        />
        {errors.requirements && (
          <p className="text-red-500 text-sm mt-1">
            {errors.requirements.message}
          </p>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="text-sm font-medium text-gray-700">Upload</label>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
          <input
            type="file"
            {...register("files")}
            className="hidden"
            id="file-upload"
            multiple
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer text-sm text-gray-600"
          >
            <span className="flex items-center justify-center gap-2">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Drop file here or Click to upload
            </span>
          </label>
        </div>
      </div>

      <div className="flex items-start gap-2">
        <input
          type="checkbox"
          id="terms"
          {...register("terms", { required: "You must accept the terms" })}
          className={`mt-1 rounded border-gray-300 ${
            errors.terms ? "border-red-500" : ""
          }`}
        />
        <label htmlFor="terms" className="text-sm text-gray-600">
          I agree with{" "}
          <a href="#" className="text-gray-600 underline">
            Privacy Policy
          </a>
          ,{" "}
          <a href="#" className="text-gray-600 underline">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href="#" className="text-gray-600 underline">
            Money Back Guarantee
          </a>{" "}
          and also accept{" "}
          <a href="#" className="text-gray-600 underline">
            Cookie Policy
          </a>
          .
        </label>
      </div>
      {errors.terms && (
        <p className="text-red-500 text-sm mt-1">{errors.terms.message}</p>
      )}

      <button
        type="submit"
        className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300"
      >
        Continue to Select Writer
      </button>
    </form>
  );

  // Writer Selection Step Component
  const WriterSelectionStep = () => (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <button
        onClick={handleBack}
        className="mb-6 text-sm text-[#2563EB] hover:text-[#1d4ed8] transition duration-300 flex items-center gap-2"
      >
        <ArrowLeft className="w-4 h-4" />
        Back to Order Details
      </button>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Time Attachment Section */}
        <div className="mb-8 border-b pb-8">
          <h2 className="text-lg font-semibold mb-4">Select Time Attachment</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date
              </label>
              <input
                type="date"
                {...register("exactDate", { required: "Date is required" })}
                className={`w-full border ${
                  errors.exactDate ? "border-red-500" : "border-gray-200"
                } rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
                min={new Date().toISOString().split("T")[0]}
              />
              {errors.exactDate && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.exactDate.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Time
              </label>
              <input
                type="time"
                {...register("exactTime", { required: "Time is required" })}
                className={`w-full border ${
                  errors.exactTime ? "border-red-500" : "border-gray-200"
                } rounded-md p-2.5 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
              />
              {errors.exactTime && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.exactTime.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Select your writer's level
        </h1>
        <p className="text-gray-600 mb-8">
          We'll match you with one of our 1,366 writers specializing in your
          subject. You'll be able to chat with them and compare prices before
          hiring the one you like.
        </p>

        <div className="space-y-4">
          {/* Writer Level Options */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "diamond"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Diamond className="w-8 h-8 text-[#2563EB]" />
                <div>
                  <h3 className="text-xl font-semibold mb-1">Diamond</h3>
                  <p className="text-gray-700">
                    Hire one of our <span className="font-bold">TOP 20</span>{" "}
                    highest-rated writers in your subject.
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Due to high demand, these writers cost an extra 20%.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel", "diamond")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "diamond"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Select
              </button>
            </div>
          </div>

          {/* Gold Writer Option */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "gold"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Crown className="w-8 h-8 text-yellow-500" />
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="text-xl font-semibold">Gold</h3>
                    <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded">
                      Popular
                    </span>
                  </div>
                  <p className="text-gray-700">
                    Hire one of our <span className="font-bold">TOP 50</span>{" "}
                    highest-rated writers in your subject.
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Due to high demand, these writers cost an extra 10%.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel", "gold")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "gold"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Select
              </button>
            </div>
          </div>

          {/* All Writers Option */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "all"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Users className="w-8 h-8 text-gray-600" />
                <div>
                  <h3 className="text-xl font-semibold mb-1">All writers</h3>
                  <p className="text-gray-700">
                    You'll see the list of all available writers specializing in
                    your subject.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel", "all")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "all"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Selected
              </button>
            </div>
          </div>

          {/* Hand-picked Writer Toggle */}
          <div className="border rounded-lg p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200">
                  <input
                    type="checkbox"
                    className="peer sr-only"
                    {...register("handPickedWriter")}
                    id="handPicked"
                  />
                  <span
                    className={`inline-block h-5 w-5 transform rounded-full bg-white transition ${
                      watch("handPickedWriter")
                        ? "translate-x-6"
                        : "translate-x-1"
                    }`}
                  />
                </div>
                <label htmlFor="handPicked" className="text-gray-700">
                  Let us hand-pick the best writer for you. It'll cost an extra{" "}
                  <span className="font-semibold">$9.99</span>.
                </label>
              </div>
            </div>
          </div>

          <div className="border-l-4 border-[#2563EB] pl-4 mt-6">
            <p className="text-gray-600 text-sm">
              All our professionals have the necessary qualifications and
              experience, so they always follow instructions and meet deadlines.
            </p>
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300"
          >
            Continue to Payment
          </button>
        </div>
      </form>
    </div>
  );

  const OrderSummary = ({ watch }) => {
    const watchedValues = watch();

    return (
      <div className="bg-white p-6 rounded-lg shadow-lg sticky top-4">
        <div className="mb-4">
          <div className="flex items-center gap-2 mb-2">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
              />
            </svg>
            <span className="text-sm text-gray-600">Have A Coupon code?</span>
            <button className="text-sm text-[#2563EB] hover:text-[#1d4ed8]">
              Offer List
            </button>
          </div>
          <div className="flex gap-2">
            <input
              type="text"
              placeholder="Enter Your Code"
              className="flex-1 border border-gray-200 rounded-md p-2 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button
              className="text-sm text-[#FF5C5C] font-medium hover:text-[#ff4444]"
              onClick={handleApplyCoupon}
            >
              Apply
            </button>
          </div>
          {couponError && (
            <p className="text-red-500 text-sm mt-1">{couponError}</p>
          )}
          {couponApplied && (
            <p className="text-green-500 text-sm mt-1">
              Coupon applied successfully!
            </p>
          )}
        </div>

        <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
        <div className="space-y-2 mb-4">
          <div className="text-[#2563EB] font-medium bg-blue-50 p-2 rounded-md mb-2">
            {watchedValues.service}
          </div>
          <div className="text-sm text-gray-600">
            {watchedValues.subject || "Not selected"}
          </div>
          <div className="text-sm text-gray-600">{watchedValues.pages}</div>
          <div className="text-sm text-gray-600">
            {watchedValues.exactDate && watchedValues.exactTime
              ? `${watchedValues.exactDate} ${watchedValues.exactTime}`
              : watchedValues.urgency}
          </div>
          {currentStep === 2 && watchedValues.writerLevel !== "all" && (
            <div className="text-sm text-gray-600">
              <span className="font-medium capitalize">
                {watchedValues.writerLevel}
              </span>
            </div>
          )}
        </div>

        <div className="space-y-3 border-t pt-4">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Basic Price</span>
            <span>GBP 22.28</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Plagiarism Report</span>
            <span className="text-green-500">FREE</span>
          </div>
          {watchedValues.writerLevel === "diamond" && (
            <div className="flex justify-between text-sm items-center">
              <span className="text-gray-600">Diamond Writer (+20%)</span>
              <span className="text-[#2563EB] font-medium">GBP 4.46</span>
            </div>
          )}
          {watchedValues.writerLevel === "gold" && (
            <div className="flex justify-between text-sm items-center">
              <span className="text-gray-600">Gold Writer (+10%)</span>
              <span className="text-[#2563EB] font-medium">GBP 2.23</span>
            </div>
          )}
          {watchedValues.handPickedWriter && (
            <div className="flex justify-between text-sm items-center">
              <span className="text-gray-600">Hand-picked Writer</span>
              <span className="text-[#2563EB] font-medium">GBP 7.99</span>
            </div>
          )}
          <div className="flex justify-between text-sm items-center">
            <span className="text-gray-600">Discount</span>
            <div className="flex items-center gap-2">
              <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-0.5 rounded">
                25% OFF
              </span>
              <span className="text-green-500">GBP 5.58</span>
            </div>
          </div>
          <div className="flex justify-between font-semibold pt-2 border-t">
            <span>Total Amount</span>
            <span>GBP 16.71</span>
          </div>
        </div>

        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Payment & Security
          </h3>
          <div className="grid grid-cols-3 gap-3">
            {[
              { name: "PayPal" },
              { name: "Visa" },
              { name: "MasterCard" },
              { name: "Maestro" },
              { name: "Bank Transfer" },
              { name: "American Express" },
            ].map((method) => (
              <div
                key={method.name}
                className="bg-gray-50 p-2 rounded flex items-center justify-center gap-2"
              >
                <span className="text-xs text-gray-600">{method.name}</span>
              </div>
            ))}
          </div>
        </div>
        {currentStep === 1 ? (
          <button
            type="button"
            onClick={() => setCurrentStep(2)}
            className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-4"
          >
            Continue to Select Writer
          </button>
        ) : (
          <button className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-4">
            Proceed to Payment
          </button>
        )}

        <div className="mt-4 space-y-2">
          <div className="flex items-center gap-2 text-xs text-gray-600">
            <CheckCircle2 className="w-4 h-4 text-green-500" />
            <span>Secure SSL Encryption</span>
          </div>
          <div className="flex items-center gap-2 text-xs text-gray-600">
            <CheckCircle2 className="w-4 h-4 text-green-500" />
            <span>Money Back Guarantee</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <ClientHeader />
      <div className="min-h-screen bg-gray-50 py-10 mt-14">
        {/* Seasonal Offer Banner */}
        <div className="max-w-6xl mx-auto mb-6 px-4">
          <div className="bg-gradient-to-r from-[#2563EB] to-[#22D3EE] p-4 rounded-lg relative overflow-hidden">
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-10">
              {Array(20)
                .fill(0)
                .map((_, i) => (
                  <div
                    key={i}
                    className="absolute text-blue-500 font-bold text-xl transform -rotate-45"
                    style={{ left: `${i * 5}%`, top: `${(i % 4) * 25}%` }}
                  >
                    %
                  </div>
                ))}
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-between gap-4 relative z-10">
              <div className="flex items-center gap-4">
                <div className="bg-white text-[#2563EB] px-3 py-1 rounded shadow-md transform -rotate-2">
                  <span className="font-bold text-sm">SEASONAL</span>
                  <br />
                  <span className="text-sm">OFFER</span>
                </div>
                <div className="flex items-baseline gap-1 text-white">
                  <span className="text-sm">UPTO</span>
                  <span className="text-4xl font-bold">56%</span>
                  <span className="text-4xl font-bold">OFF</span>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="border-2 border-dashed border-white/50 px-4 py-2 rounded bg-white/10 text-white">
                  <span className="text-sm">USE CODE : EXTRA25</span>
                </div>
                <button className="bg-white text-[#2563EB] px-6 py-2 rounded hover:bg-opacity-90 transition-colors">
                  Claim Now
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Progress Steps */}
        <div className="max-w-6xl mx-auto mb-8 px-4">
          <ProgressSteps />
        </div>

        <div className="max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Main Form Area */}
            <div className="lg:col-span-2">
              <div className="bg-white rounded-lg shadow-sm p-6">
                {currentStep === 1 ? <FormFields /> : <WriterSelectionStep />}
              </div>
            </div>

            {/* Order Summary */}
            <div className="lg:col-span-1">
              <OrderSummary watch={watch} />
            </div>
          </div>

          {/* Benefits Section */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">
              With Each Order, You Get:
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                "Money-Refund Policy if the work is found unsatisfactory",
                "On-time delivery of the assignment",
                "Free Plagiarism Report to prove the authenticity of content",
                "24*7 Customer Assistance toresolve queries",
                "100% Ownership to the clients",
                "Privacy of confidential information",
                "Free Unlimited Revisions if we do not match your expectations",
                "Exclusive offers and discounts",
              ].map((benefit) => (
                <div key={benefit} className="flex items-start gap-2">
                  <svg
                    className="w-5 h-5 text-green-500 mt-0.5 flex-shrink-0"
                    fill="none"
                    // stroke="currentshrink-0"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span className="text-sm text-gray-600">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
