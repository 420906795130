import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { getReportsAction } from "../../services/action/common";
import { ReportCardRevenue } from "../utils/ProjectsStatsData";
import DashboardLayout from "../layouts/DashboardLayout";
import SelectDate from "./components/SelectDate";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const RevenueReport = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { reports } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        getReportsAction({
          userType: user?.userType?.toLowerCase(),
          data: { reportType: "REVENUES" },
        })
      );
    }
  }, [user, dispatch]);

  const formattedReports = reports?.reports?.map((report) => ({
    ...report,
    balanceAmount: Math.abs(report.balanceAmount)  // Make balanceAmount absolute
  }));

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(value);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-100">
          <p className="font-semibold text-gray-800 mb-3 text-lg">{label}</p>
          <div className="space-y-2">
            {payload.map((entry, index) => (
              <div key={index} className="flex items-center justify-between gap-6">
                <span className="text-gray-600 flex items-center text-sm">
                  <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: entry.color }} />
                  {entry.name}
                </span>
                <span className="font-semibold text-gray-900">{formatCurrency(entry.value)}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => (
    <div className="flex flex-wrap justify-center gap-8 mt-6">
      {payload.map((entry, index) => (
        <div key={index} className="flex items-center gap-2 px-3 py-2 bg-gray-50 rounded-full">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: entry.color }} />
          <span className="text-sm font-medium text-gray-700">{entry.value}</span>
        </div>
      ))}
    </div>
  );

  return (
    <DashboardLayout>
      <div className="bg-[#00234a] pt-10 pb-24 min-h-[200px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-2xl sm:text-3xl font-bold text-white">Revenue Reports</h1>
            <SelectDate reportType="REVENUES" isShowCurrencyBtn={true} />
          </motion.div>
        </div>
      </div>

      <motion.div 
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-16"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {ReportCardRevenue(reports?.current).map((item, index) => (
            <motion.div key={index} variants={itemVariants} className="h-full">
              <div className="bg-white rounded-xl shadow-lg p-6 h-full border border-gray-100 hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-start justify-between h-full">
                  <div className="space-y-2">
                    <p className="text-sm font-medium text-gray-500">{item.title}</p>
                    <p className="text-xl font-bold text-gray-900">{item.value}</p>
                    <p className={`text-sm mt-auto ${item.percentageColor}`}>
                      {item.percentage} {item.percentageText}
                    </p>
                  </div>
                  <div className={`p-3 rounded-xl ${item.iconColorClass} bg-opacity-10`}>
                    {item.icon}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="mt-8 bg-white rounded-xl shadow-lg p-6 border border-gray-100"
          variants={itemVariants} 
        >    
          {formattedReports?.length && (
            <div className="w-full h-[400px] sm:h-[500px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={formattedReports}  // Use formatted data here
                  margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                  barGap={12}
                  barSize={24}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    vertical={false} 
                    stroke="#f0f0f0" 
                    opacity={0.5}
                  />
                  <XAxis
                    dataKey="month"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#666', fontSize: 12 }}
                    dy={10}
                    height={60}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#666', fontSize: 12 }}
                    tickFormatter={formatCurrency}
                    width={80}
                  />
                  <Tooltip 
                    content={<CustomTooltip />} 
                    cursor={{ fill: 'rgba(0, 0, 0, 0.04)' }}
                    wrapperStyle={{ outline: 'none' }}
                  />
                  <Legend content={<CustomLegend />} />
                  <Bar 
                    dataKey="totalOrderAmount" 
                    name="Order Amount" 
                    fill="#00bfa5" 
                    radius={[6, 6, 0, 0]} 
                  />
                  <Bar 
                    dataKey="paymentReceived" 
                    name="Payment Received" 
                    fill="#2196f3" 
                    radius={[6, 6, 0, 0]} 
                  />
                  <Bar 
                    dataKey="balanceAmount" 
                    name="Balance Amount" 
                    fill="#e91e63" 
                    radius={[6, 6, 0, 0]} 
                  />
                  <Bar 
                    dataKey="expertAmount" 
                    name="Expert Amount" 
                    fill="#673ab7" 
                    radius={[6, 6, 0, 0]} 
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </motion.div>
      </motion.div>
    </DashboardLayout>
  );
};

export default RevenueReport;
