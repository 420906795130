import React, { useState } from "react";
import DeleteConfirmation from "./utils/dialoge/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserAcountAction } from "../services/action/common";
import { logoutAction } from "../services/action/authentication";
import DashboardLayout from "./layouts/DashboardLayout";

const DeleteAccountButton = () => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { user } = useSelector((state) => state.authenticationReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <DashboardLayout>
      <DeleteConfirmation
        title="Are you sure do you want to delete your account?"
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={() => {
          dispatch(
            deleteUserAcountAction({
              userType: user?.userType?.toLowerCase(),
              cb: () => {
                dispatch(logoutAction());
                navigate("/client/account-delete-success");
              },
            })
          );
        }}
      />
      {user?.userType === "CLIENT" && (
        <button
          className="btn btn-danger m-3"
          onClick={() => {
            setDeleteConfirmation(true);
          }}
        >
          Delete your account
        </button>
      )}
    </DashboardLayout>
  );
};

export default DeleteAccountButton;
