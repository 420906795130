import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  MoreVertical,
  RefreshCcw,
  Search,
  MessageCircle,
  AlertCircle,
  CheckCircle2,
  Clock,
} from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterButton from "../components/FilterButton";
import ChatItem from "../components/ChatItem";
import { ActionModal } from "../components/ActionModal";
import { useSelector } from "react-redux";

const DesktopLeftSidebar = ({
  onClickRow,
  chatList,
  loadMoreChats,
  hasMoreData = true,
  getAllLiveUser,
  setFilter,
  setPageData,
  filter,
  setDetailReport
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const { chatHeaderInfo, allLiveUser } = useSelector(
    (state) => state.commonReducer
  );

  const refresh = () => {
    window.location.href = window.location.href;
  };
  return (
    <div className="hidden md:flex w-[340px] border-r border-gray-700/50 flex-col">
      <div className="p-4 border-b border-gray-700/50">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <span className="text-lg font-semibold" onClick={()=>setDetailReport(true)}>Detail Report</span>
            {/* <span className="bg-gray-700/50 px-2.5 py-0.5 rounded-full text-sm font-medium">
              {chatStats.all}
            </span> */}
          </div>
          <div className="flex items-center space-x-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg"
              onClick={() => refresh()}
            >
              <RefreshCcw size={18} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 hover:bg-gray-700/50 rounded-lg relative"
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                setModalPosition({ x: rect.left, y: rect.top });
                setIsModalOpen(true);
              }}
            >
              <MoreVertical size={18} />
            </motion.button>
          </div>
        </div>
        <div className="relative mb-4">
          <input
            value={filter.searchQuery}
            onChange={(e) => {
              getAllLiveUser({
                firstTime: true,
                page: 1,
                tempSearchQuery: e.target.value,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({ ...prev, searchQuery: e.target.value }));
            }}
            type="text"
            placeholder="Search chats..."
            className="w-full bg-[#1a2d4a] rounded-2xl py-3 px-8 pl-12 focus:outline-none focus:ring-1 focus:ring-blue-500/50 text-sm placeholder:text-gray-400/70 transition-all"
          />
          <Search
            className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400/70 pointer-events-none"
            size={18}
          />
        </div>
        <div className="grid grid-cols-2 gap-3">
          <FilterButton
            filter="all"
            icon={MessageCircle}
            label="Total"
            count={chatHeaderInfo?.total}
            activeFilter={filter?.status === "ALL"}
            onClick={() => {
              let newValue = filter?.status === "ALL" ? "" : "ALL";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={AlertCircle}
            label="Missed"
            count={chatHeaderInfo?.missed || 0}
            activeFilter={filter?.status === "MISSED"}
            onClick={() => {
              let newValue = filter?.status === "MISSED" ? "" : "MISSED";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={CheckCircle2}
            label="Inprogress"
            count={chatHeaderInfo?.inProgress || 0}
            activeFilter={filter?.status === "INPROGRESS"}
            onClick={() => {
              let newValue =
                filter?.status === "INPROGRESS" ? "" : "INPROGRESS";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={Clock}
            label="Online"
            count={chatHeaderInfo?.onlineUsers}
            activeFilter={filter?.status === "ISONLINE"}
            onClick={() => {
              let newValue = filter?.status === "ISONLINE" ? "" : "ISONLINE";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          {(filter?.website !== "all website" ||
            filter?.from ||
            filter?.to) && (
            <FilterButton
              icon={Clock}
              label="Filter"
              count={allLiveUser?.paginations?.totalFilterCount}
              activeFilter={true}
              onClick={() => {
                getAllLiveUser({
                  firstTime: true,
                  page: 1,
                  tempWebsite: "all website",
                  to: "",
                  from: "",
                });
                setPageData((prev) => ({ ...prev, page: 1 }));
                setFilter((prev) => ({
                  ...prev,
                  website: "all website",
                  to: "",
                  from: "",
                }));
              }}
            />
          )}
          {/* foreign country */}

          <FilterButton
            icon={Clock}
            label="Initiated"
            count={chatHeaderInfo?.intitiated}
            activeFilter={filter?.status === "INITIATED"}
            onClick={() => {
              let newValue = filter?.status === "INITIATED" ? "" : "INITIATED";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
          <FilterButton
            icon={Clock}
            label="Foreign"
            count={chatHeaderInfo?.foregnData}
            activeFilter={filter?.status === "FOREIGN"}
            onClick={() => {
              let newValue = filter?.status === "FOREIGN" ? "" : "FOREIGN";
              getAllLiveUser({
                firstTime: true,
                page: 1,
                status: newValue,
              });
              setPageData((prev) => ({ ...prev, page: 1 }));
              setFilter((prev) => ({
                ...prev,
                status: newValue,
              }));
            }}
          />
        </div>
      </div>
      <div
        id="scrollableDiv"
        className="overflow-y-auto flex-1 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
        style={{ height: "calc(100vh - 280px)" }}
      >
        {/* {console.log("chatList",chatList)} */}
        <InfiniteScroll
          dataLength={chatList.length}
          next={loadMoreChats}
          hasMore={hasMoreData}
          scrollableTarget="scrollableDiv"
        >
          {chatList.map((chat, index) => (
            <ChatItem
              onClickRow={onClickRow}
              currentIndex={index}
              key={chat?._id}
              chat={chat}
            />
          ))}
        </InfiniteScroll>
      </div>
      <ActionModal
        filter={filter}
        setFilter={setFilter}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        buttonPosition={modalPosition}
        getAllLiveUser={getAllLiveUser}
      />
    </div>
  );
};

export default DesktopLeftSidebar;
