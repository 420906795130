// DeleteConfirmation.jsx
// material ui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
  } from '@mui/material';
  import { Close } from '@mui/icons-material';
import Icons from '../Icons';
  
  const DeleteConfirmation = ({isDeleteLoading,open,onClose,onConfirm,title="Are you sure, do you want to delete?"}) => {
    return (
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>{title}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button color="error" variant="contained" onClick={onConfirm}>
             {isDeleteLoading ?<Icons title={"loader"} color='#fff' size={18}/>:"Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default DeleteConfirmation;