import React, { useState, useMemo, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, UserCheck, UserCog, Briefcase, User, ChevronDown, Search, X, Star, ShoppingCart, Calendar, Wallet, Clock, DollarSign, AlertCircle, ChevronUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashboardLayout from '../layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsAction, getUserReportListAction } from '../../services/action/common';
import UserReportList from './components/UserReportList';
import CustomModal from '../utils/modals/CustomModal';

const UsersReport = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedExpert, setExpandedExpert] = useState(null);
  const {user}=useSelector((state)=>state.authenticationReducer)
  const { reports } = useSelector((state) => state.commonReducer);

  const [pageData, setPageData] = useState({
    page: 1,
  });
  const [search, setSearch] = useState("");
  const metrics =(currentData)=>{
    return [
    { id: 'clients', title: 'Clients', value: currentData?.clients, icon: Users, color: 'bg-blue-500' },
    { id: 'operators_sales', title: 'Operators (Sales)', value: currentData?.operatorSales, icon: UserCheck, color: 'bg-green-500' },
    { id: 'operators_operations', title: 'Operators (Operations)', value: currentData?.operatorOperations, icon: UserCog, color: 'bg-yellow-500' },
    { id: 'operators_accounts', title: 'Operators (Accounts)', value: currentData?.operatorAccounts, icon: Briefcase, color: 'bg-purple-500' },
    { id: 'experts_internal', title: 'Experts (Internal)', value: currentData?.expertInternal, icon: User, color: 'bg-red-500' },
    { id: 'experts_external', title: 'Experts (External)', value: currentData?.expertExternal, icon: User, color: 'bg-indigo-500' },
  ];
}

  const months = [
    "All",
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const clients = [
    {
      name: 'Steve smith',
      revenue: 1000,
      orders: 25,
      joinedDate: '2023-01-19',
      wallet: 500,
      rating: 5.0
    },
    {
      name: 'David warner',
      revenue: 25000.45,
      orders: 38,
      joinedDate: '2023-12-24',
      wallet: 35.57,
      rating: 4.8
    },
    {
      name: 'Travis Head',
      revenue: 13000,
      orders: 38,
      joinedDate: '2024-01-02',
      wallet: 35.57,
      rating: 4.9
    },
    {
      name: 'Pat Cummins',
      revenue: 34000,
      orders: 324,
      joinedDate: '2022-09-04',
      wallet: 35.57,
      rating: 4.7
    },
  ];

  const operators = [
    {
      name: 'Alice Johnson',
      rating: 4.7,
      orders: 150,
      lastActive: '2023-06-15T14:30:00',
      type: 'sales'
    },
    {
      name: 'Bob Smith',
      rating: 4.9,
      orders: 200,
      lastActive: '2023-06-15T15:45:00',
      type: 'operations'
    },
    {
      name: 'Charlie Brown',
      rating: 4.8,
      orders: 180,
      lastActive: '2023-06-15T13:15:00',
      type: 'accounts'
    },
  ];

  const experts = [
    {
      name: 'Satendra Sahani',
      ordersTaken: 69,
      expertAmount: 3200,
      revenueGenerated: 22500,
      missedOrders: [
        { id: 'ORD001', reason: 'Wife pregnancy' },
        { id: 'ORD002', reason: 'Communication gap' }
      ],
      lastActive: '2023-06-15T14:30:00',
      rating: 4.9,
      type: 'internal'
    },
    {
      name: 'Prof. Ashutosh Yadav',
      ordersTaken: 60,
      expertAmount: 12000,
      revenueGenerated: 180000,
      missedOrders: [
        { id: 'ORD003', reason: 'Personal emergency' }
      ],
      lastActive: '2023-06-15T16:45:00',
      rating: 5.0,
      type: 'external'
    },
    {
      name: 'Rishabh',
      ordersTaken: 60,
      expertAmount: 12000,
      revenueGenerated: 24000,
      missedOrders: [
        { id: 'ORD003', reason: 'Personal emergency' }
      ],
      lastActive: '2023-06-11T12:32:00',
      rating: 5.0,
      type: 'external'
    },
    {
      name: 'Noor',
      ordersTaken: 80,
      expertAmount: 20000,
      revenueGenerated: 46000,
      missedOrders: [
        { id: 'ORD003', reason: 'Personal emergency' }
      ],
      lastActive: '2023-06-09T11:12:00',
      rating: 4.9,
      type: 'external'
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const filteredMetrics = useMemo(() => {
    return metrics(reports?.current)?.filter(metric =>
      metric.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm,reports]);

  const toggleExpand = (index) => {
    setExpandedExpert(expandedExpert === index ? null : index);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        getReportsAction({
          userType: user?.userType?.toLowerCase(),
          data: {
            reportType: "USERS",
            selectedMonth: selectedMonth == "All" ? "" : selectedMonth,
          },
        })
      );
    }
  }, [dispatch, user, selectedMonth]);

  const ClientModal = () => (
    <div className="space-y-6">
      {clients.map((client, index) => (
        <div key={index} className="bg-[#0A2547] p-6 rounded-lg shadow-md">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold text-white">{client.name}</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center gap-3">
              <DollarSign className="h-5 w-5 text-blue-400" />
              <div>
                <span className="block text-gray-400">Revenue Generated</span>
                <span className="font-semibold text-white">${client.revenue}</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <ShoppingCart className="h-5 w-5 text-green-400" />
              <div>
                <span className="block text-gray-400">Total Orders</span>
                <span className="font-semibold text-white">{client.orders}</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Calendar className="h-5 w-5 text-yellow-400" />
              <div>
                <span className="block text-gray-400">Joined Date</span>
                <span className="font-semibold text-white">{client.joinedDate}</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Wallet className="h-5 w-5 text-purple-400" />
              <div>
                <span className="block text-gray-400">Wallet Balance</span>
                <span className="font-semibold text-white">${client.wallet}</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Star className="h-5 w-5 text-orange-400" />
              <div>
                <span className="block text-gray-400">Expert Rating</span>
                <span className="font-semibold text-white">{client.rating}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const OperatorModal = ({ type }) => {
    const filteredOperators = operators.filter(op => op.type === type);

    return (
      <div className="space-y-6">
        {filteredOperators.map((operator, index) => (
          <div key={index} className="bg-[#0A2547] p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-white">{operator.name}</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-center gap-3">
                <Star className="h-5 w-5 text-yellow-400" />
                <div>
                  <span className="block text-gray-400">Rating</span>
                  <span className="font-semibold text-white">{operator.rating}</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <ShoppingCart className="h-5 w-5 text-green-400" />
                <div>
                  <span className="block text-gray-400">Orders</span>
                  <span className="font-semibold text-white">{operator.orders}</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Clock className="h-5 w-5 text-blue-400" />
                <div>
                  <span className="block text-gray-400">Last Active</span>
                  <span className="font-semibold text-white">
                    {new Date(operator.lastActive).toLocaleString()}
                  </span>
                  <span className={`ml-2 inline-block w-2 h-2 rounded-full ${
                    new Date().getTime() - new Date(operator.lastActive).getTime() < 3600000 ? 'bg-green-500' : 'bg-red-500'
                  }`}></span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const ExpertModal = ({ type }) => {
    const filteredExperts = experts.filter(exp => exp.type === type);

    return (
      <div className="space-y-6">
        {filteredExperts.map((expert, index) => (
          <div key={index} className="bg-[#0A2547] p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-white">{expert.name}</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div className="flex items-center gap-3">
                <ShoppingCart className="h-5 w-5 text-blue-400" />
                <div>
                  <span className="block text-gray-400">Orders Taken</span>
                  <span className="font-semibold text-white">{expert.ordersTaken}</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <DollarSign className="h-5 w-5 text-green-400" />
                <div>
                  <span className="block text-gray-400">Expert Amount</span>
                  <span className="font-semibold text-white">${expert.expertAmount}</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <DollarSign className="h-5 w-5 text-purple-400" />
                <div>
                  <span className="block text-gray-400">Revenue Generated</span>
                  <span className="font-semibold text-white">${expert.revenueGenerated}</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-center gap-3">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <div>
                  <span className="block text-gray-400">Missed Orders</span>
                  <button
                    onClick={() => toggleExpand(index)}
                    className="font-semibold text-white flex items-center gap-1"
                  >
                    {expert.missedOrders.length}
                    {expandedExpert === index ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Clock className="h-5 w-5 text-yellow-400" />
                <div>
                  <span className="block text-gray-400">Last Active</span>
                  <span className="font-semibold text-white">
                    {new Date(expert.lastActive).toLocaleString()}
                  </span>
                  <span className={`ml-2 inline-block w-2 h-2 rounded-full ${
                    new Date().getTime() - new Date(expert.lastActive).getTime() < 3600000 ? 'bg-green-500' : 'bg-red-500'
                  }`}></span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Star className="h-5 w-5 text-orange-400" />
                <div>
                  <span className="block text-gray-400">Rating</span>
                  <span className="font-semibold text-white">{expert.rating}</span>
                </div>
              </div>
            </div>
            {expandedExpert === index && (
              <div className="mt-4 p-4 rounded-lg bg-[#051630]">
                <h4 className="font-semibold mb-2 text-white">Missed Orders</h4>
                <ul className="space-y-2">
                  {expert.missedOrders.map((order, orderIndex) => (
                    <li key={orderIndex} className="text-gray-300">
                      <span className="font-medium">{order.id}</span>: {order.reason}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const getUserReportList = ({
    userType = selectedMetric,
    specificMonth = selectedMonth,
    page = pageData?.page,
    firstTime,
    tempSearch = search,
  }) => {
    const payload = {
      page,
      search: tempSearch,
      specificMonth: specificMonth === "All" ? "" : specificMonth,
      userType
    };
    dispatch(
      getUserReportListAction({
        userType: user?.userType,
        data: payload,
        firstTime: firstTime,
      })
    );
  };

  return (
    <DashboardLayout>
    <div className="min-h-screen bg-[#001B3D] p-6">
      <div className="max-w-7xl mx-auto">
        <header className="mb-4">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">
                Orderwise User Reports
              </h1>
              <p className="text-blue-300">
                Your user management dashboard
              </p>
            </div>
            <div className="relative">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="appearance-none bg-[#0A2547] text-white py-2 px-4 pr-8 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {months.map((month) => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
              <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
          </div>
        </header>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {filteredMetrics.map((metric) => (
            <motion.div 
              key={metric.id}
              className="bg-[#0A2547] rounded-xl shadow-lg overflow-hidden hover:bg-[#0F2F5B] transition-all duration-300 cursor-pointer"
              variants={itemVariants}
              onClick={() => {
                getUserReportList({
                  userType: metric.id,
                  firstTime: true,
                  page: 1,
                });
                setSelectedMetric(metric.id);
                setPageData({ page: 1 });
              }}
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-lg font-semibold text-white">
                    {metric.title}
                  </span>
                  <div className={`p-2 rounded-full ${metric.color} text-white`}>
                    <metric.icon className="h-6 w-6" />
                  </div>
                </div>
                <p className="text-3xl font-bold text-white">
                  {metric.value}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <div className="bg-[#0A2547] rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">
            User Growth This Year
          </h2>
          <div className="h-[300px]">
           {selectedMonth==="All" && <ResponsiveContainer width="100%" height="100%">
              <LineChart data={reports?.graphData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                <XAxis dataKey="month" stroke="#8295AF" />
                <YAxis stroke="#8295AF" />
                <Tooltip contentStyle={{ backgroundColor: '#0A2547', border: 'none' }} />
                <Line type="monotone" dataKey="clients" stroke="#3B82F6" strokeWidth={2} dot={{ fill: '#3B82F6' }} />
                <Line type="monotone" dataKey="operators" stroke="#10B981" strokeWidth={2} dot={{ fill: '#10B981' }} />
                <Line type="monotone" dataKey="experts" stroke="#F59E0B" strokeWidth={2} dot={{ fill: '#F59E0B' }} />
              </LineChart>
            </ResponsiveContainer>}
            {
              selectedMonth!=="All" &&(
                <ResponsiveContainer width="100%" height="100%">
                <LineChart data={reports?.graphData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                  <XAxis dataKey="day" stroke="#8295AF" />
                  <YAxis stroke="#8295AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#0A2547",
                      border: "none",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="clients"
                    name='Clients'
                    stroke="#3B82F6"
                    strokeWidth={2}
                    dot={{ fill: "#3B82F6" }}
                  />
                   <Line
                      type="monotone"
                      dataKey="experts"
                      name="Experts"
                      stroke="#10B981"
                      strokeWidth={2}
                      dot={{ fill: "#10B981" }}
                    />
                   <Line
                      type="monotone"
                      dataKey="operators"
                      name="Operators"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={{ fill: "#3B82F6" }}
                    />
                </LineChart>
              </ResponsiveContainer>
              )
            }
          </div>
        </div>


        <UserReportList
            metrics={metrics}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            setPageData={setPageData}
            fetchOrderList={getUserReportList}
            setSearch={setSearch}
            pageData={pageData}
            search={search}
          />
      </div>
    </div>
    </DashboardLayout>
  );
};

export default UsersReport;

