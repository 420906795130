import React from "react"
import {X } from 'lucide-react'
import { returnDateWithTime } from "../../../components/utils/functions/functions";

const ProfileViewsModal = ({ leads,isOpen, onClose }) => {
    if (!isOpen) return null;
    
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" onClick={onClose}>
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4" onClick={e => e.stopPropagation()}>
          <div className="flex items-center justify-between px-6 py-4 border-b">
            <h3 className="text-lg font-medium">Profile Views</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>
          <div className="px-6 py-4">
            <div className="divide-y">
              {leads?.seenBy?.map(view => (
                <div key={view.id} className="py-3 first:pt-0 last:pb-0">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{view?.user?.name}</p>
                      <p className="text-xs text-gray-500">{view?.user?.userType}</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      {returnDateWithTime(view?.seenTime)} <br/>
                      <span>Seen Count : {view?.seenCount}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
export default ProfileViewsModal;