import { useCallback, useState } from "react";
import EditorSection from "../components/EditorSection";
import { Edit2 } from "lucide-react";
import Buttons from "../../../components/utils/buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { updateAndroidDesignAction } from "../../../services/action/common";
const DashboardEditor = ({ setEditorData, editorData }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { isUpdateDesign } = useSelector((state) => state.commonReducer);
  const [activeSection, setActiveSection] = useState("welcome");
  const dispatch = useDispatch();
  const updateData = useCallback((section, index, key, value) => {
    setEditorData((prevData) => {
      const newData = { ...prevData };
      if (Array.isArray(newData[section])) {
        newData[section] = newData[section].map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        );
      } else {
        newData[section] = { ...newData[section], [key]: value };
      }
      return newData;
    });
  }, []);

  const onUpdate = () => {
    const payload = {
      data: {...editorData,screenName:"clientDashboard"},
      userType: user?.userType?.toLowerCase(),
      
    };
    dispatch(updateAndroidDesignAction(payload));
  };
  return (
    <div className="w-1/2 p-6 overflow-y-auto">
      <h1 className="text-2xl font-bold mb-6">
        Android App Editor{" "}
        <Buttons
          isLoading={isUpdateDesign}
          onClick={onUpdate}
          size="small"
          title={"Save Changes"}
        />
      </h1>

      <EditorSection
        title="Premium Services"
        isActive={activeSection === "premiumServices"}
        onToggle={() =>
          setActiveSection(
            activeSection === "premiumServices" ? "" : "premiumServices"
          )
        }
      >
        {editorData?.premiumServices?.map((service, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Premium Service {index + 1}</h3>
              <button className="text-blue-500 hover:text-blue-700">
                <Edit2 className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-2">
              <input
                type="text"
                value={service?.image}
                placeholder="Enter image url"
                onChange={(e) =>
                  updateData("premiumServices", index, "image", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        ))}
      </EditorSection>
    </div>
  );
};

export default DashboardEditor;
