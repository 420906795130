import { X } from "lucide-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLiveLeadDetailAction } from "../../../services/action/common";
const UserDetailsModal = ({ isOpen, onClose, leads }) => {
     const {liveLeadDetails}=useSelector((state)=>state.commonReducer);
     const {user}=useSelector((state)=>state.authenticationReducer);
     const dispatch=useDispatch();

     useEffect(()=>{
        if(leads?._id && isOpen && user){
            dispatch(getLiveLeadDetailAction({data:{leadId:leads?._id},userType:user?.userType?.toLowerCase()}))
        }
        },[leads,dispatch,isOpen,user])

    if (!isOpen) return null;


    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" onClick={onClose}>
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4" onClick={e => e.stopPropagation()}>
          <div className="flex items-center justify-between px-6 py-4 border-b">
            <h3 className="text-lg font-medium">User Details</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>
          <div className="px-6 py-4 space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-500">Name</label>
              <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userName || "NA"}</p>
            </div>
            <div>
              <label className="text-sm font-medium text-gray-500">Email</label>
              <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userEmail || "NA"}</p>
            </div>
            <div>
              <label className="text-sm font-medium text-gray-500">Phone</label>
              <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userPhone || "NA"}</p>
            </div>
            <div>
              <label className="text-sm font-medium text-gray-500">Address</label>
              <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userAddress || "NA"}</p>
            </div>
            {liveLeadDetails?.userMessage && (
              <div>
                <label className="text-sm font-medium text-gray-500">Message</label>
                <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userMessage || "NA"}</p>
              </div>
            )}
            {liveLeadDetails?.userCompany && (
              <div>
                <label className="text-sm font-medium text-gray-500">Company Name</label>
                <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userCompany || "NA"}</p>
              </div>
            )}
            {liveLeadDetails?.userDesignation && (
              <div>
                <label className="text-sm font-medium text-gray-500">Designation</label>
                <p className="mt-1 text-sm text-gray-900">{liveLeadDetails?.userDesignation || "NA"}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default UserDetailsModal;