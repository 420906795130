import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Bot, UserCircle2 } from 'lucide-react';
import { formatMessageTime } from '../../utils/functions/functions';

const MessageBubble = React.memo(({ message, currentData, user }) => {
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message]);

  const itsMe = message.sender === user?._id || (message.sender?.userType && message.sender.userType.toLowerCase() !== "client");

  const isSystemMessage = message.sender === 'System';
  const isAIAssistant = message.sender === 'AI Assistant';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isSystemMessage ? 'justify-center' : itsMe ? 'justify-end' : 'justify-start'}`}
    >
      {isSystemMessage ? (
        <div className="bg-gray-700/30 rounded-lg px-4 py-2 text-sm text-gray-300">
          {message.message}
        </div>
      ) : (
        <div className={`max-w-[70%] ${itsMe ? 'bg-blue-600/50' : isAIAssistant ? 'bg-purple-600/50' : 'bg-gray-700/50'} rounded-lg p-3`}>
          <div className="text-sm font-medium mb-1 flex items-center gap-2">
            {isAIAssistant ? <Bot size={14} /> : <UserCircle2 size={14} />}
            {message.sender?.name || currentData?.name || 'New Client'}
          </div>
          <div className="text-base whitespace-pre-line" dangerouslySetInnerHTML={{__html: message.message}}></div>
          <div className="text-xs text-gray-400 mt-1">{formatMessageTime(message.time)} ({message.sender?.userType?.toLowerCase() || user.userType.toLowerCase()})</div>
        </div>
      )}
      <div ref={messageEndRef} />
    </motion.div>
  );
}, (prevProps, nextProps) => {
  return prevProps.message === nextProps.message && prevProps.currentData === nextProps.currentData && prevProps.user === nextProps.user;
});

export default MessageBubble;
