import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { deleteUserActionManager, updateUserActionManager } from "../../../../services/action/manager";
import CustomModal from "../../../utils/modals/CustomModal";
import FormEditClient from "./components/FormEditClient";
import DeleteConfirmation from "../../../utils/dialoge/DeleteConfirmation";
import FullScreenModal from "../../../utils/modals/FullScreenModal";
import PersonalCard from "../../../card/PersonalCard";
import UpdateReferalAmount from "./components/UpdateReferalAmount";

const ClientListComponent = ({
  getList,
  TeamsData,
  listTitle = "Client List",
  listType = "CLIENT",
  setPageData,
  pageData,
}) => {
  const { isDeleteUser } = useSelector((state) => state.managerReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  const [operatorDetail, setOperatorDetail] = useState({
    modal: false,
    preview: false,
    edit: false,
    data: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [referalUser, setReferalUser] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const observer = useRef();

  const fetchMoreData = useCallback(() => {
    if (loading || TeamsData?.data.length >= TeamsData?.totalResults) return;
    setLoading(true);
  
    // If getList is synchronous or doesn't return a Promise
    try {
      getList({ currentPage: pageData.page + 1, firstTime: false });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [loading, TeamsData?.data.length, TeamsData?.totalResults, pageData.page, getList]);

  
  const lastClientElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && TeamsData?.data.length < TeamsData?.totalResults) {
          fetchMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, TeamsData?.data.length, TeamsData?.totalResults, fetchMoreData]
  );


  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        fetchMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMoreData]);

  const afterSave = () => {
    getList({ currentPage: 1, isEmptylist: false });
    setOperatorDetail({});
  };

  const deleteFinal = () => {
    const userType = user?.userType?.toLowerCase();
    dispatch(
      deleteUserActionManager({
        userType,
        data: deleteId,
        cb: () => {
          setDeleteId("");
          getList({ currentPage: 1, isEmptylist: false });
          setOperatorDetail({});
        },
      })
    );
  };

  const updateStatus = (data) => {
    const userType = user?.userType?.toLowerCase();
    dispatch(
      updateUserActionManager({
        userType,
        data: {
          _id: data?._id,
          status: data?.status === "ACTIVE" ? "NOT ACTIVE" : "ACTIVE",
        },
        cb: () => getList({ currentPage: 1, isEmptylist: false }),
      })
    );
  };

  return (
    <div className="min-h-screen bg-[#001b3d] text-gray-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="rounded-lg overflow-hidden bg-[#001b3d] "
      >
        <AnimatePresence>
          {deleteId && (
            <DeleteConfirmation
              open={deleteId}
              isDeleteLoading={isDeleteUser}
              onClose={() => setDeleteId("")}
              onConfirm={deleteFinal}
            />
          )}
        </AnimatePresence>

        <CustomModal
          title={listType === "MARKETING" ? "Add Marketing Team" : "Add Client"}
          open={operatorDetail.modal}
          onClose={() => setOperatorDetail({})}
          content={() => (
            <FormEditClient
              listType={listType}
              data={operatorDetail?.data}
              close={afterSave}
            />
          )}
        />

        <CustomModal
          content={() => (
            <UpdateReferalAmount
              getList={getList}
              setReferalUser={setReferalUser}
              referalUser={referalUser}
            />
          )}
          onClose={() => setReferalUser("")}
          open={referalUser}
        />

        <FullScreenModal
          title="Personal Details"
          open={operatorDetail.preview}
          onClose={() => setOperatorDetail({})}
          content={() => (
            <PersonalCard
              onDeleteClick={(data) => setDeleteId(data)}
              onEditClick={(data) =>
                setOperatorDetail({
                  edit: true,
                  modal: true,
                  preview: false,
                  data,
                })
              }
              data={operatorDetail?.data}
              close={() => setOperatorDetail({})}
            />
          )}
        />

        <div className="p-4 border-b border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
            <motion.h2
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-xl font-bold text-white"
            >
              {listTitle}
            </motion.h2>
            <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
              <div className="relative flex-grow md:flex-grow-0">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-3 py-1.5 bg-[#002952] border border-gray-700 rounded-md text-sm text-gray-100 placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-all duration-300"
                />
                <svg
                  className="absolute right-2 top-2 h-4 w-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setOperatorDetail({ modal: true, edit: false })}
                className="px-4 py-1.5 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm font-medium flex items-center justify-center gap-2 transition-colors duration-200"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                <span>
                  {listType === "MARKETING" ? "Add Marketing Team" : "Add Client"}
                </span>
              </motion.button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-[#002952]">
              <tr>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Sr. No
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Wallet
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Role
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Active
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {TeamsData?.data.map((client, index) => (
                <motion.tr
                  key={client._id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  ref={index === TeamsData.data.length - 1 ? lastClientElementRef : null}
                  className="bg-[#001b3d] hover:bg-[#002952] transition-colors duration-200"
                >
                  <td className="px-3 py-2 whitespace-nowrap">
                    <div className={`flex items-center gap-1.5 ${
                      client.isOnline ? 'text-green-400' : 'text-gray-400'
                    }`}>
                      <div className={`w-2 h-2 rounded-full ${
                        client.isOnline ? 'bg-green-400' : 'bg-gray-400'
                      }`} />
                      <span className="text-xs">
                        {client.isOnline ? "Online" : "Offline"}
                      </span>
                    </div>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-300">
                    {index + 1}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-300">
                    {client?.contactDetails?.length ? (
                      <span className="text-green-400">Saved</span>
                    ) : (
                      <span className="text-yellow-400">Not Saved</span>
                    )}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    <div className="w-8 h-8 rounded-full overflow-hidden border border-gray-700 transition-transform duration-300 hover:scale-110">
                      <img
                        src={client.image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    <button
                      onClick={() =>
                        setOperatorDetail({ data: client, preview: true, modal: false })
                      }
                      className="text-sm text-blue-400 hover:text-blue-300 font-medium transition-colors duration-200"
                    >
                      {client?.name || "NA"}
                    </button>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-300">
                    {client?.email || "NA"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    <button
                      onClick={() => setReferalUser(client)}
                      className="text-sm text-blue-400 hover:text-blue-300 font-medium transition-colors duration-200"
                    >
                      ${client?.wallet?.amount || 0}
                    </button>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-300">
                    {client?.userType || "NA"}
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={client.status === "ACTIVE"}
                        onChange={() => updateStatus(client)}
                      />
                      <div className="w-9 h-5 bg-gray-700 rounded-full peer peer-focus:ring-2 peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                </motion.tr>
              ))}
            </tbody>
            {loading && (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                </td>
              </tr>
            )}
          </table>
        </div>
      </motion.div>
    </div>
  );
};

export default ClientListComponent;

