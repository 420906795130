import React, { useEffect, useState } from "react";
import { returnDateWithTime, truncateString } from "../../../../utils/functions/functions";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateClientWalletAction } from "../../../../../services/action/common";

const UpdateReferalAmount = ({getList, referalUser,setReferalUser }) => {
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const dispatch=useDispatch();
  const {user}=useSelector((state)=>state.authenticationReducer)
  const [historyList,setHistoryList]=useState([]);

  useEffect(() => {
    if (referalUser) {
      setAmount(referalUser?.wallet?.amount);
    }
  }, [referalUser]);

  const handleUpdate = (e) => {
    e.preventDefault();
    // Add your update logic here
    const payload={
        userType:user?.userType?.toLowerCase(),
        data:{amount,message,userId:referalUser?._id},
        cb:()=>{
          const currentObjHistory={
            message,
            updatedBy:user,
            updatedAt:new Date()
          }
          setHistoryList([...historyList,currentObjHistory])
          getList({ currentPage: 1, isEmptylist: true });
        }
    }
    dispatch(updateClientWalletAction(payload))
  };

  useEffect(()=>{
   if(referalUser?.wallet?.history){
    setHistoryList(referalUser?.wallet?.history)
   }
  },[referalUser])



  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold mb-6" style={{ color: "#0a376a" }}>
        Update Referal Amount ({referalUser?.name})
      </h2>
      <form onSubmit={handleUpdate} className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="col-span-1">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="amount"
              style={{ color: "#0a376a" }}
            >
             Current Amount
            </label>
            <input
              id="amount"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{ borderColor: "#0a376a", focus: { ring: "#0a376a" } }}
            />
          </div>
          <div className="col-span-2">
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="message"
              style={{ color: "#0a376a" }}
            >
              Message <span className="text-red-500">*</span>
            </label>
            <input
              id="message"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ borderColor: "#0a376a", focus: { ring: "#0a376a" } }}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 text-white rounded-md hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors"
            style={{ backgroundColor: "#0a376a" }}
          >
            Update
          </button>
        </div>
      </form>

      <div className="mt-8">
        <h3
          className="text-xl font-semibold mb-4"
          style={{ color: "#0a376a" }}
        >
          History
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  style={{ color: "#0a376a" }}
                >
                  Message
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  style={{ color: "#0a376a" }}
                >
                  Updated By
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  style={{ color: "#0a376a" }}
                >
                  Updated At
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {historyList?.map((item, index) => (
                <tr key={index}>
                  <Tooltip title={item?.message}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {truncateString(item?.message,30)}
                  </td>
                  </Tooltip>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item?.updatedBy?.name?item?.updatedBy?.name+" ("+item?.updatedBy?.userType?.toLowerCase()+")" : "Referal"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {returnDateWithTime(item?.updateAt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpdateReferalAmount;
