import React, { useEffect, useState } from "react";
import {
  User,
  Mail,
  Phone,
  MapPin,
  MessageSquare,
  Activity,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { updateLiveAdsStatusAction } from "../../../services/action/common";
import { Message } from "@mui/icons-material";
import Buttons from "../../../components/utils/buttons/Buttons";
import { Toaster } from "../../../components/Toaster";

const LeadStatusUpdate = ({ liveLeadDetails, getLeadList }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const statusOptions = ["NEW", "REJECTED", "INPROGRESS", "TESTING","SALEMADE","SPAM","HOTLEADS"];
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const changeStatus = () => {
    if (!message) {
      Toaster.error("Message is required!");
      return false;
    }
    const payload = {
      userType: user?.userType?.toLowerCase(),
      data: { leadId: liveLeadDetails?._id, status,message },
      cb: getLeadList,
    };
    dispatch(updateLiveAdsStatusAction(payload));
  };
  useEffect(() => {
    if (liveLeadDetails?.status) {
      setStatus(liveLeadDetails?.status);
    }
  }, [liveLeadDetails?.status]);

  return (
    <div
      className="bg-gradient-to-br from-white to-gray-100 rounded-lg shadow-xl w-full max-w-2xl mx-auto overflow-hidden transform transition-all duration-300 hover:scale-[1.02]"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="px-6 py-4 space-y-6">
        {[
          { label: "Name", value: liveLeadDetails?.userName, icon: User },
          { label: "Email", value: liveLeadDetails?.userEmail, icon: Mail },
          { label: "Phone", value: liveLeadDetails?.userPhone, icon: Phone },
          {
            label: "Address",
            value: liveLeadDetails?.userAddress,
            icon: MapPin,
          },
          {
            label: "Message",
            value: liveLeadDetails?.userSubject,
            icon: MessageSquare,
          },
        ].map(
          (field, index) =>
            field.value && (
              <div key={index} className="flex items-start space-x-3">
                <field.icon className="w-5 h-5 text-blue-500 mt-1" />
                <div>
                  <label className="text-sm font-medium text-gray-500">
                    {field.label}
                  </label>
                  <p className="mt-1 text-sm text-gray-900">
                    {field.value || "NA"}
                  </p>
                </div>
              </div>
            )
        )}
        <div className="flex items-center space-x-3 pt-4 border-t border-gray-200">
          <Activity className="w-5 h-5 text-blue-500" />
          <div className="flex-grow">
            <label
              className="text-sm font-medium text-gray-500"
            >
              Status
            </label>
            <select
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              defaultValue={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center space-x-3 pt-4 border-t border-gray-200">
          <Message className="w-5 h-5 text-blue-500" />
          <div className="flex-grow">
            <label
              // htmlFor="status"
              className="text-sm font-medium text-gray-500"
            >
              Enter Message<span className="text-danger">*</span>
            </label>
            {/* className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md" */}
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
        <Buttons onClick={changeStatus} title="Update" className="pl-4" />
      </div>
    </div>
  );
};

export default LeadStatusUpdate;
