import React from 'react';
import { motion } from 'framer-motion';
import { SmartphoneIcon as AndroidLogo, AppleIcon as AppleLogo, DownloadCloud, CheckCircle } from 'lucide-react';
import DashboardLayout from '../../../components/layouts/DashboardLayout';

const AppDownload = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const features = [
    {
      title: "Seamless Mobile Access",
      description: "Access your content anytime, anywhere with our intuitive mobile interface"
    },
    {
      title: "Real-time Updates",
      description: "Stay informed with instant notifications and live data synchronization"
    },
    {
      title: "Secure Encryption",
      description: "Your data is protected with industry-leading encryption protocols"
    },
    {
      title: "Offline Mode",
      description: "Continue working even without internet connection with our offline capabilities"
    }
  ];

  return (
    <DashboardLayout>
      <motion.div 
        className="min-h-screen bg-gradient-to-br bg-[#001B3D] p-8 flex flex-col justify-center items-center"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="text-center mb-12" variants={itemVariants}>
          <h1 className="text-5xl font-bold text-white mb-4">Experience Our App</h1>
          <p className="text-blue-300 text-xl max-w-2xl mx-auto">
            Download now for seamless access on your mobile device and unlock a world of possibilities
          </p>
        </motion.div>

        <motion.div 
          className="flex flex-col md:flex-row gap-8 justify-center items-center mb-16"
          variants={itemVariants}
        >
          <AppDownloadButton 
            icon={<AndroidLogo className="w-10 h-10" />}
            store="Google Play"
            color="bg-green-600 hover:bg-green-700"
            url="https://play.google.com/store/apps/details?id=com.portal.clickinpedia.io"
          />
          <AppDownloadButton 
            icon={<AppleLogo className="w-10 h-10" />}
            store="App Store"
            color="bg-blue-600 hover:bg-blue-700"
            url="https://play.google.com/store/apps/details?id=com.portal.clickinpedia.io"
          />
        </motion.div>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-6xl mx-auto w-full px-4"
          variants={itemVariants}
        >
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </motion.div>

        <motion.div 
          className="mt-16 text-center"
          variants={itemVariants}
        >
          <p className="text-blue-300 text-lg">
            Join thousands of satisfied users and elevate your experience today!
          </p>
          <a href='https://play.google.com/store/apps/details?id=com.portal.clickinpedia.io'>
          <motion.div 
            className="mt-4 inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <DownloadCloud className="w-5 h-5 mr-2" />
           
            Download Now
           
          </motion.div>
          </a>
        </motion.div>
      </motion.div>
    </DashboardLayout>
  );
};

const AppDownloadButton = ({ icon, store, color, url }) => (
  <motion.a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={`group flex items-center justify-center gap-4 ${color} text-white font-semibold py-6 px-10 rounded-2xl transition-all duration-300 shadow-lg hover:shadow-2xl`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <motion.div
      initial={{ rotate: 0 }}
      whileHover={{ rotate: 12 }}
      transition={{ duration: 0.3 }}
    >
      {icon}
    </motion.div>
    <div className="flex flex-col items-start">
      <span className="text-sm opacity-80">Get it on</span>
      <span className="text-2xl">{store}</span>
    </div>
  </motion.a>
);

const FeatureCard = ({ feature }) => (
  <motion.div 
    className="bg-white bg-opacity-10 backdrop-blur-sm rounded-xl p-6 shadow-xl w-full min-h-[120px] flex items-center"
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <div className="flex items-start gap-4 w-full">
      <CheckCircle className="w-6 h-6 text-green-400 shrink-0 mt-1" />
      <div className="space-y-2 flex-1">
        <h3 className="text-white text-lg font-semibold">{feature.title}</h3>
        <p className="text-black text-sm">{feature.description}</p>
      </div>
    </div>
  </motion.div>
);

export default AppDownload;

