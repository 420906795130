import { useEffect } from "react";

const ShowCallHippo = () => {
  useEffect(() => {
    const initializeCallHippo = async () => {
      try {
        // Ensure the global token is set correctly
        window.TOKEN = "6756ecf0816b221b54ce4c1c"; // Replace with your actual token
        window.EMAIL = "pulak@clickinpedia.com";
        window.REGION = "global";

        // Add CallHippo container if it doesn't already exist
        if (!document.getElementById("ch-dialer-container")) {
          const chWidgetDiv = document.createElement("div");
          chWidgetDiv.id = "ch-dialer-container";
          document.body.appendChild(chWidgetDiv);
        }

        // Load CallHippo script only if it's not already loaded
        if (!document.getElementById("callhippo-script")) {
          const callhippoScript = document.createElement("script");
          callhippoScript.id = "callhippo-script";
          callhippoScript.type = "text/javascript";
          callhippoScript.async = true;
          callhippoScript.src =
            "https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/ch-dialer.js";

          // Append the script to the body
          document.body.appendChild(callhippoScript);

          // Handle script load success
          callhippoScript.onload = () => {
            console.log("CallHippo script loaded successfully.");
          };

          // Handle script load failure
          callhippoScript.onerror = () => {
            console.error("Failed to load the CallHippo script.");
          };
        } else {
          console.log("CallHippo script is already loaded.");
        }

        // Custom styles for CallHippo widget
        const style = document.createElement("style");
        style.innerHTML = `
          #ch-dialer-container .ch-open-button {
            left: 14px !important;
            background-color: transparent;
            border-color: white;
          }
          #ch-dialer-container #chCallMePopup {
            left: 10px;
            max-width: 370px;
          }
        `;
        document.head.appendChild(style);

        // Add listener for iframe messages
        window.addEventListener("message", (event) => {
          if (event.origin === "https://d1x9dsge91xf6g.cloudfront.net") {
            if (event.data.type === "callLogNameOrNum") {
              console.log("Call log name or number:", event.data.value);
            }
          }
        });

        // Handle click event for call icon
        const callIcon = document.getElementById("ch-call-icn");
        const iframe = document.getElementById("chFrame");

        if (callIcon) {
          callIcon.addEventListener("click", () => {
            if (iframe && iframe.contentWindow) {
              iframe.contentWindow.postMessage(
                { action: "getCallLogNameOrNum" },
                "https://d1x9dsge91xf6g.cloudfront.net"
              );
            } else {
              console.log("Iframe or contentWindow not found");
            }
          });
        } else {
          console.log("Call icon element not found");
        }
      } catch (error) {
        console.error("Error initializing CallHippo:", error);
      }
    };

    // Initialize CallHippo when the component is mounted
    initializeCallHippo();

    // Cleanup event listeners on unmount
    return () => {
      console.log("Cleaning up CallHippo listeners and elements...");
      const script = document.getElementById("callhippo-script");
      const container = document.getElementById("ch-dialer-container");
      if (script) document.body.removeChild(script);
      if (container) document.body.removeChild(container);
      window.removeEventListener("message", () => {});
    };
  }, []);

  return null;
};

export default ShowCallHippo;
