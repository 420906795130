import { useCallback, useState } from "react";
import EditorSection from "./EditorSection";
import { Edit2 } from "lucide-react";
import Buttons from "../../../components/utils/buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { updateAndroidDesignAction } from "../../../services/action/common";
const EditorPanel = ({ setEditorData, editorData }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { isUpdateDesign } = useSelector((state) => state.commonReducer);
  const [activeSection, setActiveSection] = useState("welcome");
  const dispatch = useDispatch();
  const updateData = useCallback((section, index, key, value) => {
    setEditorData((prevData) => {
      const newData = { ...prevData };
      if (Array.isArray(newData[section])) {
        newData[section] = newData[section].map((item, i) =>
          i === index ? { ...item, [key]: value } : item
        );
      } else {
        newData[section] = { ...newData[section], [key]: value };
      }
      return newData;
    });
  }, []);

  const onUpdate = () => {
    const payload = {
      data: {...editorData,screenName:"welcome"},
      userType: user?.userType?.toLowerCase(),
    };
    dispatch(updateAndroidDesignAction(payload));
  };
  return (
    <div className="w-1/2 p-6 overflow-y-auto">
      <h1 className="text-2xl font-bold mb-6">
        Android App Editor{" "}
        <Buttons
          isLoading={isUpdateDesign}
          onClick={onUpdate}
          size="small"
          title={"Save Changes"}
        />
      </h1>

      <EditorSection
        title="Welcome Section"
        isActive={activeSection === "welcome"}
        onToggle={() =>
          setActiveSection(activeSection === "welcome" ? "" : "welcome")
        }
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              value={editorData?.welcome?.title}
              onChange={(e) =>
                updateData("welcome", null, "title", e.target.value)
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Subtitle
            </label>
            <input
              type="text"
              value={editorData?.welcome?.subtitle}
              onChange={(e) =>
                updateData("welcome", null, "subtitle", e.target.value)
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Button Text
            </label>
            <input
              type="text"
              value={editorData?.welcome?.buttonText}
              onChange={(e) =>
                updateData("welcome", null, "buttonText", e.target.value)
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      </EditorSection>

      <EditorSection
        title="Services"
        isActive={activeSection === "services"}
        onToggle={() =>
          setActiveSection(activeSection === "services" ? "" : "services")
        }
      >
        {editorData?.services?.map((service, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Service {index + 1}</h3>
              <button className="text-blue-500 hover:text-blue-700">
                <Edit2 className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-2">
              <input
                type="text"
                value={service?.name}
                onChange={(e) =>
                  updateData("services", index, "name", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="text"
                value={service?.color}
                onChange={(e) =>
                  updateData("services", index, "color", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        ))}
      </EditorSection>

      <EditorSection
        title="Premium Services"
        isActive={activeSection === "premiumServices"}
        onToggle={() =>
          setActiveSection(
            activeSection === "premiumServices" ? "" : "premiumServices"
          )
        }
      >
        {editorData?.premiumServices?.map((service, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Premium Service {index + 1}</h3>
              <button className="text-blue-500 hover:text-blue-700">
                <Edit2 className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-2">
              <input
                type="text"
                value={service?.image}
                placeholder="Enter image url"
                onChange={(e) =>
                  updateData("premiumServices", index, "image", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        ))}
      </EditorSection>

      <EditorSection
        title="Brands"
        isActive={activeSection === "brands"}
        onToggle={() =>
          setActiveSection(activeSection === "brands" ? "" : "brands")
        }
      >
        {editorData?.brands?.map((brand, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Brand {index + 1}</h3>
              <button className="text-blue-500 hover:text-blue-700">
                <Edit2 className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-2">
              <input
                type="text"
                value={brand?.name}
                onChange={(e) =>
                  updateData("brands", index, "name", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="text"
                value={brand?.logo}
                onChange={(e) =>
                  updateData("brands", index, "logo", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="text"
                value={brand?.bgColor}
                onChange={(e) =>
                  updateData("brands", index, "bgColor", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="text"
                value={brand?.link}
                onChange={(e) =>
                  updateData("brands", index, "link", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        ))}
      </EditorSection>

      <EditorSection
        title="Reviews"
        isActive={activeSection === "reviews"}
        onToggle={() =>
          setActiveSection(activeSection === "reviews" ? "" : "reviews")
        }
      >
        {editorData?.reviews?.map((review, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium">Review {index + 1}</h3>
              <button className="text-blue-500 hover:text-blue-700">
                <Edit2 className="h-4 w-4" />
              </button>
            </div>
            <div className="space-y-2">
              <input
                type="text"
                value={review?.name}
                onChange={(e) =>
                  updateData("reviews", index, "name", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="text"
                value={review?.course}
                onChange={(e) =>
                  updateData("reviews", index, "course", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <input
                type="number"
                value={review?.rating}
                onChange={(e) =>
                  updateData(
                    "reviews",
                    index,
                    "rating",
                    parseInt(e.target.value)
                  )
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                min="1"
                max="5"
              />
              <input
                type="text"
                value={review?.comment}
                onChange={(e) =>
                  updateData("reviews", index, "comment", e.target.value)
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        ))}
      </EditorSection>
    </div>
  );
};

export default EditorPanel;
