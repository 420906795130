import { motion, AnimatePresence } from "framer-motion"
import {  Globe, Calendar, ChevronDown } from 'lucide-react'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

export  const ActionModal = ({ getAllLiveUser,setFilter,filter,isOpen, onClose, buttonPosition }) => {
  const [isWebsiteDropdownOpen, setIsWebsiteDropdownOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])



  const websites = [
    { label: "all website", value: "all websites" },
    { label: "android", value: "android" },
    { label: "ios", value: "ios" },
    { label: "assignmentwriter.io", value: "assignmentwriter.io" },
    { label: "assignmentwriter.au", value: "assignmentwriter.au" },
    { label: "clickinpedia.io", value: "clickinpedia.io" },
    { label: "myassignmethelp.io", value: "myassignmethelp.io" },
    { label: "facebook", value: "facebook.com" },
    { label: "dissertationwritingservices.io", value: "dissertationwritingservices.io" },
    { label: "managementassignmentwriter.com", value: "managementassignmentwriter.com" },
    { label: "myobassignmenthelp.com", value: "myobassignmenthelp.com" },
    { label: "xeroassignmenthelp.com", value: "xeroassignmenthelp.com" },
    { label: "assignmentexpert.io", value: "assignmentexpert.io" },
    { label: "asignmenthelp.io", value: "asignmenthelp.io" },
    { label: "marketingassignmenthelp.io", value: "marketingassignmenthelp.io" },
    { label: "onlinetafeassignmenthelp.com", value: "onlinetafeassignmenthelp.com" },
    { label: "scienceassignmenthelp.com", value: "scienceassignmenthelp.com" },
    { label: "onlineexamhelp.io", value: "onlineexamhelp.io" },
    { label: "financeassignmentwriter.com", value: "financeassignmentwriter.com" },
    { label: "nursingassignments.io", value: "nursingassignments.io" },
    { label: "lawassignmentwriter.com", value: "lawassignmentwriter.com" },
    { label: "assignmenthelpuk.io", value: "assignmenthelpuk.io" },
    { label: "dissertationwriter.uk", value: "dissertationwriter.uk" },
    { label: "assessmenthelp.uk", value: "assessmenthelp.uk" },
    { label: "nursingassignments.uk", value: "nursingassignments.uk" },
    { label: "accountingassignmenthelp.uk", value: "accountingassignmenthelp.uk" },
    { label: "programmingassignmenthelp.uk", value: "programmingassignmenthelp.uk" },
    { label: "examhelp.uk", value: "examhelp.uk" },
    { label: "courseworkhelp.uk", value: "courseworkhelp.uk" },
    { label: "engineeringassignmenthelp.uk", value: "engineeringassignmenthelp.uk" },
    { label: "247assignmenthelp.com", value: "247assignmenthelp.com" },
    { label: "247myassignmenthelp.com", value: "247myassignmenthelp.com" },
    { label: "academicassignmenthelp.com", value: "academicassignmenthelp.com" },
    { label: "accountingassignmentshelp.uk", value: "accountingassignmentshelp.uk" },
    { label: "assignmenthelp.in", value: "assignmenthelp.in" },
    { label: "assignmentsamples.com", value: "assignmentsamples.com" },
    { label: "assignmentsamples.io", value: "assignmentsamples.io" },
    { label: "bestassignmenthelp.ca", value: "bestassignmenthelp.ca" },
    { label: "bestassignmenthelp.de", value: "bestassignmenthelp.de" },
    { label: "bestassignmenthelp.io", value: "bestassignmenthelp.io" },
    { label: "bestonlineassignmenthelp.co.uk", value: "bestonlineassignmenthelp.co.uk" },
    { label: "bestonlinedissertationhelp.com", value: "bestonlinedissertationhelp.com" },
    { label: "bestsophelp.com", value: "bestsophelp.com" },
    { label: "bestsopservices.com", value: "bestsopservices.com" },
    { label: "bestsopwriter.com", value: "bestsopwriter.com" },
    { label: "domyassignmentsforme.uk", value: "domyassignmentsforme.uk" },
    { label: "managementassignmenthelp.uk", value: "managementassignmenthelp.uk" },
    { label: "marketingassignmenthelp.uk", value: "marketingassignmenthelp.uk" },
    { label: "mbaassignmenthelp.uk", value: "mbaassignmenthelp.uk" },
    { label: "myassignment.uk", value: "myassignment.uk" },
    { label: "payforassignment.uk", value: "payforassignment.uk" },
    { label: "sophelpers.com", value: "sophelpers.com" },
    { label: "sopwork.in", value: "sopwork.in" },
    { label: "universityassignmenthelp.uk", value: "universityassignmenthelp.uk" },
    { label: "codingassignmenthelp.io", value: "codingassignmenthelp.io" },
    { label: "statisticsassignmenthelp.io", value: "statisticsassignmenthelp.io" },
    { label: "engineeringassignmenthelp.com", value: "engineeringassignmenthelp.com" },
    { label: "courseworkhelp.io", value: "courseworkhelp.io" },
    { label: "assignmentsample.io", value: "assignmentsample.io" },
    { label: "cookeryassignmenthelp.io", value: "cookeryassignmenthelp.io" },
    { label: "researchproposalwriter.com", value: "researchproposalwriter.com" },
    { label: "subjectfiles.com", value: "subjectfiles.com" },
    { label: "subjectfile.com", value: "subjectfile.com" },
    { label: "casestudyhelp.uk", value: "casestudyhelp.uk" },
    { label: "researchproposalhelp.uk", value: "researchproposalhelp.uk" }
  ];

  const handleSubmit = () => {
    getAllLiveUser({firstTime:true,resetPageData:true})
    onClose()
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-40"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            style={isMobile ? {
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            } : {
              position: 'absolute',
              left: `${buttonPosition?.x || 0}px`,
              top: `${buttonPosition?.y + 40 || 0}px`,
            }}
            className="z-50 w-72 bg-[#00234a] rounded-lg shadow-lg border border-gray-700/50"
          >
            <div className="p-3 space-y-3">
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between p-2 hover:bg-gray-700/50 rounded-lg transition-colors"
                  onClick={() => setIsWebsiteDropdownOpen(!isWebsiteDropdownOpen)}
                >
                  <div className="flex items-center gap-2">
                    <Globe size={18} />
                    <span>{websites.find(w => w.value === filter?.website)?.label || 'Select Website'}</span>
                  </div>
                  <ChevronDown 
                    size={18} 
                    className={`transition-transform duration-200 ${
                      isWebsiteDropdownOpen ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {isWebsiteDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute left-0 right-0 mt-1 bg-[#00234a] rounded-lg shadow-lg border border-gray-700/50 py-1 z-50 max-h-48 overflow-y-auto"
                  >
                    {websites.map((site) => (
                      <button
                        key={site.value}
                        className="w-full text-left px-4 py-2 hover:bg-gray-700/50 transition-colors flex items-center gap-2"
                        onClick={() => {
                          setFilter((prev)=>({...prev,website:site.value}))
                          setIsWebsiteDropdownOpen(false)
                        }}
                      >
                        {site.label}
                      </button>
                    ))}
                  </motion.div>
                )}
              </div>

              {/* <button
                onClick={handleExportCSV}
                className="w-full flex items-center gap-2 p-2 hover:bg-gray-700/50 rounded-lg transition-colors"
              >
                <Download size={18} />
                <span>Export CSV</span>
              </button> */}

              <div className="space-y-2">
                <div className="flex items-center gap-2 p-2">
                  <Calendar size={18} />
                  <span>Select Date Range</span>
                </div>
                <div className="space-y-2">
                  <DatePicker
                    selected={filter?.from}
                    onChange={(date) => setFilter((prev)=>({...prev,from:date}))}
                    selectsStart
                    startDate={filter?.from}
                    endDate={filter?.to}
                    className="w-full bg-[#00234a] border border-gray-700/50 rounded-lg p-2 text-sm"
                    placeholderText="Start Date"
                  />
                  <DatePicker
                    selected={filter?.to}
                    onChange={(date) => setFilter((prev)=>({...prev,to:date}))}
                    selectsEnd
                    startDate={filter?.from}
                    endDate={filter?.to}
                    minDate={filter?.from}
                    className="w-full bg-[#00234a] border border-gray-700/50 rounded-lg p-2 text-sm"
                    placeholderText="End Date"
                  />
                </div>
              </div>

              <button
                onClick={handleSubmit}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors mt-4"
              >
                Submit
              </button>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

