import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Camera, IndianRupee, X } from 'lucide-react';
import CreatableSelect from "react-select/creatable";

const BasicInfoForm = ({
  avatar,
  bio,
  primaryExpertise,
  expertise,
  education,
  degrees,
  chargesPerWord,
  specializationOptions,
  expertiseOptions,
  onAvatarChange,
  onBioChange,
  onSpecializationChange,
  onExpertiseChange,
  onEducationChange,
  onDegreesChange,
  onChargesChange,
}) => {
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [newAvatar, setNewAvatar] = useState(null);

  const educationOptions = [
    "High School",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctorate",
    "Post Doctorate",
    "Professional Certification",
    "Vocational Training"
  ];

  const degreesOptions = [
    "B.Tech",
    "B.E.",
    "B.Sc",
    "B.A",
    "B.Com",
    "M.Tech",
    "M.E.",
    "M.Sc",
    "M.A",
    "M.Com",
    "Ph.D",
    "MBA",
    "BBA",
    "MCA",
    "BCA"
  ];

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateAvatar = () => {
    if (newAvatar) {
      onAvatarChange(newAvatar);
      setIsEditingAvatar(false);
      setNewAvatar(null);
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(30, 58, 138, 0.5)",
      borderColor: "rgba(96, 165, 250, 0.3)",
      color: "white",
      "&:hover": {
        borderColor: "rgba(96, 165, 250, 0.5)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgb(30, 58, 138)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgba(96, 165, 250, 0.2)"
        : "transparent",
      color: "white",
      "&:active": {
        backgroundColor: "rgba(96, 165, 250, 0.3)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgba(96, 165, 250, 0.3)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "rgba(96, 165, 250, 0.5)",
        color: "white",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <div className="w-full max-w-4xl mx-auto rounded-2xl shadow-2xl">
      <div className="p-6 sm:p-10 space-y-8">
        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-6">
          <div className="relative group">
            <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-blue-400/20 group-hover:border-blue-400/50 transition-all duration-300">
              <img
                src={avatar || "/placeholder.svg"}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <button
              onClick={() => setIsEditingAvatar(true)}
              className="absolute bottom-0 right-0 bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 transition-colors duration-300"
            >
              <Camera className="h-4 w-4" />
            </button>
          </div>
          <div className="flex-1 space-y-4 text-center sm:text-left">
            <h2 className="text-3xl font-bold text-white">
              Professional Profile
            </h2>
            <p className="text-blue-200 text-lg">
              Showcase your expertise and set your rates
            </p>
          </div>
        </div>

        <div className="space-y-6">
          <div className="space-y-2">
            <label
              htmlFor="bio"
              className="block text-sm font-medium text-blue-100"
            >
              Professional Bio
            </label>
            <textarea
              id="bio"
              value={bio}
              onChange={onBioChange}
              className="w-full min-h-[120px] px-4 py-2 bg-blue-900/50 border border-blue-400/30 rounded-lg text-white placeholder-blue-300/50 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              placeholder="Tell us about your expertise and experience..."
            />
            <p className="text-xs text-blue-300 text-right">
              {bio.length}/500 characters
            </p>
          </div>

          <div className="grid sm:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label
                htmlFor="education"
                className="block text-sm font-medium text-blue-100"
              >
                Education Level
              </label>
              <CreatableSelect
                isMulti
                id="education"
                value={education ? education.map(e => ({ value: e, label: e })) : []}
                onChange={(newValue) => onEducationChange(newValue.map(v => v.value))}
                options={educationOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                styles={selectStyles}
                placeholder="Select your education level"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="degrees"
                className="block text-sm font-medium text-blue-100"
              >
                Degrees
              </label>
              <CreatableSelect
                isMulti
                id="degrees"
                value={degrees ? degrees.map(d => ({ value: d, label: d })) : []}
                onChange={(newValue) => onDegreesChange(newValue.map(v => v.value))}
                options={degreesOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                styles={selectStyles}
                placeholder="Select your degrees"
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label
                htmlFor="primaryExpertise"
                className="block text-sm font-medium text-blue-100"
              >
                Primary Expertise
              </label>
              <CreatableSelect
                isMulti
                id="primaryExpertise"
                value={primaryExpertise ? primaryExpertise?.map(s => ({ value: s, label: s })) : []}
                onChange={(newValue) => onSpecializationChange(newValue.map(v => v.value))}
                options={specializationOptions?.map((option) => ({
                  value: option,
                  label: option,
                }))}
                styles={selectStyles}
                placeholder="Select or create specializations"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="expertise"
                className="block text-sm font-medium text-blue-100"
              >
                Secondary Expertise
              </label>
              <CreatableSelect
                isMulti
                id="expertise"
                value={expertise ? expertise?.map(e => ({ value: e, label: e })) : []}
                onChange={(newValue) => onExpertiseChange(newValue.map(v => v.value))}
                options={expertiseOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                styles={selectStyles}
                placeholder="Select or create expertise"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label
              htmlFor="charges"
              className="block text-sm font-medium text-blue-100"
            >
              Charges per Word (₹)
            </label>
            <div className="relative">
              <IndianRupee className="absolute left-3 top-1/2 -translate-y-1/2 text-blue-300 h-5 w-5" />
              <input
                id="charges"
                type="number"
                value={chargesPerWord}
                onChange={(e) => onChargesChange(e.target.value)}
                className="w-full pl-10 pr-16 py-2 bg-blue-900/50 border border-blue-400/30 rounded-lg text-white placeholder-blue-300/50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                placeholder="0.50"
                step="0.01"
                min="0"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-blue-300 text-sm">
                INR
              </span>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isEditingAvatar && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="bg-blue-900 p-6 rounded-lg shadow-xl max-w-md w-full"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-white">
                  Update Profile Picture
                </h3>
                <button
                  onClick={() => setIsEditingAvatar(false)}
                  className="text-blue-300 hover:text-white transition-colors duration-300"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-center">
                  <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-blue-400/20">
                    <img
                      src={newAvatar || avatar}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  className="w-full text-sm text-blue-300 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600"
                />
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setIsEditingAvatar(false)}
                    className="px-4 py-2 bg-transparent hover:bg-blue-800 text-blue-300 hover:text-white border border-blue-400/30 rounded-lg transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={updateAvatar}
                    className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-300"
                    disabled={!newAvatar}
                  >
                    Update
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BasicInfoForm;
