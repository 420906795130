import React, { useState, useEffect } from "react";
import {
  X,
  User,
  Mail,
  Phone,
  DollarSign,
  Star,
  Briefcase,
  AlertCircle,
  Clock,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../../services/action/common";

const UserDetailsModal = ({ userId, onClose }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { userDetails } = useSelector((state) => state.commonReducer);
  //   const [userDetails, setUserDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        getUserDetailsAction({
          userType: user?.userType?.toLowerCase(),
          data: {
            userId,
          },
        })
      );
    }
  }, [userId, dispatch, user]);

  if (!userDetails) {
    return "";
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#001B3D] rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white/60 hover:text-white transition-colors"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="flex items-center mb-4">
          <img
            src={userDetails?.user?.image}
            alt={userDetails?.user?.name}
            className="w-16 h-16 rounded-full mr-4"
          />
          <div>
            <h2 className="text-2xl font-bold text-white">
              {userDetails?.user?.name}
            </h2>
            <p className="text-gray-400">
              {userDetails?.user?.userType?.charAt(0).toUpperCase() +
                userDetails?.user?.userType?.slice(1)}
            </p>
          </div>
        </div>
        <div className="space-y-2">
          <InfoItem
            icon={User}
            label="Online Status"
            value={userDetails?.user?.isOnline ? "Online" : "Offline"}
          />
          <InfoItem
            icon={Clock}
            label="Last Login"
            value={userDetails?.user?.lastLogin || "NA"}
          />
          <InfoItem
            icon={Mail}
            label="Email"
            value={userDetails?.user?.email}
          />
          <InfoItem
            icon={Phone}
            label="Phone"
            value={
              userDetails?.user?.countryCode + " " + userDetails?.user?.number
            }
          />
          <InfoItem
            icon={DollarSign}
            label="Wallet"
            value={"$" + userDetails?.user?.wallet?.amount}
          />
          <InfoItem
            icon={Star}
            label="Ratings"
            value={userDetails?.user?.ratings?.toFixed(1) || 4.5}
          />

          {userDetails?.user?.userType === "CLIENT" && (
            <>
              <InfoItem
                icon={DollarSign}
                label="Revenues Generated"
                value={userDetails?.orders?.revenuesGenerated}
              />
              <InfoItem
                icon={Briefcase}
                label="Total Orders"
                value={userDetails?.orders?.totalOrders}
              />
              <InfoItem
                icon={Clock}
                label="Status"
                value={userDetails?.user?.status}
              />
            </>
          )}

          {userDetails?.user?.userType === "EXPERT" && (
            <>
              <InfoItem
                icon={Briefcase}
                label="Orders Completed"
                value={userDetails?.orders?.completedOrders}
              />
              <InfoItem
                icon={Briefcase}
                label="Assigned Orders"
                value={userDetails?.orders?.assignedOrders}
              />
              <InfoItem
                icon={AlertCircle}
                label="Missed Orders"
                value={userDetails?.orders?.missedOrders}
              />
              <InfoItem
                icon={DollarSign}
                label="Money Generated"
                value={userDetails?.orders?.moneyGenerated}
              />
              <InfoItem
                icon={Clock}
                label="PPW"
                value={userDetails?.user?.ppwRate}
              />
              <InfoItem
                icon={Clock}
                label="Expert Type"
                value={userDetails?.user?.expertType}
              />
              <InfoItem
                icon={Briefcase}
                label="Primary Expertise"
                value={userDetails?.user?.primaryExpertise}
              />
              <InfoItem
                icon={Clock}
                label="Status"
                value={userDetails?.user?.status}
              />
            </>
          )}

          {(userDetails?.user?.userType === "OPERATOR" ||
            userDetails?.user?.userType === "MANAGER") && (
            <>
              <InfoItem
                icon={Briefcase}
                label="Orders Created"
                value={userDetails?.orders?.createdOrders}
              />
              <InfoItem
                icon={Clock}
                label="Status"
                value={userDetails?.user?.status}
              />
            </>
          )}

          {userDetails?.user?.userType === "OPERATOR" && (
            <>
              <InfoItem
                icon={Briefcase}
                label="Departmant"
                value={userDetails?.user?.departMent}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center gap-2 text-gray-400">
    <Icon className="h-4 w-4" />
    <span className="text-white">
      {label}: {value}
    </span>
  </div>
);

export default UserDetailsModal;
