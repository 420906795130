import React, { useEffect, useState } from "react";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAction,
  loginAction,
  resetPasswordAction,
} from "../../services/action/authentication";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [obj, setObj] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isReset, user } = useSelector((state) => state.authenticationReducer);
  const [errors, setErrors] = useState({
    email: "",
  });

  // Keeping all the existing functionality
  const checkLogin = (tempUser) => {
    if (tempUser && tempUser?.userType === "MANAGER") {
      navigate("/manager/dashboard");
    } else if (tempUser && tempUser?.userType === "OPERATOR") {
      navigate("/operator/dashboard");
    } else if (tempUser && tempUser?.userType === "CLIENT") {
      navigate("/client/dashboard");
    } else if (tempUser && tempUser?.userType === "EXPERT") {
      navigate("/expert/dashboard");
    }
  };

  useEffect(() => {
    const tokens = localStorage.getItem("token");
    tokens && dispatch(getUserAction());
  }, [dispatch]); // Added dispatch to dependencies

  useEffect(() => {
    checkLogin(user);
  }, [user]);

  const callBackSuccess = (res) => {
    checkLogin(res);
  };

  const resetNow = (e) => {
    e.preventDefault();
    const tempError = { ...errors };
    if (!obj.email) {
      tempError.email = "Email is required!";
    } else {
      tempError.email = "";
    }

    if (tempError.email) {
      setErrors(tempError);
      return false;
    }
    dispatch(resetPasswordAction({ data: obj, cb: callBackSuccess }));
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-600">
      <div className="w-full max-w-md p-8 mx-4 bg-white rounded-2xl shadow-xl">
        <div className="text-center space-y-6">
          <img
            src="/images/logo.png"
            alt="ClickinPedia Logo"
            className="h-12 mx-auto"
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-900">Reset Password</h1>
            <p className="mt-2 text-gray-600">
              Enter your email to reset your password
            </p>
          </div>
        </div>

        <form onSubmit={resetNow} className="mt-8 space-y-6">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <input
              type="email"
              className={`w-full pl-10 pr-4 py-3 text-gray-700 bg-gray-50 rounded-lg focus:outline-none focus:ring-2 ${
                errors.email
                  ? "border-red-500 focus:ring-red-200"
                  : "border-gray-200 focus:ring-blue-200"
              }`}
              placeholder="Enter your email"
              value={obj.email}
              onChange={(e) => setObj({ ...obj, email: e.target.value })}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          <div className="flex items-center justify-end">
            <Link
              to="/"
              className="text-sm font-medium text-blue-600 hover:text-blue-500 transition-colors"
            >
              Back to Login
            </Link>
          </div>

          <button
            type="submit"
            className="w-full flex items-center justify-center px-4 py-3 text-white font-medium bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-200 transition-all duration-150 space-x-2"
          >
            {isReset ? (
              <Icons title="loader" />
            ) : (
              <>
                <span>Reset Password</span>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
