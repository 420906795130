import { useState, useEffect } from "react"
import { Menu, X, ChevronDown, Search, Phone, Mail, Bell } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { getUserAction } from "../../services/action/authentication"
import { useDispatch } from "react-redux"

const ClientHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isBalanceDropdownOpen, setIsBalanceDropdownOpen] = useState(false)
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false)
  const navigate=useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction({ errorCb: () => navigate("/") }));
  }, [dispatch, navigate]);


  const balanceOptions = [
    { name: "Main", amount: "$750.00" },
    { name: "Wallet", amount: "$500.00" },
  ]

  const totalBalance = balanceOptions
    .reduce((sum, option) => sum + Number.parseFloat(option.amount.slice(1)), 0)
    .toFixed(2)

  const notifications = [
    { id: 1, message: "Your order #1234 has been shipped" },
    { id: 2, message: "New offer available: 20% off on your next order" },
    { id: 3, message: "Reminder: Complete your profile for extra benefits" },
  ]

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 bg-white transition-all duration-300 ${isScrolled ? "shadow-lg" : ""}`}
    >
      {/* Top Bar */}
      <div className="bg-blue-600 text-white py-2">
        <div className="max-w-6xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <a href="tel:+447575438880" className="flex items-center hover:text-blue-200 transition-colors text-white">
              <Phone className="h-4 w-4 mr-2" />
              <span className="text-sm">+44 757543 8880</span>
            </a>
            <a
              href="mailto:help@clickinpedia.com"
              className="flex items-center hover:text-blue-200 transition-colors text-white"
            >
              <Mail className="h-4 w-4 mr-2" />
              <span className="text-sm">help@clickinpedia.com</span>
            </a>
          </div>
          <div className="flex items-center">
            <span className="bg-yellow-400 text-blue-900 px-3 py-1 rounded-l-full font-medium text-sm">OFFER!</span>
            <span className="bg-blue-700 px-3 py-1 rounded-r-full text-sm">
              Save 56% on Assignment Crafted by Human Writers
            </span>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <span className="text-2xl font-bold text-blue-600">Clickinpedia</span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-6">
            {/* <Link to="/order-creation" className="text-gray-700 hover:text-blue-500 transition-colors">
              Order Creation
            </Link>
            <Link to="/order-history" className="text-gray-700 hover:text-blue-500 transition-colors">
              Order History
            </Link> */}
            <Link to="/client/dashboard" className="text-gray-700 hover:text-blue-500 transition-colors">
             My Orders
            </Link>
            <Link to="/client/new-order" className="text-gray-700 hover:text-blue-500 transition-colors">
              Order Now
            </Link>
            <Link to="/client/experts" className="text-gray-700 hover:text-blue-500 transition-colors">
              Experts
            </Link>
          </nav>

          {/* Search, Cart, Balance, Notifications, and User */}
          <div className="hidden lg:flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-48 pl-10 pr-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            {/* <Link to="/cart" className="relative text-gray-600 hover:text-blue-500 transition-colors">
              <ShoppingCart className="h-6 w-6" />
              <span className="absolute -top-2 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                0
              </span>
            </Link> */}
            <div className="relative">
              <button
                className="bg-blue-50 px-3 py-1.5 rounded-lg flex items-center space-x-1"
                onClick={() => setIsBalanceDropdownOpen(!isBalanceDropdownOpen)}
              >
                <span className="text-sm font-medium text-blue-600">${totalBalance}</span>
                <ChevronDown className="h-4 w-4 text-blue-600" />
              </button>
              {isBalanceDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  {balanceOptions.map((option) => (
                    <div key={option.name} className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-50">
                      <span className="font-medium">{option.name}:</span> {option.amount}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="relative">
              <button
                className="text-gray-600 hover:text-blue-500 transition-colors"
                onClick={() => setIsNotificationDropdownOpen(!isNotificationDropdownOpen)}
              >
                <Bell className="h-6 w-6" />
                <span className="absolute -top-2 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                  {notifications.length}
                </span>
              </button>
              {isNotificationDropdownOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg py-1 z-10">
                  {notifications.map((notification) => (
                    <div key={notification.id} className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-50">
                      {notification.message}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button  onClick={()=> navigate("/client/profile")} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
              Satendra Sahani
            </button>
          </div>

          {/* Mobile menu button */}
          <button
            className="lg:hidden p-2 rounded-md text-gray-600 hover:text-blue-500 transition-colors"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-lg">
          <div className="max-w-6xl mx-auto px-4 py-4 space-y-4">
            {/* <div className="flex items-center justify-between">
              <Link to="/cart" className="relative text-gray-600 hover:text-blue-500 transition-colors">
                <ShoppingCart className="h-6 w-6" />
                <span className="absolute -top-2 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                  0
                </span>
              </Link>
              <div className="relative">
                <button
                  className="bg-blue-50 px-3 py-1.5 rounded-lg flex items-center space-x-1"
                  onClick={() => setIsBalanceDropdownOpen(!isBalanceDropdownOpen)}
                >
                  <span className="text-sm font-medium text-blue-600">${totalBalance}</span>
                  <ChevronDown className="h-4 w-4 text-blue-600" />
                </button>
                {isBalanceDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    {balanceOptions.map((option) => (
                      <div key={option.name} className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-50">
                        <span className="font-medium">{option.name}:</span> {option.amount}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative">
                <button
                  className="text-gray-600 hover:text-blue-500 transition-colors"
                  onClick={() => setIsNotificationDropdownOpen(!isNotificationDropdownOpen)}
                >
                  <Bell className="h-6 w-6" />
                  <span className="absolute -top-2 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                    {notifications.length}
                  </span>
                </button>
                {isNotificationDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg py-1 z-10">
                    {notifications.map((notification) => (
                      <div key={notification.id} className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-50">
                        {notification.message}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            <nav className="space-y-2">
              <Link to="/client/dashboard" className="block py-2 text-gray-700 hover:text-blue-500 transition-colors">
                My Order
              </Link>
              <Link to="/client/new-order" className="block py-2 text-gray-700 hover:text-blue-500 transition-colors">
                Order Now
              </Link>
              <Link to="/client/experts" className="block py-2 text-gray-700 hover:text-blue-500 transition-colors">
                Experts
              </Link>
            </nav>
            <button className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors">
              Satendra Sahani
            </button>
          </div>
        </div>
      )}
    </header>
  )
}

export default ClientHeader

