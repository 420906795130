import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { Package, CheckCircle, AlertCircle, Clock, ChevronDown } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DashboardLayout from "../layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportOrderListAction,
  getReportsAction,
} from "../../services/action/common";
import OrderListReport from "./components/OrderListReport";

const OrderReport = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { reports } = useSelector((state) => state.commonReducer);
  const [pageData, setPageData] = useState({
    page: 1,
  });
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");

  const [search, setSearch] = useState("");
  const metrics = (data) => {
    return [
      {
        id: "total",
        title: "Total Orders",
        value: data?.totalOrders,
        icon: Package,
        color: "bg-blue-500",
      },
      {
        id: "completed",
        title: "Completed Orders",
        value: data?.completedOrder,
        icon: CheckCircle,
        color: "bg-green-500",
      },
      {
        id: "missed",
        title: "Missed Deadline Orders",
        value: data?.missedDeadlineCount,
        icon: AlertCircle,
        color: "bg-red-500",
      },
      {
        id: "progress",
        title: "Orders In Progress",
        value: data?.inProgressOrder,
        icon: Clock,
        color: "bg-yellow-500",
      },
    ];
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const months = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        getReportsAction({
          userType: user?.userType?.toLowerCase(),
          data: {
            reportType: "ORDERS",
            selectedMonth: selectedMonth == "All" ? "" : selectedMonth,
          },
        })
      );
    }
  }, [dispatch, user, selectedMonth]);

  const getOrderReportList = ({
    orderStatus = selectedMetric,
    specificMonth = selectedMonth,
    page = pageData?.page,
    firstTime,
    tempSearch = search,
    dateFilter = selectedDateFilter,
  }) => {
    const payload = {
      page,
      search: tempSearch,
      specificMonth: specificMonth === "All" ? "" : specificMonth,
      orderType:
        orderStatus === "total"
          ? "TOTAL"
          : orderStatus === "missed"
          ? "MISSED_DEADLINE"
          : orderStatus === "progress"
          ? "ASSIGNED"
          : orderStatus === "completed"
          ? "DELIVERED"
          : "",
      dateFilter,
    };
    dispatch(
      getReportOrderListAction({
        userType: user?.userType,
        data: payload,
        firstTime: firstTime,
      })
    );
  };

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    getOrderReportList({
      dateFilter: filter,
      firstTime: true,
      page: 1,
    });
    setPageData({ page: 1 });
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen bg-[#001B3D] p-6">
        <div className="max-w-7xl mx-auto">
          <header className="mb-12 flex justify-between items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">
                Orderwise Reports
              </h1>
              <p className="text-blue-300">Your order management dashboard</p>
            </div>
            <div className="relative">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="appearance-none bg-[#0A2547] text-white py-2 px-4 pr-8 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white pointer-events-none" />
            </div>
          </header>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {metrics(reports?.current)?.map((metric) => (
              <motion.div
                key={metric.id}
                className="bg-[#0A2547] rounded-xl shadow-lg overflow-hidden hover:bg-[#0F2F5B] transition-all duration-300 cursor-pointer"
                variants={itemVariants}
                onClick={() => {
                  getOrderReportList({
                    orderStatus: metric.id,
                    firstTime: true,
                    page: 1,
                  });
                  setSelectedMetric(metric.id);
                  setPageData({ page: 1 });
                }}
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <span className="text-lg font-semibold text-white">
                      {metric.title}
                    </span>
                    <div
                      className={`p-2 rounded-full ${metric.color} text-white`}
                    >
                      <metric.icon className="h-6 w-6" />
                    </div>
                  </div>
                  <p className="text-3xl font-bold text-white">
                    {metric.value}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>

          <div className="bg-[#0A2547] rounded-xl shadow-lg p-6 mb-8">
            <h2 className="text-2xl font-bold text-white mb-4">
              Total Orders of{" "}
              {selectedMonth !== "All" ? selectedMonth : "this year"}
            </h2>
            {selectedMonth !== "All" && (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={reports?.graphData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                    <XAxis dataKey="day" stroke="#8295AF" />
                    <YAxis stroke="#8295AF" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "#0A2547",
                        border: "none",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="orders"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={{ fill: "#3B82F6" }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}

            {selectedMonth === "All" && (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={reports?.graphData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                    <XAxis dataKey="month" stroke="#8295AF" />
                    <YAxis stroke="#8295AF" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "#0A2547",
                        border: "none",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="totalOrders"
                      name="Total Orders"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={{ fill: "#3B82F6" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="completedOrder"
                      name="Complete Orders"
                      stroke="#10B981"
                      strokeWidth={2}
                      dot={{ fill: "#10B981" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="inProgressOrder"
                      name="Inprogress Orders"
                      stroke="#F59E0B"
                      strokeWidth={2}
                      dot={{ fill: "#F59E0B" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="missedDeadlineCount"
                      name="Missed Deadline"
                      stroke="#F59E0B"
                      strokeWidth={2}
                      dot={{ fill: "red" }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
  
          <OrderListReport
            metrics={metrics}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            setPageData={setPageData}
            fetchOrderList={getOrderReportList}
            setSearch={setSearch}
            pageData={pageData}
            search={search}
            handleDateFilterChange={handleDateFilterChange}
            selectedDateFilter={selectedDateFilter}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default OrderReport;

