import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { X, Send, Paperclip, MinusCircle, User, Settings } from 'lucide-react'

const TypingAnimation = () => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    className="flex space-x-2 p-3 bg-white/20 rounded-2xl max-w-[80%]"
  >
    <motion.div
      className="w-2 h-2 bg-white rounded-full"
      animate={{ y: [0, -5, 0] }}
      transition={{ repeat: Infinity, duration: 0.5, ease: "easeInOut" }}
    />
    <motion.div
      className="w-2 h-2 bg-white rounded-full"
      animate={{ y: [0, -5, 0] }}
      transition={{ repeat: Infinity, duration: 0.5, ease: "easeInOut", delay: 0.1 }}
    />
    <motion.div
      className="w-2 h-2 bg-white rounded-full"
      animate={{ y: [0, -5, 0] }}
      transition={{ repeat: Infinity, duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    />
  </motion.div>
)

export default function DynamicThemeChat({ initialColor = '#13004a' }) {
  const [isOpen, setIsOpen] = useState(true)
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "What's on your mind today?",
      sender: "agent",
      time: "just now"
    }
  ])
  const [inputValue, setInputValue] = useState("")
  const [themeColor, setThemeColor] = useState(initialColor)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [isAgentTyping, setIsAgentTyping] = useState(false)

  useEffect(() => {
    console.log('Theme color changed to:', themeColor)
  }, [themeColor])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!inputValue.trim()) return

    const newMessage = {
      id: messages.length + 1,
      text: inputValue,
      sender: "user",
      time: "just now"
    }
    setMessages(prev => [...prev, newMessage])
    setInputValue("")

    if (inputValue.toLowerCase().trim() === typeof("string") || typeof("number")) {
      setIsAgentTyping(true)
      setTimeout(() => {
        setIsAgentTyping(false)
        setMessages(prev => [...prev, {
          id: prev.length + 1,
          text: "This is satendra sahani from bhatnibujurg , how may i assist you today!",
          sender: "agent",
          time: "just now"
        }])
      }, 8000)
    } else {
      setTimeout(() => {
        setMessages(prev => [...prev, {
          id: prev.length + 1,
          text: "This is satendra sahani from bhatnibujurg , how may i assist you today!",
          sender: "agent",
          time: "just now"
        }])
      }, 8000)
    }
  }

  const handleColorChange = (e) => {
    setThemeColor(e.target.value)
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="fixed bottom-4 right-4 w-[380px] rounded-2xl shadow-2xl overflow-hidden"
          style={{ backgroundColor: themeColor }}
        >
          {/* Header */}
          <motion.div 
            className="p-4 flex items-center justify-between border-b border-white/10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                <User className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="font-semibold text-white">Assignment Expert</h3>
                <p className="text-xs text-white/70">Online</p>
              </div>
            </div>
            <div className="flex gap-2">
              <button 
                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                className="p-1 hover:bg-white/10 rounded-full transition-colors"
              >
                <Settings className="w-5 h-5 text-white" />
              </button>
              <button 
                onClick={() => setIsOpen(false)}
                className="p-1 hover:bg-white/10 rounded-full transition-colors"
              >
                <MinusCircle className="w-5 h-5 text-white" />
              </button>
              <button 
                onClick={() => setIsOpen(false)}
                className="p-1 hover:bg-white/10 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-white" />
              </button>
            </div>
          </motion.div>

          {/* Color Picker */}
          <AnimatePresence>
            {isColorPickerOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="p-4 border-b border-white/10"
              >
                <label htmlFor="colorPicker" className="block text-sm font-medium text-white mb-2">
                  Choose Theme Color
                </label>
                <input
                  type="color"
                  id="colorPicker"
                  value={themeColor}
                  onChange={handleColorChange}
                  className="w-full h-10 rounded-md cursor-pointer"
                />
              </motion.div>
            )}
          </AnimatePresence>

          {/* Messages */}
          <motion.div 
            className="h-[400px] overflow-y-auto p-4 space-y-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {messages.map((message, index) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`max-w-[80%] ${message.sender === 'user' ? 'bg-white/10' : 'bg-white/20'} rounded-2xl p-3`}>
                  <p className="text-sm text-white">{message.text}</p>
                  <p className="text-xs text-white/50 mt-1">{message.time}</p>
                </div>
              </motion.div>
            ))}
            {isAgentTyping && (
              <motion.div className="flex justify-start">
                <TypingAnimation />
              </motion.div>
            )}
          </motion.div>

          {/* Input */}
          <motion.form 
            onSubmit={handleSubmit}
            className="p-4 border-t border-white/10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <div className="flex gap-2">
              <button 
                type="button"
                className="p-2 hover:bg-white/10 rounded-full transition-colors"
              >
                <Paperclip className="w-5 h-5 text-white" />
              </button>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 bg-white/10 rounded-full px-4 py-2 text-sm text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
              />
              <button 
                type="submit"
                className="bg-white/20 p-2 rounded-full hover:bg-white/30 transition-colors disabled:opacity-50"
                disabled={!inputValue.trim()}
              >
                <Send className="w-5 h-5 text-white" />
              </button>
            </div>
          </motion.form>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

