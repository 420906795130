import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import InfiniteScroll from "react-infinite-scroll-component";
import * as XLSX from "xlsx"; 

const CustomDataTable = ({hasMore=true,isNotOrderId=false, columns, data, isInfinitScroll, fetchMoreData, downLoadBtnRef }) => {

  function transformDataToExcelBFormat(data) {
    if(isNotOrderId){
      return data;
    }
    return data.map(item => ({
      "Order Registration": item?.createdAt?moment(item.createdAt).format('YYYY-MM-DD'):"",  
      "Order Status": item?.orderStatus || "N/A",   
      "Country Code": item["countryCode"] || "N/A",
      "Client Name": item?.client?.name || "N/A",  
      "Client Email": item?.client?.email  || "N/A",
      "Client Number": item?.client?.number  || "N/A", 
      "Client Deadline": item?.clientDeadline?moment(item.clientDeadline).format('YYYY-MM-DD'):"",
      "Expert Deadline": item?.expertDeadline?moment(item.expertDeadline).format('YYYY-MM-DD'):"",
      "Expert Name": item?.expert?.name || "N/A",  
      "Word Count": item["wordCount"] || 0,    
      "Currency": item["currancy"] || "N/A",
      "Total Payment": item["totalPayment"] || 0,  
      "Payment Received": item["paymentRecieved"] || 0, 
      "Balance Payment": (item["totalPayment"] || 0) - (item["paymentRecieved"] || 0), 
      "Source": item["source"] || "N/A",    
      "Order ID": item["orderId"] || "N/A",
      "Subject": item["subject"] || "N/A",  
      "Expert Amount": item["expertAmount"] || 0 
    }));
  }

  function downloadExcel(data) {
    if (!data || data.length === 0) {
      return; 
    }

    const transformedData = transformDataToExcelBFormat(data); 

    const worksheet = XLSX.utils.json_to_sheet(transformedData); 
    const workbook = XLSX.utils.book_new(); 
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads"); 

    XLSX.writeFile(workbook, 'export.xlsx');
  }

  const Export = ({ onExport }) => (
    <button ref={downLoadBtnRef} onClick={onExport} style={{ display: "none" }}>Export</button>
  );

  const actionsMemo = React.useMemo(() => (
    <Export onExport={() => downloadExcel(data)} />
  ), [data]);

  return isInfinitScroll ? (
    <InfiniteScroll
      dataLength={data?.length || 0}
      next={fetchMoreData}
      hasMore={hasMore}
    >
      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        actions={actionsMemo}
      />
    </InfiniteScroll>
  ) : (
    <DataTable
      columns={columns}
      data={data}
      highlightOnHover
      actions={actionsMemo}
    />
  );
};

export default CustomDataTable;
