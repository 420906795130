import React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { checkClientRazorpayAction, getUserAction } from "../../../services/action/authentication"
import { Download, ArrowRight } from "lucide-react"
const ReceiptButton = ({ details, status }) => {


  return (
    <button
      className="flex items-center justify-center gap-2 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
    >
      <Download className="w-5 h-5" />
      Download Receipt
    </button>
  )
}

const PaymentStatus = ({ status, details }) => {
  const navigate = useNavigate()
  if (!details) return null

  const statusColor = status === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"

  return (
    <div id="payment-receipt" className="bg-white rounded-lg shadow-xl w-full max-w-4xl">
      <div className="p-8">
        <div className="flex justify-between items-start mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Clickinpedia</h1>
            <p className="text-sm text-gray-600">Payment Receipt</p>
          </div>
          <div className={`px-4 py-2 rounded-full ${statusColor}`}>
            {status === "success" ? "Payment Successful" : "Payment Failed"}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-16 gap-y-4 mb-8">
          <div>
            <h2 className="text-sm font-semibold text-gray-600 mb-2">Client Details</h2>
            <p className="text-sm">{details.name}</p>
            <p className="text-sm">{details.email}</p>
          </div>
          <div>
            <h2 className="text-sm font-semibold text-gray-600 mb-2">Payment Information</h2>
            <p className="text-sm">Date: {details.paymentDate}</p>
            <p className="text-sm">Payment ID: {details.paymentId}</p>
            <p className="text-sm">Order ID: {details.orderId}</p>
          </div>
        </div>

        <table className="w-full mb-8">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="text-left py-2 text-sm font-semibold text-gray-600">Description</th>
              <th className="text-right py-2 text-sm font-semibold text-gray-600">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 text-sm">{details.subject}</td>
              <td className="py-2 text-sm text-right">
                {details.totalPayment} {details.currency}
              </td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="py-2 text-sm font-semibold">Total Payment</td>
              <td className="py-2 text-sm font-semibold text-right">
                {details.totalPayment} {details.currency}
              </td>
            </tr>
            <tr>
              <td className="py-2 text-sm">Paid Amount</td>
              <td className="py-2 text-sm text-right">
                {details.paidAmount} {details.currency}
              </td>
            </tr>
            <tr className="border-t border-gray-200">
              <td className="py-2 text-sm font-semibold">Balance Payment</td>
              <td className="py-2 text-sm font-semibold text-right">
                {details.balancePayment} {details.currency}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="grid grid-cols-2 gap-x-16 gap-y-4 mb-8 text-sm">
          <div>
            <p>
              <span className="font-semibold">Payment Mode:</span> {details.paymentMode}
            </p>
            <p>
              <span className="font-semibold">Subject:</span> {details.subject}
            </p>
            <p>
              <span className="font-semibold">Deadline:</span> {details.deadline}
            </p>
          </div>
          <div>
            <p>
              <span className="font-semibold">Plan:</span> {details.plan}
            </p>
            <p>
              <span className="font-semibold">Currency:</span> {details.currency}
            </p>
          </div>
        </div>

        {status !== "success" && (
          <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 text-sm text-amber-800 mb-8">
            If the amount was deducted from your account, it will be refunded within 3-7 business days.
          </div>
        )}

        <div className="flex justify-between items-center">
          <p className="text-xs text-gray-600">Thank you for choosing Clickinpedia!</p>
          <div className="flex gap-4">
            <button
              onClick={() => navigate("/client/dashboard")}
              className="flex items-center justify-center gap-2 px-6 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Go to Dashboard
              <ArrowRight className="w-5 h-5" />
            </button>
            <ReceiptButton details={details} status={status} />
          </div>
        </div>
      </div>
    </div>
  )
}

const CheckPaymentStatus = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const { user } = useSelector((state) => state.authenticationReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const paymentData = {
      leadId: searchParams.get("leadId") || undefined,
      razorpay_payment_id: searchParams.get("razorpay_payment_id") || "",
      razorpay_payment_link_id: searchParams.get("razorpay_payment_link_id") || "",
      razorpay_payment_link_reference_id: searchParams.get("razorpay_payment_link_reference_id") || "",
      razorpay_payment_link_status: searchParams.get("razorpay_payment_link_status") || "",
      razorpay_signature: searchParams.get("razorpay_signature") || "",
    }

    const requiredFields = [
      paymentData.razorpay_payment_id,
      paymentData.razorpay_payment_link_id,
      paymentData.razorpay_payment_link_status,
      paymentData.razorpay_signature,
    ]
    const isValid = requiredFields.every((value) => value !== "")

    if (isValid) {
      setPaymentDetails(paymentData)
    } else {
      console.error("Invalid or incomplete payment details:", paymentData)
    }
  }, [location.search])

  useEffect(() => {
    const verifyPayment = async () => {
      if (user?._id && paymentDetails) {
        try {
          const cb = () => {
            setLoading(false)
            setPaymentStatus("success")
          }
          const errorCb = () => {
            setLoading(false)
            setPaymentStatus("failed")
          }
          dispatch(checkClientRazorpayAction({ data: paymentDetails, cb, errorCb }))
        } catch (error) {
          setPaymentStatus("failed")
          setLoading(false)
        }
      }
    }

    verifyPayment()
  }, [user, paymentDetails, dispatch])

  useEffect(() => {
    dispatch(getUserAction({}))
  }, [dispatch])

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="relative">
          <div className="w-24 h-24 border-4 border-white/30 rounded-full animate-spin">
            <div className="absolute top-0 left-0 w-24 h-24 border-t-4 border-l-4 border-white rounded-full animate-pulse"></div>
          </div>
          <div className="absolute top-1/2 left-1/2 w-4 h-4 bg-white rounded-full -translate-x-1/2 -translate-y-1/2 animate-ping"></div>
        </div>

        <div className="mt-8 text-center max-w-md mx-auto px-4">
          <h2 className="text-3xl font-bold text-white mb-4 animate-pulse">Verifying Payment</h2>
          <p className="text-white/90 text-lg mb-2">Please wait while we confirm your transaction</p>
          <p className="text-white/70 text-sm">Do not refresh or close this page</p>
        </div>
      </div>
    )
  }

  if (paymentStatus) {
    // Mock data for demonstration - replace with actual data from your state/API
    const mockDetails = {
      name: "John Doe",
      email: "john@example.com",
      paymentDate: "2023-05-15",
      paymentId: "pay_ABC123XYZ",
      orderId: "ORD-12345",
      subject: "Advanced Mathematics Course",
      totalPayment: "500.00",
      balancePayment: "0.00",
      paidAmount: "500.00",
      paymentMode: "Credit Card",
      deadline: "2023-06-30",
      plan: "Premium",
      currency: "USD",
      ...paymentDetails,
    }

    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-600 to-blue-800 p-4">
        <PaymentStatus status={paymentStatus} details={mockDetails} />
      </div>
    )
  }

  return null
}

export default CheckPaymentStatus

