import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencilAlt,
  faTrash,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getReferalAmountAction, updateReferalAmount } from "../../services/action/common";

const CouponManagement = () => {
  const [coupons, setCoupons] = useState([]);
  const [currentCoupon, setCurrentCoupon] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("coupons");
  const [referralSettings, setReferralSettings] = useState({
    senderPrice: 0,
    receiverPrice: 0,
  });

  const {referalAmount}=useSelector((state)=>state.commonReducer)
  const {user} =useSelector((state)=>state.authenticationReducer)
  const dispatch=useDispatch();

  useEffect(()=>{
    if(user){
      dispatch(getReferalAmountAction({userType:user?.userType?.toLowerCase()}))
    }
  },[user,dispatch])

  useEffect(() => {
    // Simulating API call to fetch coupons
    const fetchedCoupons = [
      {
        id: "1",
        code: "SUMMER2024",
        usageLimit: 100,
        usageCount: 50,
        createdAt: moment().subtract(30, "days").toISOString(),
        updatedAt: moment().subtract(5, "days").toISOString(),
      },
      {
        id: "2",
        code: "NEWUSER",
        usageLimit: 50,
        usageCount: 10,
        createdAt: moment().subtract(60, "days").toISOString(),
        updatedAt: moment().subtract(2, "days").toISOString(),
      },
    ];
    setCoupons(fetchedCoupons);
  }, []);

  const handleOpenDialog = (coupon = null) => {
    setCurrentCoupon(
      coupon || {
        id: String(Date.now()),
        code: "",
        usageLimit: 0,
        usageCount: 0,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString(),
      }
    );
    setIsDialogOpen(true);
  };

  const handleSaveCoupon = () => {
    if (currentCoupon) {
      const updatedCoupon = {
        ...currentCoupon,
        updatedAt: moment().toISOString(),
      };
      if (coupons.find((c) => c.id === currentCoupon.id)) {
        setCoupons(
          coupons.map((c) => (c.id === currentCoupon.id ? updatedCoupon : c))
        );
        toast.success("Coupon updated successfully");
      } else {
        setCoupons([...coupons, updatedCoupon]);
        toast.success("New coupon created successfully");
      }
    }
    setIsDialogOpen(false);
  };

  const handleDeleteCoupon = (id) => {
    setCoupons(coupons.filter((c) => c.id !== id));
    toast.success("Coupon deleted successfully");
  };

  const handleCopyCode = () => {
    toast.info("Coupon code copied to clipboard!");
  };

  const handleSaveReferralSettings = () => {
    dispatch(updateReferalAmount({data:referralSettings,userType:user?.userType}))
  };

  useEffect(()=>{
    if(referalAmount){
    setReferralSettings(referalAmount)
    }
  },[referalAmount])
  return (
    <DashboardLayout>
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-[#0A376A] mb-8">
            Coupon Management
          </h1>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="flex border-b border-gray-200">
              <button
                className={`flex-1 px-6 py-4 text-lg font-medium focus:outline-none transition-colors duration-200 ${
                  activeTab === "coupons"
                    ? "text-[#0A376A] border-b-2 border-[#0A376A]"
                    : "text-gray-500 hover:text-[#0A376A]"
                }`}
                onClick={() => setActiveTab("coupons")}
              >
                Coupons
              </button>
              <button
                className={`flex-1 px-6 py-4 text-lg font-medium focus:outline-none transition-colors duration-200 ${
                  activeTab === "referrals"
                    ? "text-[#0A376A] border-b-2 border-[#0A376A]"
                    : "text-gray-500 hover:text-[#0A376A]"
                }`}
                onClick={() => setActiveTab("referrals")}
              >
                Referral Settings
              </button>
            </div>

            {activeTab === "coupons" && (
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-semibold text-[#0A376A]">
                    Active Coupons
                  </h2>
                  <button
                    className="bg-[#0A376A] text-white px-4 py-2 rounded-md hover:bg-[#0A376A]/90 transition-colors duration-200 flex items-center"
                    onClick={() => handleOpenDialog()}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Create New Coupon
                  </button>
                </div>

                <div className="overflow-x-auto">
                  <table className="w-full text-left border-collapse">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-4 py-3 text-[#0A376A] font-semibold">
                          Coupon Code
                        </th>
                        <th className="px-4 py-3 text-[#0A376A] font-semibold">
                          Usage Limit
                        </th>
                        <th className="px-4 py-3 text-[#0A376A] font-semibold">
                          Times Used
                        </th>
                        <th className="px-4 py-3 text-[#0A376A] font-semibold">
                          Created At
                        </th>
                        <th className="px-4 py-3 text-[#0A376A] font-semibold">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.map((coupon) => (
                        <tr
                          key={coupon.id}
                          className="border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150"
                        >
                          <td className="px-4 py-3">{coupon.code}</td>
                          <td className="px-4 py-3">{coupon.usageLimit}</td>
                          <td className="px-4 py-3">{coupon.usageCount}</td>
                          <td className="px-4 py-3">
                            {moment(coupon.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex space-x-2">
                              <button
                                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                                onClick={() => handleOpenDialog(coupon)}
                              >
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </button>
                              <button
                                className="text-red-600 hover:text-red-800 transition-colors duration-200"
                                onClick={() => handleDeleteCoupon(coupon.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                              <CopyToClipboard
                                text={coupon.code}
                                onCopy={handleCopyCode}
                              >
                                <button className="text-green-600 hover:text-green-800 transition-colors duration-200">
                                  <FontAwesomeIcon icon={faCopy} />
                                </button>
                              </CopyToClipboard>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {activeTab === "referrals" && (
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-[#0A376A] mb-6">
                  Referral Settings
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Sender Price ($)
                    </label>
                    <input
                      type="number"
                      value={referralSettings.senderPrice}
                      onChange={(e) =>
                        setReferralSettings({
                          ...referralSettings,
                          senderPrice: Number(e.target.value),
                        })
                      }
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A376A] transition-colors duration-200"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Receiver Price ($)
                    </label>
                    <input
                      type="number"
                      value={referralSettings.receiverPrice}
                      onChange={(e) =>
                        setReferralSettings({
                          ...referralSettings,
                          receiverPrice: Number(e.target.value),
                        })
                      }
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A376A] transition-colors duration-200"
                    />
                  </div>
                </div>
                <button
                  className="mt-6 bg-[#0A376A] text-white px-4 py-2 rounded-md hover:bg-[#0A376A]/90 transition-colors duration-200"
                  onClick={handleSaveReferralSettings}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </div>

        {isDialogOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <h2 className="text-2xl font-semibold text-[#0A376A] mb-4">
                {currentCoupon && currentCoupon.id
                  ? "Edit Coupon"
                  : "Create New Coupon"}
              </h2>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Coupon Code
                </label>
                <input
                  type="text"
                  value={currentCoupon ? currentCoupon.code : ""}
                  onChange={(e) =>
                    setCurrentCoupon({ ...currentCoupon, code: e.target.value })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A376A] transition-colors duration-200"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Usage Limit
                </label>
                <input
                  type="number"
                  value={currentCoupon ? currentCoupon.usageLimit : ""}
                  onChange={(e) =>
                    setCurrentCoupon({
                      ...currentCoupon,
                      usageLimit: Number(e.target.value),
                    })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0A376A] transition-colors duration-200"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                  onClick={() => setIsDialogOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-[#0A376A] text-white rounded-md hover:bg-[#0A376A]/90 transition-colors duration-200"
                  onClick={handleSaveCoupon}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </DashboardLayout>
  );
};

export default CouponManagement;
