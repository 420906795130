import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2 } from "lucide-react";
import BasicInfoForm from "./BasicInfoForm";
import DocumentsForm from "./DocumentsForm";
import { useDispatch, useSelector } from "react-redux";
import { updateBasicDetailAction } from "../../../services/action/common";
import { expertOption } from "../../../components/constants/commonConstant";
import { Toaster } from "../../../components/Toaster";

const ExpertProfileModal = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const [activeTab, setActiveTab] = useState("basic");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitProgress, setSubmitProgress] = useState(0);

  const [avatar, setAvatar] = useState(
    user?.avatar ||
      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
  );
  const [bio, setBio] = useState(user?.bio || "");
  const [primaryExpertise, setPrimaryExpertise] = useState(
    Array.isArray(user?.primaryExpertise) ? user?.primaryExpertise : ""
  );
  const [expertise, setExpertise] = useState(
    Array.isArray(user?.secondaryExpertise) ? user?.secondaryExpertise : ""
  );
  const [chargesPerWord, setChargesPerWord] = useState(user?.ppwRate || "");
  const [education, setEducation] = useState(user?.educations || []);
  const [degrees, setDegrees] = useState(user?.degrees || []);

  // Updated document state management
  const [documents, setDocuments] = useState({
    pan: {
      file: null,
      preview: user?.panCard || null,
    },
    bankProof: {
      file: null,
      preview: user?.bankProof || null,
    },
  });

  const [workSamples, setWorkSamples] = useState(
    user?.workSamples?.map((url) => ({
      file: null,
      preview: url,
      name: url.split("/").pop() || "Sample",
    })) || []
  );

  const specializationOptions = expertOption;
  const expertiseOptions = expertOption;
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.expertProfileLevel < 2) {
      setShowProfileModal(true);
      if (user?.expertProfileLevel === 1) {
        setActiveTab("documents");
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setDegrees(user.degrees || []);
      setChargesPerWord(user.ppwRate || "");
      setEducation(user.educations || []);
      Array.isArray(user.primaryExpertise) &&
        setPrimaryExpertise(user.primaryExpertise || []);
      Array.isArray(user.secondaryExpertise) &&
        setExpertise(user.secondaryExpertise || []);
      setBio(user.bio || "");

      // Update document states
      setDocuments({
        pan: {
          file: null,
          preview: user?.pancardImage || null,
        },
        bankProof: {
          file: null,
          preview: user?.bankDetails?.document || null,
        },
      });
      console.log("user.workSamples", user.workSamples);

      // Update work samples
      if (user.workSamples?.length) {
        setWorkSamples(
          user.workSamples.map((url) => ({
            file: null,
            preview: url,
            name: url.split("/").pop() || "Sample",
          }))
        );
      }
    }
  }, [user]);

  const handleImageUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("Image size should not exceed 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocumentUpload = (type, uploadedDoc) => {
    setDocuments((prev) => ({
      ...prev,
      [type]: {
        file: uploadedDoc.file || null,
        preview: uploadedDoc.preview,
      },
    }));
  };

  const handleWorkSampleUpload = (newSamples) => {
    setWorkSamples((prev) => {
      const combined = [...prev, ...newSamples];
      return combined.slice(0, 5); // Ensure max 5 samples
    });
  };

  const removeWorkSample = (index) => {
    setWorkSamples((prev) => prev.filter((_, i) => i !== index));
  };

  const removeDocument = (type) => {
    setDocuments((prev) => ({
      ...prev,
      [type]: {
        file: null,
        preview: null,
      },
    }));
  };

  const validateForm = () => {
    if (activeTab === "basic") {
      if (!bio.trim()) return "Bio is required";
      if (!primaryExpertise) return "Primary expertise is required";
      if (!expertise) return "Secondary expertise is required";
      if (!chargesPerWord) return "Charges per word is required";
      if (!education.length) return "At least one education entry is required";
      if (!degrees.length) return "At least one degree is required";
    } else {
      if (!documents.pan.preview) return "PAN Card is required";
      if (!documents.bankProof.preview) return "Bank proof is required";
    }
    return null;
  };

  const handleSubmit = async () => {
    const error = validateForm();
    if (error) {
      Toaster.error(error);
      return;
    }

    setIsSubmitting(true);
    setSubmitProgress(0);

    const progressInterval = setInterval(() => {
      setSubmitProgress((prev) => Math.min(prev + 10, 90));
    }, 200);

    try {
      const data = {
        bio,
        primaryExpertise,
        secondaryExpertise: expertise,
        educations: education,
        degrees,
        ppwRate: chargesPerWord,
        pancardImage: documents.pan.preview,
        workSamples: workSamples.map((sample) => sample.preview),
        bankDetails: {
          document: documents.bankProof.preview,
        },
      };

      dispatch(updateBasicDetailAction({ userType: "expert", data ,cb:(res)=>{
        if(res.expertProfileLevel>1){
          setShowProfileModal(false);
        }
      }}));

      setSubmitProgress(100);

      if (user?.expertProfileLevel < 2) {
        setActiveTab("documents");
      } else {
        setShowProfileModal(false);
      }
    } catch (error) {
      Toaster.error("Failed to update profile. Please try again.");
    } finally {
      clearInterval(progressInterval);
      setTimeout(() => {
        setIsSubmitting(false);
        setSubmitProgress(0);
      }, 500);
    }
  };

  return (
    <AnimatePresence>
      {showProfileModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-[#002952] rounded-xl w-full max-w-4xl relative max-h-[90vh] overflow-hidden"
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-bold text-white">
                  Update Your Profile
                </h2>
                <div className="flex items-center bg-[#001B3D] rounded-lg p-1">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setActiveTab("basic")}
                    disabled={isSubmitting}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      activeTab === "basic"
                        ? "bg-blue-500 text-white"
                        : "text-gray-400 hover:text-white"
                    } ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    Basic Info
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setActiveTab("documents")}
                    disabled={isSubmitting}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      activeTab === "documents"
                        ? "bg-blue-500 text-white"
                        : "text-gray-400 hover:text-white"
                    } ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    Documents
                  </motion.button>
                </div>
              </div>

              <div className="overflow-y-auto max-h-[calc(90vh-200px)] pr-2 custom-scrollbar">
                <AnimatePresence mode="wait">
                  {activeTab === "basic" ? (
                    <motion.div
                      key="basic"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 20 }}
                    >
                      <BasicInfoForm
                        avatar={avatar}
                        bio={bio}
                        primaryExpertise={primaryExpertise}
                        expertise={expertise}
                        education={education}
                        degrees={degrees}
                        chargesPerWord={chargesPerWord}
                        specializationOptions={specializationOptions}
                        expertiseOptions={expertiseOptions}
                        onAvatarChange={handleImageUpload}
                        onBioChange={(e) => setBio(e.target.value)}
                        onSpecializationChange={setPrimaryExpertise}
                        onExpertiseChange={setExpertise}
                        onEducationChange={setEducation}
                        onDegreesChange={setDegrees}
                        onChargesChange={setChargesPerWord}
                        disabled={isSubmitting}
                      />
                    </motion.div>
                  ) : (
                    <motion.div
                      key="documents"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                    >
                      <DocumentsForm
                        documentPreviews={{
                          pan: documents.pan.preview,
                          bankProof: documents.bankProof.preview,
                        }}
                        workSamples={workSamples}
                        onDocumentUpload={handleDocumentUpload}
                        onWorkSampleUpload={handleWorkSampleUpload}
                        onRemoveDocument={removeDocument}
                        onRemoveWorkSample={removeWorkSample}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <motion.button
                onClick={handleSubmit}
                disabled={isSubmitting}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full mt-6 py-3 bg-blue-500 text-white rounded-xl font-medium hover:bg-blue-600 transition-colors flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed relative overflow-hidden"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin mr-2" />
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <span>Save Changes</span>
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </>
                )}
                {submitProgress > 0 && (
                  <motion.div
                    className="absolute bottom-0 left-0 h-1 bg-white/30"
                    initial={{ width: 0 }}
                    animate={{ width: `${submitProgress}%` }}
                    transition={{ duration: 0.2 }}
                  />
                )}
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ExpertProfileModal;
