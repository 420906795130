import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { motion, AnimatePresence } from "framer-motion"
import { X, Upload, Trash2, Plus, FileText, Loader2 } from "lucide-react"
import CreatableSelect from "react-select/creatable"
import { components } from "react-select"
import { createUserActionManager, updateUserActionManager } from "../../../../../services/action/manager"
import { uploadImageAction } from "../../../../../services/action/common"
import { expertOption } from "../../../../constants/commonConstant"
import { Toaster } from "../../../../Toaster"

// Add this import for country codes
import { countryCodes } from "../../../../constants/commonConstant"

const CustomValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props
  const nbValues = getValue().length

  return (
    <components.ValueContainer {...props}>
      {hasValue && (
        <div className="flex items-center">
          <span className="mr-2 text-sm text-gray-500">
            {nbValues} {nbValues === 1 ? "item" : "items"} selected
          </span>
        </div>
      )}
      {children}
    </components.ValueContainer>
  )
}

const FormEditExpert = ({ data, onClose }) => {
  const [activeTab, setActiveTab] = useState("basic")
  const [avatar, setAvatar] = useState(data?.image || "/placeholder.svg")
  const [isEditingAvatar, setIsEditingAvatar] = useState(false)
  const [newAvatar, setNewAvatar] = useState(null)
  const [documents, setDocuments] = useState({
    pan: { file: null, preview: data?.pancardImage || null },
    bankProof: { file: null, preview: data?.bankDetails?.document || null },
  })
  const {user}=useSelector((state)=>state.authenticationReducer)
  const [workSamples, setWorkSamples] = useState(
    data?.workSamples?.map((url) => ({
      file: null,
      preview: url,
      name: url.split("/").pop() || "Sample",
    })) || [],
  )
  const [uploadingType, setUploadingType] = useState(null)
  const [uploadProgress, setUploadProgress] = useState({})

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data?.name || "",
      email: data?.email || "",
      countryCode: data?.countryCode || "+1", // Add default country code
      number: data?.number || "",
      expertType: data?.expertType || "",
      bio: data?.bio || "",
      primaryExpertise: Array.isArray(data?.primaryExpertise)
        ? data.primaryExpertise.map((item) => ({ value: item, label: item }))
        : [],
      secondaryExpertise: Array.isArray(data?.secondaryExpertise)
        ? data.secondaryExpertise.map((item) => ({ value: item, label: item }))
        : [],
      educations:
        data?.educations?.map((item) => ({
          value: item,
          label: item,
        })) || [],
      degrees:
        data?.degrees?.map((item) => ({
          value: item,
          label: item,
        })) || [],
      ppwRate: data?.ppwRate || "",
      bankDetails: {
        bankName: data?.bankDetails?.bankName || "",
        accountNo: data?.bankDetails?.accountNo || "",
        confirmAccountNo: data?.bankDetails?.accountNo || "",
        ifsc: data?.bankDetails?.ifsc || "",
      },
    },
  })

  const dispatch = useDispatch()
  const { isSaveLoading } = useSelector((state) => state.managerReducer)

  const handleAvatarChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setNewAvatar(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const updateAvatar = () => {
    if (newAvatar) {
      setAvatar(newAvatar)
      setIsEditingAvatar(false)
      setNewAvatar(null)
    }
  }

  const handleDocumentUpload = async (type, file) => {
    setUploadingType(type)
    setUploadProgress({ [type]: 0 })

    const formData = new FormData()
    formData.append("images", file)

    try {
      const progressInterval = setInterval(() => {
        setUploadProgress((prev) => ({
          ...prev,
          [type]: Math.min((prev[type] || 0) + 10, 90),
        }))
      }, 200)

      await new Promise((resolve) =>
        dispatch(
          uploadImageAction({
            data: formData,
            cb: (res) => {
              clearInterval(progressInterval)
              setUploadProgress((prev) => ({ ...prev, [type]: 100 }))

              if (!res?.imageUrls?.length) {
                Toaster.error("Upload failed. Please try again.")
                return
              }

              setDocuments((prev) => ({
                ...prev,
                [type]: {
                  file: file,
                  preview: res.imageUrls[0],
                },
              }))
              resolve()
            },
          }),
        ),
      )

      setTimeout(() => {
        setUploadProgress((prev) => ({ ...prev, [type]: null }))
        setUploadingType(null)
      }, 500)
    } catch (error) {
      console.error("Upload error:", error)
      Toaster.error("Upload failed. Please try again.")
      setUploadingType(null)
      setUploadProgress((prev) => ({ ...prev, [type]: null }))
    }
  }

  const handleWorkSampleUpload = async (files) => {
    setUploadingType("workSamples")
    setUploadProgress({ workSamples: 0 })

    const formData = new FormData()
    files.forEach((file) => formData.append("images", file))

    try {
      const progressInterval = setInterval(() => {
        setUploadProgress((prev) => ({
          ...prev,
          workSamples: Math.min((prev.workSamples || 0) + 10, 90),
        }))
      }, 200)

      await new Promise((resolve) =>
        dispatch(
          uploadImageAction({
            data: formData,
            cb: (res) => {
              clearInterval(progressInterval)
              setUploadProgress((prev) => ({ ...prev, workSamples: 100 }))

              if (!res?.imageUrls?.length) {
                Toaster.error("Upload failed. Please try again.")
                return
              }

              const newSamples = res.imageUrls.map((url, index) => ({
                file: files[index],
                preview: url,
                name: files[index].name,
              }))

              setWorkSamples((prev) => [...prev, ...newSamples].slice(0, 5))
              resolve()
            },
          }),
        ),
      )

      setTimeout(() => {
        setUploadProgress((prev) => ({ ...prev, workSamples: null }))
        setUploadingType(null)
      }, 500)
    } catch (error) {
      console.error("Upload error:", error)
      Toaster.error("Upload failed. Please try again.")
      setUploadingType(null)
      setUploadProgress((prev) => ({ ...prev, workSamples: null }))
    }
  }

  const removeWorkSample = (index) => {
    setWorkSamples((prev) => prev.filter((_, i) => i !== index))
  }

  const selectStyles = {
    control: (base) => ({
      ...base,
      minHeight: "40px",
      background: "#F9FAFB",
      borderColor: "#E5E7EB",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
      boxShadow: "none",
      "&:focus-within": {
        borderColor: "#3B82F6",
        boxShadow: "0 0 0 1px #3B82F6",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "2px 8px",
      flexWrap: "wrap",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#9CA3AF",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#F3F4F6" : "white",
      color: "#374151",
      "&:active": {
        backgroundColor: "#E5E7EB",
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#E5E7EB",
      borderRadius: "4px",
      margin: "2px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#374151",
      padding: "2px 8px",
      fontSize: "0.8rem",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#6B7280",
      ":hover": {
        backgroundColor: "#D1D5DB",
        color: "#374151",
      },
    }),
  }

  const submitData = (formData) => {
    const processedData = {
      ...formData,
      primaryExpertise: formData.primaryExpertise.map((item) => item.value),
      secondaryExpertise: formData.secondaryExpertise.map((item) => item.value),
      educations: formData.educations.map((item) => item.value),
      degrees: formData.degrees.map((item) => item.value),
      avatar,
      pancardImage: documents.pan.preview,
      bankDetails: {
        ...formData.bankDetails,
        document: documents.bankProof.preview,
      },
      workSamples: workSamples.map((sample) => sample.preview),
      number: `${formData.countryCode}${formData.number.replace(/\D/g, "")}`, // Combine country code and cleaned number
    }

    if (data?._id) {
      dispatch(
        updateUserActionManager({
          userType:user?.userType?.toLowerCase(),
          data: { ...processedData, _id: data._id },
          cb: onClose,
        }),
      )
    } else {
      dispatch(
        createUserActionManager({
          data: processedData,
          cb: onClose,
        }),
      )
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="p-6 flex-grow overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-900">
              {data ? "Edit Expert Profile" : "Create Expert Profile"}
            </h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setActiveTab("basic")}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                activeTab === "basic" ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Basic Info
            </button>
            <button
              onClick={() => setActiveTab("documents")}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                activeTab === "documents" ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Documents
            </button>
          </div>

          <form onSubmit={handleSubmit(submitData)} className="space-y-6">
            <AnimatePresence mode="wait">
              {activeTab === "basic" ? (
                <motion.div
                  key="basic"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="space-y-6"
                >
                  <div className="flex items-start space-x-6">
                    <div className="relative">
                      <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-100 border border-gray-200">
                        <img src={avatar || "/placeholder.svg"} alt="Profile" className="w-full h-full object-cover" />
                      </div>
                      <button
                        type="button"
                        onClick={() => setIsEditingAvatar(true)}
                        className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-1.5 cursor-pointer shadow-sm hover:bg-blue-600 transition-colors"
                      >
                        <Upload className="h-4 w-4 text-white" />
                      </button>
                    </div>

                    <div className="flex-1 space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name<span className="text-red-500">*</span>
                          </label>
                          <input
                            id="name"
                            {...register("name", { required: "Name is required" })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                          />
                          {errors.name && <p className="mt-1 text-xs text-red-500">{errors.name.message}</p>}
                        </div>
                        <div>
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email<span className="text-red-500">*</span>
                          </label>
                          <input
                            id="email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                          />
                          {errors.email && <p className="mt-1 text-xs text-red-500">{errors.email.message}</p>}
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Phone Number<span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <div className="relative flex-grow-0">
                              <select
                                id="countryCode"
                                {...register("countryCode", { required: "Country code is required" })}
                                className="h-10 rounded-l-md border-r-0 border-gray-300 bg-gray-50 py-2 pl-3 pr-10 text-gray-500 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                              >
                                {countryCodes.map((code) => (
                                  <option key={code.code} value={code.code}>
                                    {code.code}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              id="number"
                              type="tel"
                              {...register("number", { required: "Phone number is required" })}
                              className="block w-full flex-1 rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                              placeholder="Phone number"
                            />
                          </div>
                          {errors.countryCode && (
                            <p className="mt-1 text-xs text-red-500">{errors.countryCode.message}</p>
                          )}
                          {errors.number && <p className="mt-1 text-xs text-red-500">{errors.number.message}</p>}
                        </div>
                        <div>
                          <label htmlFor="expertType" className="block text-sm font-medium text-gray-700">
                            Expert Type<span className="text-red-500">*</span>
                          </label>
                          <select
                            id="expertType"
                            {...register("expertType", { required: "Expert type is required" })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                          >
                            <option value="">Select type</option>
                            <option value="INTERNAL">Internal</option>
                            <option value="EXTERNAL">External</option>
                          </select>
                          {errors.expertType && (
                            <p className="mt-1 text-xs text-red-500">{errors.expertType.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                      Professional Bio
                    </label>
                    <textarea
                      id="bio"
                      {...register("bio")}
                      rows={4}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2 resize-vertical"
                      placeholder="Tell us about your expertise and experience..."
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="primaryExpertise" className="block text-sm font-medium text-gray-700">
                        Primary Expertise<span className="text-red-500">*</span>
                      </label>
                      <Controller
                        name="primaryExpertise"
                        control={control}
                        rules={{ required: "Primary expertise is required" }}
                        render={({ field }) => (
                          <CreatableSelect
                            {...field}
                            isMulti
                            options={expertOption.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            styles={selectStyles}
                            placeholder="Select or create expertise"
                            className="mt-1"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                          />
                        )}
                      />
                      {errors.primaryExpertise && (
                        <p className="mt-1 text-xs text-red-500">{errors.primaryExpertise.message}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="secondaryExpertise" className="block text-sm font-medium text-gray-700">
                        Secondary Expertise
                      </label>
                      <Controller
                        name="secondaryExpertise"
                        control={control}
                        render={({ field }) => (
                          <CreatableSelect
                            {...field}
                            isMulti
                            options={expertOption.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            styles={selectStyles}
                            placeholder="Select or create expertise"
                            className="mt-1"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="educations" className="block text-sm font-medium text-gray-700">
                        Education
                      </label>
                      <Controller
                        name="educations"
                        control={control}
                        render={({ field }) => (
                          <CreatableSelect
                            {...field}
                            isMulti
                            options={[
                              "High School",
                              "Bachelor's Degree",
                              "Master's Degree",
                              "Doctorate",
                              "Post Doctorate",
                              "Professional Certification",
                            ].map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            styles={selectStyles}
                            placeholder="Select or add education"
                            className="mt-1"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <label htmlFor="degrees" className="block text-sm font-medium text-gray-700">
                        Degrees
                      </label>
                      <Controller
                        name="degrees"
                        control={control}
                        render={({ field }) => (
                          <CreatableSelect
                            {...field}
                            isMulti
                            options={[
                              "B.Tech",
                              "B.E.",
                              "B.Sc",
                              "B.A",
                              "B.Com",
                              "M.Tech",
                              "M.E.",
                              "M.Sc",
                              "M.A",
                              "M.Com",
                              "Ph.D",
                              "MBA",
                            ].map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            styles={selectStyles}
                            placeholder="Select or add degrees"
                            className="mt-1"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="ppwRate" className="block text-sm font-medium text-gray-700">
                      Charges per Word (₹)<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm h-10">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">₹</span>
                      </div>
                      <input
                        id="ppwRate"
                        {...register("ppwRate", {
                          required: "Charges per word is required",
                          min: { value: 0, message: "Charges must be positive" },
                        })}
                        type="number"
                        step="0.01"
                        className="block w-full pl-8 pr-16 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                        placeholder="0.00"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">INR</span>
                      </div>
                    </div>
                    {errors.ppwRate && <p className="mt-1 text-xs text-red-500">{errors.ppwRate.message}</p>}
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="documents"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="space-y-6"
                >
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      PAN Card<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative">
                      {documents.pan.preview ? (
                        <div className="relative">
                          <img
                            src={documents.pan.preview || "/placeholder.svg"}
                            alt="PAN Card"
                            className="max-h-32 w-auto"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              setDocuments((prev) => ({
                                ...prev,
                                pan: { file: null, preview: null },
                              }))
                            }
                            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 shadow-md hover:bg-red-600"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-1 text-center">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <div className="flex text-sm text-gray-600">
                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                              <span>Upload a file</span>
                              <input
                                type="file"
                                className="sr-only"
                                onChange={(e) => handleDocumentUpload("pan", e.target.files[0])}
                                accept=".png,.jpg,.jpeg,.pdf"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">PNG, JPG, PDF up to 5MB</p>
                        </div>
                      )}
                      {uploadingType === "pan" && (
                        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                          <div className="text-center">
                            <Loader2 className="w-8 h-8 mb-2 mx-auto animate-spin text-blue-500" />
                            <p className="text-sm text-gray-600">Uploading... {uploadProgress.pan}%</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Bank Proof<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative">
                      {documents.bankProof.preview ? (
                        <div className="relative">
                          <img
                            src={documents.bankProof.preview || "/placeholder.svg"}
                            alt="Bank Proof"
                            className="max-h-32 w-auto"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              setDocuments((prev) => ({
                                ...prev,
                                bankProof: { file: null, preview: null },
                              }))
                            }
                            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 shadow-md hover:bg-red-600"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-1 text-center">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <div className="flex text-sm text-gray-600">
                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                              <span>Upload a file</span>
                              <input
                                type="file"
                                className="sr-only"
                                onChange={(e) => handleDocumentUpload("bankProof", e.target.files[0])}
                                accept=".png,.jpg,.jpeg,.pdf"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">PNG, JPG, PDF up to 5MB</p>
                        </div>
                      )}
                      {uploadingType === "bankProof" && (
                        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                          <div className="text-center">
                            <Loader2 className="w-8 h-8 mb-2 mx-auto animate-spin text-blue-500" />
                            <p className="text-sm text-gray-600">Uploading... {uploadProgress.bankProof}%</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Work Samples (Max 5)</label>
                    <div className="mt-1 space-y-2">
                      {workSamples.map((sample, index) => (
                        <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                          <div className="flex items-center space-x-2">
                            <FileText className="w-5 h-5 text-gray-400" />
                            <span className="text-sm text-gray-700">{sample.name}</span>
                          </div>
                          <button
                            type="button"
                            onClick={() => removeWorkSample(index)}
                            className="text-gray-400 hover:text-red-500"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      ))}
                      {workSamples.length < 5 && (
                        <label className="flex items-center justify-center w-full h-12 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400">
                          <Plus className="w-5 h-5 mr-2 text-gray-400" />
                          <span className="text-sm text-gray-600">Add Work Sample</span>
                          <input
                            type="file"
                            className="hidden"
                            onChange={(e) => handleWorkSampleUpload(Array.from(e.target.files))}
                            accept=".pdf,.doc,.docx,.txt"
                            multiple
                          />
                        </label>
                      )}
                      {uploadingType === "workSamples" && (
                        <div className="flex items-center justify-center h-12 bg-white border rounded-md">
                          <Loader2 className="w-5 h-5 mr-2 animate-spin text-blue-500" />
                          <p className="text-sm text-gray-600">Uploading... {uploadProgress.workSamples}%</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <h3 className="text-lg font-medium text-gray-900">Bank Details</h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="bankName" className="block text-sm font-medium text-gray-700">
                          Bank Name
                        </label>
                        <input
                          id="bankName"
                          {...register("bankDetails.bankName")}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                        />
                      </div>
                      <div>
                        <label htmlFor="ifsc" className="block text-sm font-medium text-gray-700">
                          IFSC Code
                        </label>
                        <input
                          id="ifsc"
                          {...register("bankDetails.ifsc")}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="accountNo" className="block text-sm font-medium text-gray-700">
                          Account Number
                        </label>
                        <input
                          id="accountNo"
                          {...register("bankDetails.accountNo")}
                          type="text"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                        />
                      </div>
                      <div>
                        <label htmlFor="confirmAccountNo" className="block text-sm font-medium text-gray-700">
                          Confirm Account Number
                        </label>
                        <input
                          id="confirmAccountNo"
                          {...register("bankDetails.confirmAccountNo", {
                            validate: (value) =>
                              value === watch("bankDetails.accountNo") || "Account numbers do not match",
                          })}
                          type="password"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 px-3"
                        />
                        {errors.bankDetails?.confirmAccountNo && (
                          <p className="mt-1 text-xs text-red-500">{errors.bankDetails.confirmAccountNo.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="sticky bottom-0 bg-white border-t p-4 mt-6">
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSaveLoading}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {isSaveLoading ? "Saving..." : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <AnimatePresence>
        {isEditingAvatar && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Update Profile Picture</h3>
                <button onClick={() => setIsEditingAvatar(false)} className="text-gray-400 hover:text-gray-500">
                  <X className="h-5 w-5" />
                </button>
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-center">
                  <div className="w-32 h-32 rounded-full overflow-hidden border-2 border-gray-200">
                    <img src={newAvatar || avatar} alt="Profile" className="w-full h-full object-cover" />
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setIsEditingAvatar(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={updateAvatar}
                    disabled={!newAvatar}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 rounded-md"
                  >
                    Update
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default FormEditExpert

