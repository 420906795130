import React from 'react';
import { motion } from 'framer-motion';
import { TrashIcon } from 'lucide-react';

const AccountDeleted = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center min-h-screen bg-gray-100"
    >
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}
          className="flex justify-center mb-6"
        >
          <TrashIcon className="w-16 h-16 text-red-500" />
        </motion.div>
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-4">
          Your account has been deleted
        </h1>
        <p className="text-center text-gray-600 mb-6">
          We're sorry to see you go. All your data has been permanently removed from our systems.
        </p>
        <div className="flex justify-center">
        <a href='/'>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
          >
            
            Back to Home
            
          </motion.button>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default AccountDeleted;

