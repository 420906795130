// import node module libraries
import React, { useState } from "react";
import { Card} from "react-bootstrap";
import Buttons from "../../../utils/buttons/Buttons";
import CustomModal from "../../../utils/modals/CustomModal";
import FormEditExpert from "./components/FormEditExpert";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserActionManager,
  updateUserActionManager,
} from "../../../../services/action/manager";
import FullScreenModal from "../../../utils/modals/FullScreenModal";
import PersonalCard from "../../../card/PersonalCard";
import DeleteConfirmation from "../../../utils/dialoge/DeleteConfirmation";
import CustomDataTable from "../../../utils/data-tables/CustomDataTable";
import { ExpertListColumn } from "./ExpertListColumn";

const ExpertListComponent = ({
  getList,
  TeamsData,
  listTitle = "Expert List",
  setPageData,
  pageData,
}) => {
  const { isDeleteUser } = useSelector((state) => state.managerReducer);
  const {user}=useSelector((state)=>state.authenticationReducer);
  const dispatch = useDispatch();
  const [operatorDetail, setExpertDetails] = useState({
    modal: false,
    preview: false,
    edit: false,
    data: "",
  
  });

  const [deleteId, setDeleteId] = useState("");


  const afterSave = () => {
    getList({ currentPage:1, isEmptylist: false });
    setExpertDetails({});
  };

  const userType=user?.userType?.toLowerCase();

  const deleteFinal = () => {
    dispatch(
      deleteUserActionManager({
        userType,
        data: deleteId,
        cb: () => {
          setDeleteId("");
          getList({ currentPage:1, isEmptylist: false });
          setExpertDetails({});
        },
      })
    );
  };

  const updateStatus = (data) => {
    dispatch(
      updateUserActionManager({
        userType,
        data: {
          _id: data?._id,
          status: data?.status === "ACTIVE" ? "NOT ACTIVE" : "ACTIVE",
        },
        cb: () => getList({ currentPage:1, isEmptylist: false }),
      })
    );
  };

  const fetchMoreData = () => {
    setPageData({ page: pageData?.page + 1 });
    getList({ currentPage: pageData?.page + 1, firstTime: false });
  };


  return (
    <Card className="h-100">
      <DeleteConfirmation
        open={deleteId}
        isDeleteLoading={isDeleteUser}
        onClose={() => setDeleteId("")}
        onConfirm={deleteFinal}
      />

      <FullScreenModal
        title={"Add Expert"}
        bgContent="dark-bg"
        open={operatorDetail.modal}
        onClose={() => setExpertDetails({})}
        content={() => (
          <FormEditExpert onClose={() => {afterSave(); setExpertDetails({})}} data={operatorDetail?.data} close={afterSave} />
        )}
      />

      <FullScreenModal
        title={"Personal Details"}
        bgContent="dark-bg"
        size="xl"
        open={operatorDetail.preview}
        onClose={() => setExpertDetails({})}
        content={() => (
          <PersonalCard
            onDeleteClick={(data) => setDeleteId(data)}
            onEditClick={(data) =>
              setExpertDetails({
                edit: true,
                modal: true,
                preview: false,
                data,
              })
            }
            data={operatorDetail?.data}
            close={() => setExpertDetails({})}
          />
        )}
      />

      <Card.Header className="bg-white py-4">
        <h4 className="mb-0">
          {listTitle}{" "}
          <Buttons
            onClick={() => setExpertDetails({ modal: true, edit: false })}
            title={"Add Expert"}
            icon="plus"
          />{" "}
        </h4>
      </Card.Header>
        <CustomDataTable
        columns={ExpertListColumn(
          setExpertDetails,
          updateStatus
        )}
        isInfinitScroll={true}
        fetchMoreData={fetchMoreData}
        data={TeamsData?.data}
      />
    </Card>
  );
};

export default ExpertListComponent;
