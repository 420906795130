import React, { useState, useEffect } from "react";

import DashboardLayout from "../layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import {  getAllLiveUserListAction, getLiverUserHeaderCountAction, getOpenUseNoticationAction, getOpenUserChatListAction } from "../../services/action/common";
import socket from "../socket/socket";
import OpenChatBoxInternal from "./views/OpenChatBoxInternal";
// Main component
export default function LiveUserNewDashboard({visitorId,setopenLiveChat, isContentOnly}) {
  const [activeFilter, setActiveFilter] = useState("all");
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  // const [selectedWebsite, setSelectedWebsite] = useState('all websites')
  const { allLiveUser,openUserChatList } = useSelector((state) => state.commonReducer);

  const [currentUserList, setCurrentUserList] = useState({
    paginations: "",
    data: [],
  });


  const [messages, setMessages] = useState({ chatList: [] });

  const chatStats = {
    all: 5,
    missed: 2,
    confirmed: 55,
    pending: 44,
  };

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleSendMessage = (tempMessage) => {
    if (tempMessage?.trim()|| newMessage.trim() ) {
      const obj = {
        userId: messages?.data?._id,
        sender: user?._id,
        type: "MESSAGE",
        time: new Date(),
        message:tempMessage || newMessage ,
      };

      socket.emit("open_chat_backend", obj);
      setMessages((prevMessages) => ({
        ...prevMessages,
        chatList: [...prevMessages?.chatList, obj],
      }));
      setNewMessage("");
      let tempMessage2=tempMessage || newMessage;
      if(tempMessage2?.toLowerCase()?.includes("this is inprogress")){
        getChatHeaderInfo()
      }else if(tempMessage2?.toLowerCase()?.includes("this is rejected")){
        getChatHeaderInfo()
      }else if(tempMessage2?.toLowerCase()?.includes("this is confirmed")){
        getChatHeaderInfo()
      }
    }
  };



  const { user } = useSelector((state) => state.authenticationReducer);
  const [filter, setFilter] = useState({
    searchQuery: "",
    website: "all website",
    isOnline: undefined,
    onlyTodayUser: undefined,
    from: undefined,
    to: undefined,
    status:"ALL",
  });
  const [pageData, setPageData] = useState({
    page: 1,
    limit: 17,
  });
  const [existUser, setExistUser] = useState(false);

  const dispatch = useDispatch();

  const getChatHeaderInfo=()=>{
    dispatch(getLiverUserHeaderCountAction({userType:user?.userType}))
  }
  const getAllLiveUser = ({
    page = pageData?.page,
    limit = pageData?.limit,
    tempWebsite = filter.website,
    // tempIsOnline = filter?.isOnline || filter.status==="ISONLINE",
    tempSearchQuery = filter.searchQuery,
    tempOnlyTodayUser = filter.onlyTodayUser,
    from = filter.from,
    to = filter.to,
    firstTime = false,
    status=filter.status,
    resetPageData=""

  }) => {
    // console.log("ddd",tempIsOnline ,status)

    if(resetPageData){
      setPageData({page:1})
    }
    const tempFilter = {
      website: tempWebsite,
      status:status,
      searchQuery: tempSearchQuery,
      limit,
      onlyTodayUser: tempOnlyTodayUser,
      from,
      to,
      page,
    };
    dispatch(
      getAllLiveUserListAction({
        firstTime,
        userType: user?.userType?.toLowerCase(),
        filter: tempFilter,
      })
    );
  };

  useEffect(() => {
    if (user?.userType) {
      getAllLiveUser({ firstTime: true });
      getChatHeaderInfo()
    }
  }, [user]);

  const getNotification = ({userId}) => {
    dispatch(getOpenUseNoticationAction({ data: { userId } }));
  };
  const getCurrentChatUser = ({userId}) => {
    dispatch(
      getOpenUserChatListAction({
        data: { userId, isOpenUser: true },
        cb: getNotification,
      })
    );
  };

  useEffect(() => {
    if (visitorId || allLiveUser) {
      if(allLiveUser){
        setCurrentUserList(allLiveUser);
      }
      if(visitorId && !messages.chatList?.length){
       
        allLiveUser?.data?.[0]?._id && getCurrentChatUser({userId:visitorId})
      }else if(!messages.chatList?.length && !messages?.data && !isContentOnly && !visitorId && allLiveUser?.data?.[0]?._id) {
        getCurrentChatUser({userId: allLiveUser?.data?.[0]?._id})
      }
      
      
    }

  }, [allLiveUser,visitorId,dispatch,isContentOnly,messages]);


  useEffect(() => {
    if (existUser) {
      let existUserIndex = currentUserList?.data?.findIndex(
        (item) => item?._id === existUser?._id
      );
      const tempData = [...currentUserList?.data];
      if (existUserIndex != -1) {
        tempData[existUserIndex] = existUser;
        setCurrentUserList((prev) => ({ ...prev, data: tempData }));
        setExistUser(false);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [existUser, ...prev.data],
        }));
        setExistUser(false);
      }
    }
  }, [existUser]);

  useEffect(() => {
    socket.on("recieve_message_from_open_chat", (currentUserMessage) => {
      if (messages?.data?._id === currentUserMessage?.sender) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          chatList: [...prevMessages?.chatList, currentUserMessage],
        }));
      }
    });
    return () => {
      socket.off("recieve_message_from_open_chat");
    };
  }, [messages]);

  const loadMoreChats=()=>{
    getAllLiveUser({page:pageData?.page+1})
    setPageData((prev)=>({...prev,page:prev.page+1}))
  }

  const getUpdateUserLive=()=>{
    getAllLiveUser({page:1,limit:pageData?.limit*pageData?.page,firstTime:true})
    setPageData((prev)=>({...prev,page:1,limit:pageData?.limit*pageData?.page}))
  }
  const startChat = (currentuser) => {
    getCurrentChatUser({userId:currentuser?._id})
  };

  
  useEffect(()=>{
   setMessages({chatList:openUserChatList?.chatList,data:openUserChatList?.data})
  },[openUserChatList])

  useEffect(() => {
    socket.on("all_live_user", (currentData) => {
      if (currentData?.existUser) {
        setExistUser(currentData);
      } else {
        setCurrentUserList((prev) => ({
          ...prev,
          data: [currentData,...prev?.data],
        }));
        startChat(currentData);
        // if (audioRef.current) {
        //   const playPromise = audioRef.current.play();
        //   if (playPromise !== undefined) {
        //     playPromise.catch((error) => {
        //       console.log("Playback prevented:", error);
        //     });
        //   }
        // }
      }
    });
    return () => {
      socket.off("all_live_user");
    };
  }, []);

  // console.log("isMobile",isMobile)


  return (
    isContentOnly?<OpenChatBoxInternal
    showRightSidebar ={showRightSidebar}
    setShowRightSidebar={setShowRightSidebar}
    setNewMessage={setNewMessage}
    newMessage={newMessage}
    handleSendMessage={handleSendMessage}
    user={user}
    getAllLiveUser={getAllLiveUser}
    setPageData={setPageData}
    setFilter={setFilter}
    filter={filter}
    allLiveUser={allLiveUser}
    loadMoreChats={loadMoreChats}
    startChat={startChat}
    currentUserList={currentUserList}
    chatStats={chatStats}
    messages={messages}
    setActiveFilter={setActiveFilter}
    activeFilter={activeFilter}
    showLeftSidebar={showLeftSidebar}
    setopenLiveChat={setopenLiveChat}
    setShowLeftSidebar={setShowLeftSidebar}
    getUpdateUserLive={getUpdateUserLive}
    />:
    <DashboardLayout hideSidebar={false}>
     <OpenChatBoxInternal 
     
     showRightSidebar ={showRightSidebar}
     setShowRightSidebar={setShowRightSidebar}
     setNewMessage={setNewMessage}
     newMessage={newMessage}
     handleSendMessage={handleSendMessage}
     user={user}
     getAllLiveUser={getAllLiveUser}
     setPageData={setPageData}
     setFilter={setFilter}
     filter={filter}
     allLiveUser={allLiveUser}
     loadMoreChats={loadMoreChats}
     startChat={startChat}
     currentUserList={currentUserList}
     chatStats={chatStats}
     messages={messages}
     setActiveFilter={setActiveFilter}
     activeFilter={activeFilter}
     showLeftSidebar={showLeftSidebar}
     setopenLiveChat={setopenLiveChat}
     setShowLeftSidebar={setShowLeftSidebar}
     getUpdateUserLive={getUpdateUserLive}
     />
    </DashboardLayout>
  );
}
